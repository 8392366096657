import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { accordion } from '../data/careerData';

import CareerAcordion from '../components/careerAcordion';

const Career = props => {
  return (
    <div className="career-block">
      <Container>
        <div className="career-details" align="center">
          <p>
            Quality, Efficiency, Commitment. Help us perfect the processes,
            product, and standard that we stand for. We believe in building
            strong teams & creating a balanced work-environment.
          </p>
        </div>
        <div className="position-block">
          <Row>
            <Col lg={6} className="left-label">
              <Row>
                <h4>Position</h4>
              </Row>
            </Col>
            <Col lg={6} className="right-label">
              <Row>
                <h4>Location</h4>
              </Row>
            </Col>
          </Row>
          <Row>
            <CareerAcordion panels={accordion} />
          </Row>
        </div>

        <p class="career-not-found-block para para--large para--dark-gray">
          Didn’t Find What You Were Looking For?
          <br />
          <br />
          Send us your resume anyway. We might be able to help you find the
          right fit for you. All unsolicited applications to be sent to&nbsp;
          <a href="mailto: hrd@palvichemical.com">hrd@palvichemical.com</a>.
        </p>
      </Container>
    </div>
  );
};

export default Career;
