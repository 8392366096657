import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../style/typography.css';
import '../style/singleParaBar.css';

const SinglePara = Props => {
  return (
    <div className="section about-us__section-main">
      <Container>
        <Row className="section-main-block">
          <Col lg={12}>
            <p className="para para--white para--large para-home-bar">
              The foundation stone for Palvi Industries Ltd was laid in 1995.
              From a humble beginning, the company now registers annual sales
              revenue of more than 35 million USD.
            </p>
            <p className="para para--white para--large para-home-bar">
              Since its inception Palvi’s vision has been to provide quality
              products to its customers and to build strong networks. Our valued
              customers are ensured price competitiveness and quality
              deliverance at every given opportunity. Our list of clients has
              increased from time-to-time and we wish to mark our presence
              worldwide, in the future as well.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SinglePara;
