import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import '../../style/product-child-masterbatch.css';
import '../../style/director-message.css';

class DirectorMessage extends React.Component {
  render() {
    return (
      <div className="product-main-block">
        <div className="product-details-block">
          <Container>
            <Row>
              <Col
                lg={12}
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-details-sub-block">
                  <div className="product-details-block-title">
                    <h2 className="section-heading-primary">
                      Director's Message
                    </h2>
                  </div>
                  <div className="product-details-block-info">
                    {/* <img src={product.image} alt="" /> */}
                    <p key={`productMainPara`}>
                      Mr Himanshu Mehta, the Founder & Chief Executive Officer
                      of Palvi Industries Limited, has more than two decades of
                      experience in the chemical export sector. He is an expert
                      in Import-Export and international trading with a thorough
                      knowledge on marketing, logistics, documentation etc. Mr
                      Himanshu has received various awards including the ‘Niryat
                      Bandhu Award for Export Excellence’ from President of
                      India for his entrepreneurship. He has been associated
                      with the Company since its inception as promoter. He is
                      responsible for the overall working of the Company and is
                      instrumental in making strategic decisions for the
                      Company.
                    </p>
                    <p key={`productMainPara`}>
                      Mr Himanshu Mehta, the Founder & Chief Executive Officer
                      of Palvi Industries Limited, has more than two decades of
                      experience in the chemical export sector. He is an expert
                      in Import-Export and international trading with a thorough
                      knowledge on marketing, logistics, documentation etc. Mr
                      Himanshu has received various awards including the ‘Niryat
                      Bandhu Award for Export Excellence’ from President of
                      India for his entrepreneurship. He has been associated
                      with the Company since its inception as promoter. He is
                      responsible for the overall working of the Company and is
                      instrumental in making strategic decisions for the
                      Company.
                    </p>
                    <p
                      key={`productMainPara`}
                      className="director-message-quote"
                    >
                      <FaQuoteLeft />
                      &nbsp;&nbsp;Since inception, Palvi’s business strategy has
                      been to develop long term strategic partnerships with its
                      valued vendors and customers.&nbsp;&nbsp;
                      <FaQuoteRight />
                    </p>
                    <p key={`productMainPara`}>
                      Palvi Industries Limited was established in the year 1995
                      as a small partnership firm as Palvi Power Tech Sales with
                      a capital of Rs. 5000/-. The main business of the company
                      was trading & tending of chemicals for domestic Indian
                      market. In the year 1999-2000 the company stopped domestic
                      trading and commenced export of Industrial chemicals. In
                      the year 2006 the company was converted from partnership
                      firm into Private Limited Company, Palvi Power Tech Sales
                      Private Limited, with increase in business. The Company
                      steadily grew from the year 2006 when its turnover was Rs.
                      4 Crs (@ US $ 0.66 million) only and today its sales
                      revenues are more than Rs. 220 Crs (@ US $ 31.00 million).
                      Today the company regularly exports more than 100 products
                      to more than 60 countries. The company operates a wholly
                      owned subsidiary named Palvi Power Tech Sales FZE in UAE
                      which gives it easy access to Gulf and African markets.
                    </p>
                    <p
                      key={`productMainPara`}
                      className="director-message-quote"
                    >
                      <FaQuoteLeft />
                      &nbsp;&nbsp;We were already established in the UAE before
                      companies started rushing there. We do business worth 10
                      million USD every year and are well versed with the UAE
                      market and export procedures.&nbsp;&nbsp;
                      <FaQuoteRight />
                    </p>
                    <p key={`productMainPara`}>
                      In 2010, the company ventured into manufacturing of
                      specialty chemicals through the Molybdenum project. The
                      company set up a small unit to manufacture Molybdenum
                      salts like Sodium Molybdate, Ammonium Molybdate and
                      Molybdenum Trioxide. The production capacity was 3 metric
                      tons per month which has now been expanded to 60 metric
                      tons per month. Today Palvi Industries Limited is one of
                      the top producers & exporter for Molybdenum chemicals in
                      Asia. The company exports its product to more than 25
                      countries to reputed clients.
                    </p>
                    <p
                      key={`productMainPara`}
                      className="director-message-quote"
                    >
                      <FaQuoteLeft />
                      &nbsp;&nbsp;We ventured into manufacturing to add more
                      value to our product mix and offer it to our international
                      customers as well the Indian chemicals market.&nbsp;&nbsp;
                      <FaQuoteRight />
                    </p>
                    <p key={`productMainPara`}>
                      In 2015, the company diversified into Plastic field and is
                      currently manufacturing 250 metric tons of Black
                      masterbatches & 350 metric tons of Calcium Carbonate
                      fillers. The company is developing grades of biodegradable
                      black masterbatches with lookout to the future. All three
                      manufacturing units are located in Vadodara, Gujarat
                    </p>
                    <p key={`productMainPara`}>
                      Palvi Industries Limited has rich experience of sourcing,
                      trading and export of Acids such as Sulphuric acid, Nitric
                      Acid, Hydrochloric Acid, Acetic Acid and Formic Acid. The
                      company has won several awards for its export performances
                      and regularly exports more than 5000 tons from India every
                      year.
                    </p>
                    <p
                      key={`productMainPara`}
                      className="director-message-quote"
                    >
                      <FaQuoteLeft />
                      &nbsp;&nbsp;I strongly advocate to my team that the only
                      way towards fast growth is to be 'Customer
                      Focussed.&nbsp;&nbsp;
                      <FaQuoteRight />
                    </p>
                  </div>
                  <div className="director-sign-off">
                    <p>Himanshu Mehta</p>
                    <p>Managing Director</p>
                    <p>Palvi Industries Limited</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default DirectorMessage;
