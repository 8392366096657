import React from 'react';

import Page404 from './pages/Page404';
import Home from './pages/Home';
import ExportProductPage from './pages/Product/ProductCategory/Export/product';
import AcidProductPage from './pages/Product/ProductCategory/Acid/product';
import TollProductPage from './pages/Product/ProductCategory/Toll/product';
import CommonPageFormat from './pages/Product/ProductCategory/commonPageFormat';
import MasterbatchProductPage from './pages/Product/ProductCategory/Masterbatch/product';
import ProductChildMolybdenumPage from './pages/Product/ProductCategory/Molybdenum/product';
import ProductCategoryPage from './pages/Product/ProductCategory';
import About from './pages/About';
import Csr from './pages/Csr';
import Contact from './pages/Contact';
import Career from './pages/Career';

import ProductPage from './pages/Product';
import Header from './pages/layout/Header';
import Footer from './pages/layout/Footer';
import { connect } from 'react-redux';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { fetchProducts } from './actions/index';
import Spinner from './components/spinner';
import ScrollToTop from './components/ScrollToTop';
import PressRelease from './pages/Newsroom/PressRelease';
import MediaGallery from './pages/Newsroom/MediaGallery';
import DirectorMessage from './pages/Newsroom/DirectorMessage';
import ProductSearch from './pages/ProductSearch';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loading: true,
    };
  }
  componentWillMount() {
    this.props.fetchProducts();
  }
  componentDidMount() {
    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="App">
            <Header />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/products" exact component={ProductPage} />
              <Route
                path="/manufacturedproducts"
                exact
                component={ProductPage}
              />
              <Route exact path="/contact" component={Contact} />

              <Route
                path="/products/international"
                exact
                render={(props) => (
                  <ProductCategoryPage {...props} productType="international" />
                )}
              />
              <Route
                path="/products/masterbatches"
                exact
                render={(props) => (
                  <ProductCategoryPage {...props} productType="masterbatches" />
                )}
              />
              <Route
                path="/products/molybdenum"
                exact
                render={(props) => (
                  <ProductCategoryPage {...props} productType="molybdenum" />
                )}
              />

              <Route
                path="/products/acid"
                exact
                render={(props) => (
                  <ProductCategoryPage {...props} productType="acid" />
                )}
              />

              <Route
                path="/products/toll"
                exact
                render={(props) => (
                  <ProductCategoryPage {...props} productType="toll" />
                )}
              />

              <Route
                path="/products/international/:id"
                status={404}
                exact
                render={(props) => (
                  <ExportProductPage {...props} productType="international" />
                )}
              />

              <Route
                path="/products/international/soap-detergent/:id"
                status={404}
                exact
                render={(props) => (
                  <CommonPageFormat {...props} productType="common" />
                )}
              />

              <Route
                path="/products/international/other-products/:id"
                status={404}
                exact
                render={(props) => (
                  <CommonPageFormat {...props} productType="common" />
                )}
              />

              <Route
                path="/products/international/food-ingredient/:id"
                status={404}
                exact
                render={(props) => (
                  <CommonPageFormat {...props} productType="common" />
                )}
              />

              <Route
                path="/products/international/polyurathene/:id"
                status={404}
                exact
                render={(props) => (
                  <CommonPageFormat {...props} productType="common" />
                )}
              />

              <Route
                path="/products/international/drill-mining/:id"
                status={404}
                exact
                render={(props) => (
                  <CommonPageFormat {...props} productType="common" />
                )}
              />

              <Route
                path="/products/international/plastics/:id"
                status={404}
                exact
                render={(props) => (
                  <CommonPageFormat {...props} productType="common" />
                )}
              />

              <Route
                path="/products/masterbatches/:id"
                status={404}
                exact
                render={(props) => (
                  <MasterbatchProductPage
                    {...props}
                    productType="masterbatches"
                  />
                )}
              />

              <Route
                path="/products/molybdenum/:id"
                status={404}
                exact
                render={(props) => (
                  <ProductChildMolybdenumPage
                    {...props}
                    productType="molybdenum"
                  />
                )}
              />

              <Route
                path="/products/acid/:id"
                status={404}
                exact
                render={(props) => (
                  <AcidProductPage {...props} productType="acid" />
                )}
              />

              <Route
                path="/products/toll/:id"
                status={404}
                exact
                render={(props) => (
                  <TollProductPage {...props} productType="toll" />
                )}
              />

              <Route exact path="/products/list" component={ProductSearch} />

              <Route exact path="/about" component={About} />
              <Route exact path="/csr" component={Csr} />
              <Route
                path="/csr/:id"
                status={404}
                exact
                render={(props) => <Csr {...props} />}
              />
              <Route exact path="/career" component={Career} />

              <Route exact path="/press-release" component={PressRelease} />
              <Route exact path="/media-gallery" component={MediaGallery} />
              <Route
                exact
                path="/director-message"
                component={DirectorMessage}
              />

              <Route exact component={Page404} />
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.products.items,
});
export default connect(mapStateToProps, { fetchProducts })(App);
