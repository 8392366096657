import React from 'react';
import axios from 'axios';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { withFirebase } from '../../Firebase';
import '../../style/getQuoteForm.css';

const GetQuoteForm = (props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <div>
      <h1>GET QUOTE</h1>
      <form>
        <div className="getquote-input-flex-container">
          <div className="getquote-input-error-group">
            <input
              className={
                errors.name && touched.name
                  ? 'getquote-input-error'
                  : 'getquote-input'
              }
              name="name"
              value={values.name || ''}
              onChange={handleChange}
              type="text"
              placeholder="Name*"
              size="large"
              onBlur={handleBlur}
            />
            {errors.name && touched.name && (
              <div className="invalid-feedback-getquote">{errors.name}</div>
            )}
          </div>
          <div className="getquote-input-error-group">
            <input
              className={
                errors.email && touched.email
                  ? 'getquote-input-error'
                  : 'getquote-input'
              }
              name="email"
              value={values.email || ''}
              onChange={handleChange}
              type="text"
              placeholder="Email Address*"
              size="large"
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <div className="invalid-feedback-getquote">{errors.email}</div>
            )}
          </div>
          <div className="getquote-input-error-group">
            <input
              className={
                errors.companyName && touched.companyName
                  ? 'getquote-input-error'
                  : 'getquote-input'
              }
              name="companyName"
              value={values.companyName || ''}
              onChange={handleChange}
              type="text"
              placeholder="Company Name*"
              size="large"
              onBlur={handleBlur}
            />
            {errors.companyName && touched.companyName && (
              <div className="invalid-feedback-getquote">
                {errors.companyName}
              </div>
            )}
          </div>
        </div>
        <div className="getquote-container getquote-input-error-group">
          <textarea
            className={`message-textarea-getquote ${
              errors.message && touched.message
                ? 'getquote-input-error'
                : 'getquote-input'
            }`}
            name="message"
            value={values.message || ''}
            onChange={handleChange}
            type="text"
            placeholder="Message"
            size="large"
            onBlur={handleBlur}
          />
          {errors.message && touched.message && (
            <div className="invalid-feedback-getquote">{errors.message}</div>
          )}
        </div>
        <div className="form-group-getquote">
          <button
            disabled={isSubmitting ? true : false}
            className="btn-blue btn-medium"
            type="primary"
            onClick={handleSubmit}
            size="large"
            loading={isSubmitting}
          >
            {!isSubmitting ? 'Send Request' : 'Please Wait...'}
          </button>

          {errors.apiError && (
            <div className="invalid-feedback-getquote">{errors.apiError}</div>
          )}
        </div>
      </form>
    </div>
  );
};

const contactusWithFormik = withFormik({
  mapPropsToValues: (props) => ({
    name: props.name,
    email: props.email,
    companyName: props.companyName,
    message: props.message,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('Name is required.')
      .max(50, 'Name must be less than 50 characters.'),
    email: Yup.string()
      .email('Invalid email address.')
      .required('Email is required.'),
    companyName: Yup.string()
      .required('Company name is required.')
      .max(50, 'Company name must be less than 50 characters.'),
    message: Yup.string().max(
      1000,
      'Message must be less than 1000 characters.',
    ),
  }),

  handleSubmit: async (
    values,
    { props, setSubmitting, setErrors, resetForm },
  ) => {
    try {
      const text = `
    <html>
    <head>
    <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 50%;
    }

    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }

    </style>
    </head>
    <body>
    <h2>Client Details</h2>
    <table>
      <tr>
        <td>Name</td>
        <td>${values.name}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>${values.email}</td>
      </tr>
      <tr>
        <td>Company Name</td>
        <td>${values.companyName}</td>
      </tr>
      <tr>
        <td>Message</td>
        <td>${values.message ? values.message : ''}</td>
      </tr>
    </table>
    </body>
</html>
    `;

      const response = await axios.post(
        'https://backend-api24.herokuapp.com/sendemail',
        {
          fromName: 'Palvi Chemicals',
          fromEmail: 'info@palvichemical.com',
          toName: 'Alay Mehta',
          toEmail: 'alay@palvichemical.com',
          subject: 'Website - Home - Get Quote',
          text,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.status !== 200) {
        setErrors({ apiError: response.message });
        setSubmitting(false);
      } else {
        props.onSuccess();
        resetForm({});
        setSubmitting(false);
      }
    } catch (error) {
      setErrors({ apiError: error.message });
      setSubmitting(false);
    }
  },
})(GetQuoteForm);

export default withFirebase(contactusWithFormik);
