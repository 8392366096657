import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PeopleGallery from '../components/PeopleGallery';
import Gallery from 'react-photo-gallery';
import peopleData from '../components/PeopleGallery/data';
import '../style/common.css';
import '../style/typography.css';
import '../style/about-us.css';

import image_banner from '../assests/aboutus/banner/team.jpg';

import factory1_4x3 from '../assests/aboutus/factory/1.jpeg';
import factory2_4x3 from '../assests/aboutus/factory/2.jpeg';
import factory3_4x3 from '../assests/aboutus/factory/3.jpeg';
import factory4_4x3 from '../assests/aboutus/factory/4.jpeg';
import factory5_3x4 from '../assests/aboutus/factory/5.jpeg';
import factory6_4x3 from '../assests/aboutus/factory/6.jpeg';
import factory7_3x4 from '../assests/aboutus/factory/7.jpeg';
import factory8_4x3 from '../assests/aboutus/factory/8.jpeg';
import factory9_4x3 from '../assests/aboutus/factory/9.jpeg';
import factory10_3x4 from '../assests/aboutus/factory/10.jpeg';
import factory11_4x3 from '../assests/aboutus/factory/11.jpeg';

class About extends React.Component {
  render() {
    return (
      <div className="about-us">
        <div className="about-us__section-banner">
          <img src={image_banner} alt="Palvi Team" />
        </div>

        <div className="section about-us__section-main">
          <Container>
            <h2 className="section-heading-primary-light">OUR CORE VALUES</h2>
            <Row className="section-main-block">
              <Col lg={12}>
                <p className="para para--white para--medium">
                  Palvi is a closely held public limited company. Established in
                  1995, we have carved our milestones based on the core values
                  of customer service, trust, and personal network of
                  relationships.
                </p>
                <p className="para para--white para--medium">
                  <strong>> Customer Relationship</strong> - We strongly believe
                  in providing products that our customer asks for, helping us
                  build trust and reliability one step at a time. Our customers’
                  needs is our priority that needs to be met at all times.
                </p>
                <p className="para para--white para--medium">
                  <strong>> Network</strong> - Our long-standing business
                  partnerships enable us to bring the most cost effective,
                  quality solutions for our clients. These nurtured
                  relationships help us customize our delivery too.
                </p>
                <p className="para para--white para--medium">
                  <strong>> Quality</strong> - Our products & materials are
                  sourced from the highest quality suppliers that meet
                  international standards. We take pride in being recognized as
                  a 2 star Export House by DGFT organization.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section about-us__people-gallery">
          <Container>
            <h2 className="section-heading-primary">Team</h2>
            <PeopleGallery data={peopleData.company} />
          </Container>
        </div>

        <div className="section about-us__people-gallery">
          <Container>
            <h2 className="section-heading-primary">Molybdenum Unit</h2>
            <PeopleGallery data={peopleData.molybdenum} />
          </Container>
        </div>

        <div className="section about-us__people-gallery">
          <Container>
            <h2 className="section-heading-primary">Masterbatch Unit</h2>
            <PeopleGallery data={peopleData.masterbatch} />
          </Container>
        </div>

        <div className="section about-us__people-gallery">
          <Container>
            <h2 className="section-heading-primary">Marketing Department</h2>
            <PeopleGallery data={peopleData.marketing} />
          </Container>
        </div>

        <div className="section about-us__people-gallery">
          <Container>
            <h2 className="section-heading-primary">Finance Department</h2>
            <PeopleGallery data={peopleData.finance} />
          </Container>
        </div>

        <div className="section about-us__people-gallery">
          <Container>
            <h2 className="section-heading-primary">Logistics Department</h2>
            <PeopleGallery data={peopleData.logistics} />
          </Container>
        </div>

        <div className="section about-us__people-gallery">
          <Container>
            <h2 className="section-heading-primary">HR Department</h2>
            <PeopleGallery data={peopleData.hr} />
          </Container>
        </div>

        <div className="section about-us__our-factory">
          <Container>
            <h2 className="section-heading-primary">Our Premises</h2>
          </Container>
          <Gallery photos={photos} />
        </div>

        {/* <div className="section about-us__section-details">
          <Container>
            <h2 className="section-heading-primary">Awards & Accolades</h2>

            <Row>
              <Col lg={4} className="d-none d-lg-block">
                <img
                  className="about-us-details-img"
                  alt="Our History"
                  src={image_quality}
                />
              </Col>
              <Col lg={8}>
                <div className="details-text-block">
                  <h2 className="heading-secondary">
                    FIEL International Award
                  </h2>
                  <p className="para para--dark-gray para--large">
                    At Palvi we firmly believe that ‘Quality is paramount to
                    success’.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="d-none d-lg-block">
                <img
                  className="about-us-details-img"
                  alt="Our History"
                  src={image_quality}
                />
              </Col>
              <Col lg={8}>
                <div className="details-text-block">
                  <h2 className="heading-secondary">
                    FIEL International Award
                  </h2>
                  <p className="para para--dark-gray para--large">
                    At Palvi we firmly believe that ‘Quality is paramount to
                    success’.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="d-none d-lg-block">
                <img
                  className="about-us-details-img"
                  alt="Our History"
                  src={image_quality}
                />
              </Col>
              <Col lg={8}>
                <div className="details-text-block">
                  <h2 className="heading-secondary">
                    FIEL International Award
                  </h2>
                  <p className="para para--dark-gray para--large">
                    At Palvi we firmly believe that ‘Quality is paramount to
                    success’.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>*/}
      </div>
    );
  }
}

const photos = [
  {
    src: factory1_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory2_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory3_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory4_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory5_3x4,
    width: 3,
    height: 4,
  },
  {
    src: factory6_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory7_3x4,
    width: 3,
    height: 4,
  },
  {
    src: factory8_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory9_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory10_3x4,
    width: 3,
    height: 4,
  },
  {
    src: factory11_4x3,
    width: 4,
    height: 3,
  },
];

export default About;
