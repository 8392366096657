import React from 'react';
import ReactDOM from 'react-dom';

import '../style/career.css';
import { Row, Col } from 'react-bootstrap';

class Panel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };
  }

  componentDidMount() {
    window.setTimeout(() => {
      const el = ReactDOM.findDOMNode(this);
      const height = el.querySelector('.panel__inner').scrollHeight;
      this.setState({
        height,
      });
    }, 333);
  }

  render() {
    const {
      leftLabel,
      rightLabel,
      leftContent,
      rightContent,
      activeTab,
      index,
      activateTab,
    } = this.props;
    const { height } = this.state;
    const isActive = activeTab === index;
    const innerStyle = {
      height: `${isActive ? height : 0}px`,
    };

    return (
      <div className="panel" role="tabpanel" aria-expanded={isActive}>
        <button className="panel__label" role="tab" onClick={activateTab}>
          <Row>
            <Col lg={6} className="left-side-label">
              {leftLabel}
            </Col>
            <Col lg={6} className="right-side-label">
              {rightLabel}
            </Col>
          </Row>
        </button>
        <div
          className="panel__inner"
          style={innerStyle}
          aria-hidden={!isActive}
        >
          <p className="panel__content">
            <Row>
              <Col lg={6} className="left-side-acordion-data">
                <span>{leftContent.title}</span>
                <ul>
                  {leftContent.list.map((item, index) => {
                    return <li key={`acordion-right-list-${index}`}>{item}</li>;
                  })}
                </ul>
              </Col>
              <Col lg={6} className="right-side-acordion-data">
                <span>{rightContent.title}</span>
                <ul>
                  {rightContent.list.map((item, index) => {
                    return <li key={`acordion-right-list-${index}`}>{item}</li>;
                  })}
                </ul>
              </Col>
            </Row>
          </p>
        </div>
      </div>
    );
  }
}

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

    this.activateTab = this.activateTab.bind(this);
  }

  activateTab(index) {
    this.setState((prev) => ({
      activeTab: prev.activeTab === index ? -1 : index,
    }));
  }

  render() {
    const { panels } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="accordion" role="tablist">
        {panels.map((panel, index) => (
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={this.activateTab.bind(null, index)}
          />
        ))}
      </div>
    );
  }
}

export default Accordion;
