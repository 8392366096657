import React from 'react';
import '../../style/header.css';
import NavBar from '../../components/NavBar';

class Header extends React.Component {
  render() {
    return <NavBar />;
  }
}
export default Header;
