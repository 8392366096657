import { products } from '../data/searchProduct';

// Products Page
import pro_banner from '../assests/products/banner/products.jpg';

import pro_card_int from '../assests/products/card/1int.jpg';
import pro_card_master from '../assests/products/card/2master.jpg';
import pro_card_moly from '../assests/products/card/3moly.jpg';
import pro_card_acid from '../assests/products/card/4acid.jpg';

import pro_banner_int from '../assests/products/banner/1int.jpg';
import pro_banner_master from '../assests/products/banner/2master.jpg';
import pro_banner_moly from '../assests/products/banner/3moly.jpg';
import pro_banner_acid from '../assests/products/banner/4acid.jpg';

// International Trading
import int_card_soap from '../assests/products/international_trading/card/1soap.jpg';
import int_card_textile from '../assests/products/international_trading/card/2textile.jpg';
import int_card_food from '../assests/products/international_trading/card/3food.jpg';
import int_card_cosmetics from '../assests/products/international_trading/card/5cosmetics.jpg';
import int_card_flavour from '../assests/products/international_trading/card/6flavour.jpg';
import int_card_poly from '../assests/products/international_trading/card/7poly.jpg';
import int_card_paint from '../assests/products/international_trading/card/8paint.jpg';
import int_card_plastic from '../assests/products/international_trading/card/9plastic.jpg';
import int_card_mine from '../assests/products/international_trading/card/10mine.jpg';
import int_card_water from '../assests/products/international_trading/card/11water.jpg';
import int_card_other from '../assests/products/international_trading/card/12other.jpg';

import int_banner_soap from '../assests/products/international_trading/banner/1soap.jpg';
import int_banner_textile from '../assests/products/international_trading/banner/2textile.jpg';
import int_banner_food from '../assests/products/international_trading/banner/3food.jpg';
import int_banner_cosmetics from '../assests/products/international_trading/banner/5cosmetics.jpg';
import int_banner_flavour from '../assests/products/international_trading/banner/6flavour.jpg';
import int_banner_poly from '../assests/products/international_trading/banner/7poly.jpg';
import int_banner_paint from '../assests/products/international_trading/banner/8paint.jpg';
import int_banner_plastic from '../assests/products/international_trading/banner/9plastic.jpg';
import int_banner_mine from '../assests/products/international_trading/banner/10mine.jpg';
import int_banner_water from '../assests/products/international_trading/banner/11water.jpg';
import int_banner_other from '../assests/products/international_trading/banner/12other.jpg';

import csr1 from '../assests/csr/activity/1.jpg';
import csr2 from '../assests/csr/activity/2.jpg';
import csr3 from '../assests/csr/activity/3.jpg';
import csr4 from '../assests/csr/activity/4.jpg';
import csr5 from '../assests/csr/activity/5.jpg';
import csr6 from '../assests/csr/activity/6.jpg';
import csr7 from '../assests/csr/activity/7.jpg';
import csr8 from '../assests/csr/activity/8.jpg';
import csr9 from '../assests/csr/activity/9.jpg';
import csr10 from '../assests/csr/activity/10.jpg';
import csr11 from '../assests/csr/activity/11.jpg';
import csr12 from '../assests/csr/activity/12.jpg';

// Masterbatches
import master_card_black from '../assests/products/masterbatches/card/1black.jpg';
import master_card_white from '../assests/products/masterbatches/card/2white.jpg';
// import master_card_additive from '../assests/products/masterbatches/card/3additive.jpg';
import master_card_filler from '../assests/products/masterbatches/card/4filler.jpg';

import master_banner_black from '../assests/products/masterbatches/banner/1black.jpg';
import master_banner_white from '../assests/products/masterbatches/banner/2white.jpg';
// import master_banner_additive from '../assests/products/masterbatches/banner/3additive.jpg';
import master_banner_filler from '../assests/products/masterbatches/banner/4filler.jpg';

import master_card_black_para from '../assests/products/masterbatches/card/blackPara.jpeg';
// import master_card_additive_para from '../assests/products/masterbatches/card/additivePara.jpg';
import master_card_filler_para from '../assests/products/masterbatches/card/fillerPara.jpg';
import master_card_white_para from '../assests/products/masterbatches/card/whitePara.jpg';

// Molybdenum
import moly_card_ammonium from '../assests/products/molybdenum/card/Ammonium Molybdate.jpeg';
import moly_card_trioxide from '../assests/products/molybdenum/card/Molybdenum Trioxide.jpeg';
import moly_card_sodium from '../assests/products/molybdenum/card/Sodium Molybdate.jpeg';

import moly_bag from '../assests/products/molybdenum/banner/bag.jpg';
import moly_fertilizer from '../assests/products/molybdenum/banner/fertilizer.jpg';
import moly_eng from '../assests/products/molybdenum/banner/eng.jpg';
import moly_water from '../assests/products/molybdenum/banner/water.jpg';
import moly_port from '../assests/products/molybdenum/banner/port.jpg';
import moly_mine from '../assests/products/molybdenum/banner/mine.jpg';

// Acid
import acid_card_acetic from '../assests/products/acid/card/acetic.jpeg';
import acid_card_hcl from '../assests/products/acid/card/hcl.png';
import acid_card_nitric from '../assests/products/acid/card/nitric.png';
import acid_card_sulphuric from '../assests/products/acid/card/sulphuric.png';

// Toll
import toll_card_sn from '../assests/products/toll/card/sn.jpg';
import toll_card_oba from '../assests/products/toll/card/oba.jpg';

import sample from '../assests/products/card/2master.jpg';

export const productNotFoundText =
  '* Can’t find what you are looking for? Get in touch with us, and we shall source the product for you.';

export const exportNoteText =
  '<i><strong>Note: We export/distribute all the above products in Canada, Egypt, Kenya, Uganda, Ethiopia, Zimbabwe, Tanzania, Bangladesh, Bahrain, UAE, Qatar, Yemen, Saudi Arabia, UK, Greece, USA, Brazil, Colombia, Costa Rica, Sri Lanka, Argentina, Australia, Ecuador, Bolivia, Chile, Russia, Mexico, Peru, Paraguay, Panama, Philippines, Vietnam, Honduras and Other countries.</strong></i>';

export const productCards = [
  {
    name: 'export',
    img: pro_card_int,
    url: '/products/international',
  },
  {
    name: 'masterbatches',
    img: pro_card_master,
    url: '/products/masterbatches',
  },
  {
    name: 'molybdenum',
    img: pro_card_moly,
    url: '/products/molybdenum',
  },
  {
    name: 'acid',
    img: pro_card_acid,
    url: '/products/acid',
  },
  {
    name: 'toll',
    img: pro_card_acid,
    url: '/products/toll',
  },
];

export const csrSections = [
  {
    name: 'Composition',
    url: '/csr/composition',
  },
  {
    name: 'Boards Approval',
    url: '/csr/board-approval',
  },
  {
    name: 'Scope of activites',
    url: '/csr/scope-of-activites',
  },
];

export const internationalCards = [
  {
    name: 'soap, detergent & laundry',
    url: '/products/international/soap-detergent',
    img: int_card_soap,
  },
  {
    name: 'paper & textile',
    url: '/products/international/paper-textile',
    img: int_card_textile,
  },
  {
    name: 'food ingredients',
    url: '/products/international/food-ingredient',
    img: int_card_food,
  },
  {
    name: 'cosmetics & hair-skin care',
    url: '/products/international/cosmetic',
    img: int_card_cosmetics,
  },
  {
    name: 'flavour & fragrance',
    url: '/products/international/flavour-Fragrance',
    img: int_card_flavour,
  },
  {
    name: 'polyurathene',
    url: '/products/international/polyurathene',
    img: int_card_poly,
  },
  {
    name: 'paint & coating',
    url: '/products/international/paint-conating',
    img: int_card_paint,
  },
  {
    name: 'plastics',
    url: '/products/international/plastics',
    img: int_card_plastic,
  },
  {
    name: 'drilling & mining',
    url: '/products/international/drilling-mining',
    img: int_card_mine,
  },
  {
    name: 'water treatment',
    url: '/products/international/water-treatment',
    img: int_card_water,
  },
  {
    name: 'other products',
    url: '/products/international/other-products',
    img: int_card_other,
  },
];

export const masterbatchesCards = [
  {
    name: 'black masterbatches',
    url: '/products/masterbatches/black-masterbatches',
    img: master_card_black,
  },
  {
    name: 'white masterbatches',
    url: '/products/masterbatches/white-masterbatches',
    img: master_card_white,
  },
  // {
  //   name: 'additive masterbatches',
  //   url: '/products/masterbatches/additive-masterbatches',
  //   img: master_card_additive,
  // },
  {
    name: 'filler masterbatches',
    url: '/products/masterbatches/filler-masterbatches',
    img: master_card_filler,
  },
];

export const acidCards = [
  {
    name: 'acetic acid',
    url: '/products/acid/acetic-acid',
    img: acid_card_acetic,
  },
  {
    name: 'hydrochloric acid',
    url: '/products/acid/hydrochloric-acid',
    img: acid_card_hcl,
  },
  {
    name: 'nitric acid',
    url: '/products/acid/nitric-acid',
    img: acid_card_nitric,
  },
  {
    name: 'sulphuric acid',
    url: '/products/acid/sulphuric-acid',
    img: acid_card_sulphuric,
  },
];

export const tollCards = [
  {
    name: 'Sodium Nitrate',
    url: '/products/toll/sodium-nitrate',
    img: toll_card_sn,
  },
  {
    name: 'Optical Brightening Agent',
    url: '/products/toll/optical-brightening-agent',
    img: toll_card_oba,
  },
];

export const molybdenumCards = [
  {
    name: 'sodium molybdate',
    url: '/products/molybdenum/sodium-molybdate',
    img: moly_card_sodium,
  },
  {
    name: 'ammonium di molybdate',
    url: '/products/molybdenum/ammonium-di-molybdate',
    img: moly_card_ammonium,
  },
  {
    name: 'ammonium hepta molybdate',
    url: '/products/molybdenum/ammonium-hepta-molybdate',
    img: moly_card_ammonium,
  },
  {
    name: 'ammonium tetra molybdate',
    url: '/products/molybdenum/ammonium-tetra-molybdate',
    img: moly_card_ammonium,
  },
  {
    name: 'pure molybdenum trioxide',
    url: '/products/molybdenum/pure-molybdenum-trioxide',
    img: moly_card_trioxide,
  },
  {
    name: 'roasted molybdenum concentrate',
    url: '/products/molybdenum/roasted-molybdenum-concentrate',
    img: moly_card_ammonium,
  },
];

export const allProducts = {
  products: {
    bannerTitle: 'Products',
    title: 'Products',
    banner: pro_banner,
    information: [
      'Palvi Industries Ltd has created a strong network of distribution across the globe with an annual turnover of more than $35 million. We have served over 60 countries across the five continents with LATAM and Africa taking the highest share. We have been awarded 2 Star Export House Status by Government of India for their sustained export performance.',
      'Over the years, Palvi has shifted focus towards the manufacturing sector and valued added trading. The company established Molybdenum derivatives unit near Vadodara in 2009, manufacturing specialty molybdenum salts. Recently, we have commissioned an expanded facility and catering to the demands of agriculture, paint and catalyst manufacturing industries.',
      'In 2014, Palvi masterbatch units were established producing specialty masterbatches. We have an annual  installed capacity of 3000 MT of Black Masterbatches and 5000 MT of Filler Masterbatches. Our products are exported widely and are approved by renowned masterbatch producers and users  of India and the world. In 2019, Palvi has set up a facility to provide tailor made solutions and products to our customers as a way to conduct value added trading and custom manufacturing.',
      'We have secured high prestige for our products based on meeting of international quality standards, exceptional customer service and a trustworthy network of suppliers. These aspects have uniquely positioned Palvi as a quality distributor across multiple continents.',
    ],
    industryCategories: internationalCards,
    manufacturedCategories: [
      { name: 'masterbatches', url: 'masterbatches' },
      { name: 'molybdenum', url: 'molybdenum' },
      { name: 'acid', url: 'acid' },
      { name: 'toll', url: 'toll' },
    ],
    mainCategories: productCards,
  },
  international: {
    bannerTitle: 'INTERNATIONAL <BR/> TRADING',
    title: 'Exported Products',
    banner: pro_banner_int,
    information: [
      'Palvi’s core strength has always been exports. Our company with its network of distributors and suppliers, sources and export a wide range of chemicals, food and feed additives, commodity and specialty chemicals. We export to over five continents, covering more than 60 countries.',
      "We have associated ourselves with some of the renowned vendors domestically and internationally, who supply us with the highest quality materials for our range of industrial chemicals. The company's endeavors are directed towards providing complete and integrated service based on customer’s requirements.",
    ],
    produts: internationalCards,
  },
  masterbatches: {
    bannerTitle: 'Masterbatches',
    title: 'Leading Masterbatches Manufacturer',
    banner: pro_banner_master,
    information: [
      'Palvi is one of the leading producers of Specialty Masterbatches. We have achieved expertise in the manufacturing of Black Masterbatches, Filler Masterbatches, and Additive Masterbatches. Our production capacity currently lies at 5000 MT per annum of Filler Masterbatches and 3000 MT per annum of Black Masterbatches.',
      'Our state-of-the-art manufacturing facilities and expert teams of plastic engineers enables us to manufacture and supply quality products as per customer’s requirements. Our pallette of products targets all major segments and we continuously strive to develop new formulations for our clients. Currently, we are working on biodegradable masterbatches as an effort to conserve environment.',
      'Explore our export-quality and price competitive Specialty Masterbatch product ranges.',
    ],
    produts: masterbatchesCards,
  },
  acid: {
    bannerTitle: 'Acid',
    title: 'Leading Acid Manufacturer',
    banner: pro_banner_acid,
    information: [
      'PIL has started manufacturing, purification and repacking of Industrial acids such Acetic Acid, Hydrochloric Acid, Nitric Acid, Sulfuric Acid and also Liquor Ammonia. We can manufacture upto 1000 tons of all these products every month and export them with minimum turnaround time. We are catering these acids worldwide in small packing (35L - 50L) to large packing (up to 250L) with customization as required by the customers. Explore our products as below.',
    ],
    produts: acidCards,
  },
  toll: {
    bannerTitle: 'Toll Manufactured',
    title: 'Toll Manufactured Products',
    banner: pro_banner_acid,
    information: [
      'Palvi Industries Limited has started production of Sodium Nitrate and Optical Brightening Agents. We can manufacture upto 500 tons of sodium nitrate and around 100 tons optical brightening agents every month and export them with minimum turnaround time. PIL are catering these products worldwide in 25 Kg HDPE Bag/100 Kg Jumbo Bag/IBC’s with customization as required by the customers. Explore our products as below.',
    ],
    produts: tollCards,
  },
  molybdenum: {
    bannerTitle: 'Molybdenum',
    title: 'Molybdenum',
    banner: pro_banner_moly,
    information: [
      'Palvi is amongst India’s top manufacturers of Molybdenum chemicals like Sodium Molybdate, Ammonium Molybdate & Molybdenum Trioxide. We are catering these products to more than 25 countries including the US and Europe, for which our products are REACH registered. Our products comply to FAMI-QS, Halal and Kosher standards.',
    ],
    produts: molybdenumCards,
    table: [
      {
        name: 'Ammonium Di Molybdate',
        synonyms: 'Ammonium Molybdate',
        molecularFormula: '(Nh4)2Mo2O7',
        assay: '99 to 100 %',
        moContent: '56 to 56.5 %',
        mo3Content: '84 to 85 %',
        casNo: '27546 - 07 - 2',
      },
      {
        name: 'Sodium Molybdate',
        synonyms: 'Natrium Molybdate',
        molecularFormula: 'Na2MoO4.2H2O',
        assay: '99 to 100 %',
        moContent: '39 to 39.7 %',
        mo3Content: '59 to 59.5 %',
        casNo: '10102 - 40 - 6',
      },
      {
        name: 'Molybdenum Trioxide',
        synonyms: 'Molybdic Anhydride',
        molecularFormula: 'MoO3',
        assay: '99.95 % min',
        moContent: '66.6 % min',
        mo3Content: '99.95 % min',
        casNo: '1313 - 27 - 5',
      },
      {
        name: 'Ammonium Para Molybdate',
        synonyms: 'Ammonium Molybdate, Ammonium Hepta Molybdate',
        molecularFormula: '(NH4)6Mo7O24.4H2O',
        assay: '98 to 101 %',
        moContent: '53.5 to 55 %',
        mo3Content: '80 to 82.5 %',
        casNo: '12027 - 67 - 7',
      },
      {
        name: 'Ammonium Tetra Molybdate',
        synonyms: 'Ammonium Molybdate, Molybdic Acid',
        molecularFormula: '(NH4)2Mo4O13.2H2O',
        assay: '98 to 100 %',
        moContent: '56.5 to 57.5 %',
        mo3Content: '85 to 86 %',
        casNo: '12207 - 64 - 6',
      },
    ],
  },
};

export const csrSectionDetails = {
  composition: {
    url: 'composition',
    content: [
      { type: 'heading', text: 'Composition of CSR Committee' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `The CSR Committee shall consists of the following directors:`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: '',
          content: {
            'only-text': 'Ilesh Vyas',
            'only-text-1': 'Himanshu Mehta',
            'only-text-2': 'Vibha Mehta',
          },
        },
      },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `The CSR Committee may invite any expert member, senior management officials, and other persons as it deem necessary to the committee meetings.`,
          ],
        },
      },
    ],
  },
  'board-approval': {
    url: 'board-approval',
    content: [
      { type: 'heading', text: 'Boards Approval to CSR Policy' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `The CSR Committee so constituted, shall formulate a Policy on Corporate Social Responsibility (CSR Policy) and recommended it to the Board of the Company.`,
          ],
        },
      },
    ],
  },
  'scope-of-activities': {
    url: 'scope-of-activites',
    content: [
      { type: 'heading', text: 'Scope of Activites' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `The Company may engage in all or any of the following activities:`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: '3.1 Education',
          content: {
            'only-text': `All consumer protection program and activities such as Providing effective consumer grievance redressal mechanism, protecting consumer health and safety, sustainable consumption, consumer service, support and compliant resolution, Setting up libraries for schools and colleges`,
            'only-text-1': 'Livelihood enhancement projects',
            'only-text-2':
              'Whole School improvement along with school’s infrastructure.',
            'only-text-3':
              'Capacity building of teachers and other stakeholders',
            'only-text-4': 'Supplementary education',
            'only-text-5':
              'Provide fees for a period of one year or more to the poor and meritorious, preferably girl students of the school in the operational area of the Company to enable them to get uninterrupted education. Provide scholarships',
            'only-text-6': 'Improving Educational Facilities in general.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: '3.2 Health and Care',
          content: {
            'only-text': `Eradicating hunger, poverty and malnutrition`,
            'only-text-1': 'Promoting preventive health care',
            'only-text-2': 'Sanitation',
            'only-text-3': 'Making available safe drinking water',
            'only-text-4':
              'Contribution to the “Swach Bharat Kosh‟ set-up by the Central Government for the promotion of sanitation',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: '3.3 Environment Sustainability',
          content: {
            'only-text': `Ensuring environmental sustainability`,
            'only-text-1': 'Ensuring ecological balance',
            'only-text-2': 'Protection of Flora and Fauna',
            'only-text-3': 'Animal Welfare',
            'only-text-4': 'Agro forestry',
            'only-text-5': 'Conservation of natural resources',
            'only-text-6': 'Maintaining quality of soil, air and water',
            'only-text-7':
              'Contribution to the “Clean Ganga Fund‟ setup by the Central Government for rejuvenation of river Ganga.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: '3.4 Equality',
          content: {
            'only-text': `Promoting gender equality`,
            'only-text-1': 'Empowering women',
            'only-text-2': 'Setting up homes and hostel for women and orphans',
            'only-text-3':
              'Setting up old age home, such other facilities for senior citizens',
            'only-text-4': 'Day care centres',
            'only-text-5':
              'Adopting measures for reducing inequality faced by socially and economically backward groups',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: '3.5 Rural Development / Transformation',
          content: {
            'only-text': `Supporting Farm and non-farm livelihoods`,
            'only-text-1': `Improving water conservation and rain-water harvesting`,
            'only-text-2': `Using technology, upgrading infrastructure towards delivering need based information & services for improving quality of life.`,
            'only-text-3': `Improving food security and enhancing nutrition`,
            'only-text-4': `Developing Community infrastructure, building capacity of the community and ensuring sustainability.`,
          },
        },
      },
      {
        type: 'list',
        data: {
          title: '3.6 Other Initiatives',
          content: {
            'only-text': `To undertake other need based initiatives in compliance with schedule VII of the Companies Act, 2013 as amended from time to time.`,
          },
        },
      },
    ],
  },
  'implementation-of-activities': {
    url: 'implementation-of-activities',
    content: [
      {
        type: 'list',
        data: {
          title: 'Implementation of Activities',
          content: {
            'only-text': `In furtherance of specific CSR projects or programmes, the Company may directly take up the project, collaborate with NGOs, partnership with stakeholders, including other companies, registered trusts and societies, in order to leverage upon the collective expertise, wisdom and experience that such relationships may bring.`,
            'only-text-1': `The investment in CSR should be project based and for every project time framed periodic mile stones should be finalized at the outset.`,
            'only-text-2': `If in the opinion of the CSR Committee, the implementation of CSR projects requires specialized knowledge and skills, and if the Company does not have such expertise in-house, the wherewithal, and dedicated staff to carry out such activities, the CSR Committee may decide to avail the services of external specialized agencies for the implementation of such CSR projects.`,
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Such specialized agencies would include:',
          content: {
            'only-text': `Community based organization whether formal or informal.`,
            'only-text-1': `Elected local bodies such as Panchayats`,
            'only-text-2': `Voluntary Agencies (NGOs)`,
            'only-text-3': `Institutes/Academic Organizations`,
            'only-text-4': `Trusts, Mission etc.`,
            'only-text-5': `Self-help groups`,
            'only-text-6': `Government, Semi Government and autonomous Organizations.`,
            'only-text-7': `Standing Conference of Public Enterprises (SCOPE)`,
            'only-text-8': `Mahila Mondals/Samitis and the like Contracted agencies for civil works Professional Consultancy Organization etc.`,
          },
        },
      },
      { type: 'heading-sub-1', text: 'Gallery' },
      {
        type: 'gallery',
        data: {
          photos: [
            {
              src: csr1,
              width: 4,
              height: 2,
            },
            {
              src: csr2,
              width: 4,
              height: 2,
            },
            {
              src: csr3,
              width: 4,
              height: 2,
            },
            {
              src: csr4,
              width: 4,
              height: 2,
            },
            {
              src: csr5,
              width: 4,
              height: 2,
            },
            {
              src: csr6,
              width: 4,
              height: 2,
            },
            {
              src: csr7,
              width: 4,
              height: 2,
            },
            {
              src: csr8,
              width: 4,
              height: 2,
            },
            {
              src: csr9,
              width: 4,
              height: 2,
            },
            {
              src: csr10,
              width: 4,
              height: 2,
            },
            {
              src: csr11,
              width: 4,
              height: 2,
            },
            {
              src: csr12,
              width: 4,
              height: 2,
            },
          ],
        },
      },
    ],
  },
  'statutory-compliance': {
    url: 'statutory-compliance',
    content: [
      { type: 'heading', text: 'Statutory Compliance' },
      {
        type: 'download-button',
        data: {
          ref: '/downloads/csr/MGT-9.pdf',
          name: 'MGT-9',
        },
      },
    ],
  },
};

export const exportProductDetail = {
  'sodium-lauryl-ether-sulphate': {
    url: 'sodium-lauryl-ether-sulphate',
    productRange: [{ name: 'sodium lauryl ether sulphate' }],
    content: [
      { type: 'heading', text: 'sodium lauryl ether sulphate (SLES)' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `<b>Chemical Formula: CH<sub>3</sub>(CH<sub>2</sub>)<sub>10</sub>CH<sub>2</sub>(OCH<sub>2</sub>CH<sub>2</sub>)<sub>n</sub>OSO<sub>3</sub>Na</b>
`,
          ],
        },
      },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `<b>Appearance:</b> SLES (Sodium Lauryl Ether Sulfate) is a yellowish-white paste that is used in a number
of personal care and cleaning items as an anionic detergent or surfactant (cosmetics, soaps,
handwash etc). 
`,
            `It is used as a washing agent in a variety of everyday items. SLES is made from natural sources such
as palm kernel and coconut oils. This chemical compound can be found in the ingredients portion of
your shampoo's packet or container. Palvi Chemicals is a dominant <b>SLES distributor in Ghana</b>.`,
          ],
        },
      },
      { type: 'heading-sub-1', text: 'The Mechanism' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `As a surfactant, sodium lauryl ether sulfate is commonly used. A surfactant is a substance that
reduces the surface tension between ingredients, which is what makes it a good cleaning and
foaming agent. This chemical compound can be used in a variety of household cleansers, as well as
beauty and self-care products. 
`,
            `SLES (Sodium Lauryl Ether Sulphate) is a chemical material with a chemical composition similar to
SLS, but it is much milder and kinder to the skin than SLS. Palvi Chemicals has been recognized as
one of the leading <b>SLES exporters in Nigeria</b>.`,
            `There have been several misconceptions about SLS's use being harmful to the skin, but the fact is
that there is no scientific proof that shows the dangers of using SLES in everyday life.`,
          ],
        },
      },
    ],
  },
  'toluene-di-isocyanate': {
    url: 'toluene-di-isocyanate',
    content: [
      { type: 'heading', text: 'Toluene Di Isocyanate' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Chemical Formula of TDI: C<sub>9</sub>H<sub>6</sub>N<sub>2</sub>O<sub>2</sub> or CH<sub>3</sub>C<sub>6</sub>H<sub>3</sub>(NCO)<sub>2</sub>`,
          ],
        },
      },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Among the companies that supply Toluene Diisocyanate (TDI) chemicals to different industries, Palvi Chemicals stands out as the best manufacturer as well as supplier of Toluene Diisocyanate. Talking about its appearance, TDI is a colourless to light yellow liquid that can be dissolved in a wide variety of organic solvents. It is an artificial hybrid of the 2,4 as well as 2,6-isomers. Polyurethane foams are made primarily using these isomers. When heated, TDI releases toxic cyanides and nitrogen oxides. TDI can cause severe respiratory problems due to its irritating effects on human tissues, especially the mucous membranes. There is a high probability that these isomers cause cancer in humans.`,
          ],
        },
      },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `TDI belongs to the diisocyanate family, which is used in numerous commonplace things. TDI is a versatile chemical that finds use in many different industries. TDI is formed when diaminotoluene is reacted with phosgene. The usage of TDI is on the rise.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Properties',
          content: {
            'only-text-0': `In liquid or crystal state while kept at normal temperatures.`,
            'only-text-1': `Usually Colourless, but turns yellow in the open air.`,
            'only-text-2': 'A pungent odour',
            'only-text-3':
              'Soluble in both water as well as other organic solvents.',
            'only-text-4': 'Highly reactive',
            'only-text-5': 'Exist in 2 different isomers: 2,4 TDI and 2,6 TDI',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0': `Bedding, carpet underlay, furniture, packing, as well as so on are only some of the many uses for polyurethanes, which are typically produced using TDI. It's also used to make elastomers, adhesives, sealants, and paints.`,
            'only-text-1': `Improved fuel economy and reduced energy consumption are two of the many benefits of using TDI in the automotive industry.`,
            'only-text-2': `Products for both outdoor as well as indoor use are developed using TDI for the synthetic recreational surface industry.`,
            'only-text-3': `Restoring floors, sealing concrete, and waterproofing walls all seem like viable options, and TDI seems to be a great replacement.`,
            'only-text-4': `Side windows, as well as windshields for cars and trucks, are installed using TDI.`,
          },
        },
      },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `For any of your above applications, if you are looking for the most prevalent as well as distinct exporter of Toluene Diisocyanate, Palvi Chemicals is the most trusted company that can fulfil all your industrial chemical needs.`,
          ],
        },
      },
    ],
  },
  'soda-ash': {
    url: 'soda-ash',
    content: [
      { type: 'heading', text: 'Sodium Carboante (Soda Ash)' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Sodium carbonate hydrates are several forms of the inorganic chemical sodium carbonate (Na<sub>2</sub>CO<sub>3</sub>). There are many different types of sodium carbonate, all of which are odourless, white in colour, as well as water-soluble salts that produce mildly alkaline water solutions. To create it, traditionally, people burned plants that thrived in areas high in salt. Sodium Carbonate is widely popular as Soda Ash because its ashes are very different from wood ashes. The Solvay process, which utilises sodium chloride and limestone, allows for its mass production.`,
            `Inorganic chemical compound; example(s): sodium carbonate. What we call "soda ash" is actually sodium carbonate. Trona is mined for its ability to be refined into soda ash. In lakes, a combination of evaporation processes produces trona, a double salt made up of sodium hydrogen carbonate as well as sodium carbonate.`,
            `Soda ash, or sodium carbonate, is the most crucial of all the fundamental heavy compounds. It is far less dangerous to work with than sodium hydroxide because it is non-corrosive.`,
            `If you are looking for the best quality Sodium Carbonate for your industrial use, Palvi Chemicals is the most distinguished supplier of Sodium Carbonate.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Properties',
          content: {
            'only-text-0': `Molar Mass	=	105.9888 g/mol`,
            'only-text-1': `Density	=	2.54 g/cm³`,
            'only-text-2': 'Boiling Point	=	1,600°C',
            'only-text-3': 'Melting Point	=	851°C',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0': `Detergents, soaps, and even paper are made with it.`,
            'only-text-1': `Manufacturing Water glass, sodium phosphate, borax, as well as many other salt compounds that require borax as a key ingredient.`,
            'only-text-2':
              'In the brick making industry, it serves as a wetting agent.',
            'only-text-3':
              'It acts as both a scrubber and a foaming agent in toothpaste.',
            'only-text-4':
              'As a pH adjuster, this substance has several practical applications.',
            'only-text-5': 'As Water softeners',
            'only-text-6':
              'Used for acid standardisation and analysis in the lab.',
          },
        },
      },
    ],
  },
  'sodium-bicarbonate': {
    url: 'sodium-bicarbonate',
    content: [
      { type: 'heading', text: 'Sodium Bicarbonate' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Common names for sodium bicarbonate include "baking soda" and "bicarbonate of soda," and its chemical formula is NaHCO<sub>3</sub>. A salt, sodium bicarbonate consists of the sodium cation (Na+) and the bicarbonate anion (HCO<sub>3</sub>). Sodium bicarbonate is a crystalline white powder that can also be found in its solid form. The flavour is mildly salty and alkaline, not unlike washing soda. Nahcolite occurs as a mineral in nature.`,
            `Due to its long history of use and widespread availability, cooking soda, bread soda, baking soda, as well as bicarbonate of soda are all common names for the same salt. While "baking soda" is the more popular name in the US, "bicarbonate of soda" is the more prevalent term in Australia, the UK, as well as Ireland.`,
            `Decomposing potassium hydro carbonate/bicarbonate into (di)potassium carbonate and (di)potassium oxide produces two Carbon Dioxide molar equivalents, which is where the prefix bi in bicarbonate derives from. This naming scheme predates molecular knowledge (potash). The exact chemical contents of these compounds, which were not known at the time the word bi-carbonate of potash was established, are now expressed by their current chemical formulae.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Properties',
          content: {
            'only-text-0': `Crystalline chemical is white in colour.`,
            'only-text-1': `Ethanol and methanol and acetone are the only solvents that show any degree of solubility for this chemical.`,
            'only-text-2':
              'This chemical dissolves in water at a rate of 96 grammes per litre at 20 degrees Celsius.',
            'only-text-3':
              'It is important to note that sodium bicarbonate crystallises into a monoclinic crystal lattice.',
            'only-text-4': 'Molar Mass	=	84.0066 g/ mol',
            'only-text-5': 'Density	=	Solids - 2.20 g/cm3  and  Powder 1.1 - 1.3',
            'only-text-6': 'Boiling Point	=	851°C',
            'only-text-7': 'Melting Point	=	50°C',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0': `It can be applied to the floor or walls to keep cockroaches away as well as prevent mould formation.`,
            'only-text-1': `It's a deodorizer and antibacterial, plus it keeps pits fresh and itch-free.`,
            'only-text-2': `In the kitchen, it's used for baking and other baking-related tasks.`,
            'only-text-3':
              'It is administered intravenously to help mitigate the negative effects of chemotherapy.',
            'only-text-4':
              'The antibacterial characteristics of this compound make it ideal for cleaning kitchenware.',
            'only-text-5': 'You can use it to keep your mouth and teeth clean.',
          },
        },
      },
    ],
  },
  'phosphoric-acid': {
    url: 'phosphoric-acid',
    content: [
      { type: 'heading', text: 'Phosphoric Acid' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Phosphoric acid, also known as orthophosphoric acid, is an inorganic molecule that has the formula H<sub>3</sub>PO<sub>4</sub> in its chemical form. The most frequent form of phosphoric acid is an 85 per cent aqueous solution, which is an odourless, colourless, as well as non-volatile syrupy liquid. Although phosphoric acid exists as a colourless solid, it is typically encountered as an aqueous solution. It plays a significant role in the chemical industry and can be found in a variety of different fertilisers. It is possible to differentiate this particular acid from other "phosphoric acids," such as pyrophosphoric acid, by referring to it by its more specific term, "orthophosphoric acid."`,
            `Nevertheless, when people talk about "phosphoric acid," they usually mean this particular chemical, and the IUPAC nomenclature currently uses that name. The acid is distinguished from connected phosphoric acids, sometimes known as polyphosphoric acids, by the prefix ortho-, which denotes an orthogonal relationship. The pure form of Phosphoric acid is a solid at ambient temperature and pressure, despite the fact that it is a naturally occurring non-toxic acid.`,
          ],
        },
      },
      { type: 'heading-sub-1', text: 'Physical Properties' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Phosphoric acid in its purest form exists as a white crystalline solid with a melting point of 42.35°C. It has a density of 1.885 g/mL as well as it is odourless, colourless, and viscous when it is less dense. In its natural state, it does not exhibit any poisonous or volatile properties. The quantity of phosphoric acid that is most frequently utilised is 85% in water (H<sub>2</sub>O).`,
          ],
        },
      },
      { type: 'heading-sub-1', text: 'Chemical Properties' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Phosphoric acid consists of 3 atoms of hydrogen that are acidic and can be replaced. As a consequence of this, it responds differently compared to the other mineral acids. It is possible for it to react with bases to form three different classes of salts by substituting one, two, or three hydrogen atoms in a certain order, such as Na<sub>2</sub>HPO<sub>4</sub>, NaH<sub>3</sub>PO<sub>4</sub>, and Na<sub>3</sub>PO<sub>4</sub> respectively.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0': `Removing rust`,
            'only-text-1': `Food and beverage`,
            'only-text-2': 'Personal care',
            'only-text-3': 'Agriculture',
            'only-text-4': 'Pharmaceutical',
            'only-text-5': 'Used as electrolyte in fuel cells',
            'only-text-6': 'Synthetic detergents',
            'only-text-7': 'Water as well as Metal treatment',
          },
        },
      },
    ],
  },
  'hydrogen-peroxide': {
    url: 'hydrogen-peroxide',
    content: [
      { type: 'heading', text: 'Hydrogen Peroxide' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Palvi Chemicals ranks among the most reliable as well as leading Hydrogen Peroxide manufacturers as well as suppliers. All of the chemicals we supply are of the highest quality and efficiency. Toxicity varies among these acid types since they are utilised both in dilute and concentrated forms in different manufacturing processes. Therefore, extreme caution and safety measures should be taken when working with such chemicals. Before shipping it out to consumers, we make sure the Hydrogen Peroxide meets all of the standards set by various industries. Chemistry-wise, it is dominated by the O-O bond (between oxygen atoms).`,
            `Hydrogen peroxide, as a molecule with only one oxygen–oxygen link, is the simplest type of peroxide. Over time, it breaks down when exposed to light. The standard method of storing hydrogen peroxide involves mixing it with a stabiliser and a dilute acidic solution before placing it in a dark container. Additionally, biological systems. The body also produces hydrogen peroxide.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Properties',
          content: {
            'only-text-0': `In its purest form, hydrogen peroxide is barely visible to the naked eye (a very light blue).`,
            'only-text-1': `The highest temperature at which it may be expected to boil is 150.2 °C.`,
            'only-text-2':
              'Hydrogen peroxide has a melting point of -0.43 degrees Celsius.',
            'only-text-3':
              'Hydrates are formed when it is dissolved in water at any concentration.',
            'only-text-4':
              'Hydrogen peroxide has a molar mass of 34.0147 g/mol.',
            'only-text-5': 'There is a mildly pungent smell about it.',
            'only-text-6':
              'It has a density of 1.11 g/cc when dissolved in water and a density of 1.450 g/cc when isolated and purified.',
            'only-text-7': `Hydrogen peroxide dissolves in alcohol and ether but not in petroleum ether.`,
          },
        },
      },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Hydrogen peroxide has many different applications, including but not limited to those of a bleaching agent, disinfectant, stain remover (for both hair and fabric), and producer of organic compounds. It's a light blue fluid that has several industrial cleaning applications.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0': `Bleaching`,
            'only-text-1': `Manufacturing organic compounds`,
            'only-text-2': 'Sewage treatment',
            'only-text-3': 'Disinfectant',
            'only-text-4': 'Propellant',
            'only-text-5': 'Household use',
            'only-text-6': 'Glowsticks',
            'only-text-7': 'Horticulture',
            'only-text-8': 'Fishkeeping',
          },
        },
      },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Palvi Chemicals - engaged in an international trade business managing the Export and Import of chemicals, acids, minerals and many other products globally is the most prevalent as well as noted exporter of Hydrogen Peroxide.`,
          ],
        },
      },
    ],
  },
  'dextrose-monohydrate': {
    url: 'dextrose-monohydrate',
    content: [
      { type: 'heading', text: 'Dextrose Monohydrate' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Palvi Chemicals is the most distinct as well as a noted distributor of Dextrose Monohydrate. We are the trusted manufacturer as well as exporter of the best quality chemicals used in various industries.`,
            `Dextrose Monohydrate is a naturally occurring carbohydrate as well as a monosaccharide. It is the monohydrate form of D-glucose, which has the formula C6H14O7. The nutrients and electrolytes that are lost can be replaced with dextrose. The agent contributes to the production of metabolic energy and serves as the primary component of ORS. Additionally, it can be found in intravenous (IV) fluids, which are given or injected to ICU patients who need intensive care as well as are unable to absorb nutrients through the digestive process. In addition to restoring normal levels of glucose in the blood, solutions that contain dextrose also provide calories, have the potential to help reduce the amount of glycogen lost in the liver, and help preserve protein. In addition, dextrose has a role in the production of proteins as well as the metabolism of lipids.`,
            `Dextrose, a kind of sugar, is often derived from cereal grains like corn and wheat. Dextrose and glucose, the sugar found in blood, are almost similar compounds. Because of this, the human body can rapidly convert it into usable energy. Dextrose is commonly added to foods as a preservative and an artificial sweetener.`,
            `Dextrose can either improve or detract from health. If you suffer from a medical problem, watch how much dextrose you consume.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0': `Food`,
            'only-text-1': `Beverage`,
            'only-text-2': 'Pharmaceutical',
            'only-text-3': 'Health care products',
            'only-text-4': 'Agriculture',
            'only-text-5': 'Animal Feed',
            'only-text-6': 'Poultry',
          },
        },
      },
    ],
  },
  'chlorinated-paraffin': {
    url: 'chlorinated-paraffin',
    productRange: [{ name: 'Chlorinated Paraffin' }],
    content: [
      { type: 'heading', text: 'Chlorinated Paraffin' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `When it comes to its physical properties, chlorine paraffin is a solid mass of one substance. This substance might be colourless, a very light yellow, or a golden honey hue. Palvi Chemicals is one of the most distinguished exporters of Chlorinated Paraffin as well as the entire UAE region.`,
            `Chlorinated paraffin is created as a result of the reaction between chlorine and other regular paraffin fractions during the petroleum distillation process, and it is a polychlorinated n-alkane combination. Commercial products typically have carbon chain lengths between 10 and 30, and their chlorine concentrations sit between 40 and 70 per cent. Chlorinated paraffin is used in a wide variety of applications, including as a polyvinyl chloride plasticizer, an extreme-pressure addition to metal-machining fluids, and an additive to paints, coatings, and sealants to increase their resistance to chemicals and water, and as a flame retardant in these same materials. There was a complete lack of data concerning occupational exposure limits.`,
            `Products on the market have been labelled as compounds with unknown or changing compositions. Mixtures of chlorinated n-alkanes (CPs) comprise thousands of isomers and homologues that are difficult, if not impossible, to separate using standard analytical methods.`,
          ],
        },
      },
      { type: 'heading-sub-1', text: 'Properties' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `The physical properties of chlorine paraffin are those of homogenous material. Its colour ranges from colourless to yellow, amber, and honey yellow. Chlorine paraffin's density, viscosity, solubility, thermal stability, as well as refractive index are all noteworthy properties. As the HCl is released during heating, chlorine paraffin typically darkens.`,
          ],
        },
      },
      { type: 'heading-sub-1', text: 'Applications' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `A basic application of Chlorinated Paraffin (CP) manufactured by one of the most distinct suppliers of Chlorinated Paraffin - Palvi Chemicals, is as a flame retardant. Chlorinated paraffin (CP) releases a lot of hydrogen chloride (HCI) when heated to high temperatures. HCI aids in the maturation of char during its compressed state. In its vapour form, it can be used as a flame toxin.`,
            `Chlorinated paraffin (CP) is used in numerous industrial processes in over 200 different formulations, including: Adhesives, Caulks, Plastics, Coolant in metalworking fluids, Additives, Textiles, Leather fat, Coating, Upholstery furniture, Flooring`,
          ],
        },
      },
    ],
  },
  'caustic-soda': {
    url: 'caustic-soda',
    productRange: [{ name: 'Caustic Soda' }],
    content: [
      { type: 'heading', text: 'Caustic Soda' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Palvi Chemicals is one of the excellent as well as most trusted distributors of Caustic Soda. Since our inception, we are manufacturing and supplying the highest quality industrial chemicals`,
            `Lye, often known as Caustic Soda, is the common name for the inorganic substance sodium hydroxide (chemical formula: NaOH). The cations (Na+) and anions (OH-) make up this white solid ionic combination.`,
            `Sodium hydroxide can cause severe chemical burns and is a caustic base as well as alkali that is capable of destroying proteins at room temperature. It is very soluble in water and can absorb both carbon dioxide as well as humidity from the air. It forms a series of hydrates with water, denoted by the formula NaOHnH2O. NaOHH2O crystallises from aqueous solutions between 12.3 and 61.8°C. Publicly available "sodium hydroxide" typically contains this monohydrate rather than the anhydrous form, thus it's possible that the former is being utilised instead.`,
            `Sodium hydroxide, one of the most basic hydroxides, is frequently used as a pH demonstration tool in chemistry classrooms.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Properties',
          content: {
            'only-text-0': `Melting point = 591°K, Appearance = white and solid.`,
            'only-text-1': `There is no evidence of instability in this compound.`,
            'only-text-2':
              'Bitter and unpleasant, sodium hydroxide is not pleasant to work with.',
            'only-text-3':
              'There is a marked increase in solubility in water and a decrease in solubility in alcohol.',
            'only-text-4': 'Strong alkalinity characterises sodium hydroxide.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Appications',
          content: {
            'only-text-0': `Cleaning & Disinfectant Products`,
            'only-text-1': `Pharmaceuticals & Medicine`,
            'only-text-2': 'Fuel cell production',
            'only-text-3': 'Water Treatment',
            'only-text-4': 'Food Production',
            'only-text-5': 'Wood & Paper Products',
            'only-text-6': 'Aluminum Ore Processing',
          },
        },
      },
    ],
  },
  'calcium-chloride': {
    url: 'calcium-chloride',
    productRange: [{ name: 'calcium chloride' }],
    content: [
      { type: 'heading', text: 'Calcium Chloride' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Although calcium chloride was first identified in the 15th century, it was not seriously studied until the 18th century. Since it was not manufactured on a commercial scale until the ammonia-soda method was introduced for making soda ash, all of the early work was done on laboratory-prepared samples. Before its usefulness was recognised, it was actually regarded as a byproduct.`,
            `It is a calcium-chlorine ionic compound. When cooled to normal temperature, it takes on a pure white crystalline solid appearance. It is hygroscopic because of its high solubility in water. It has a large enthalpy change in solution and no discernible smell. This substance is commonly employed as a de-icing agent and dust suppressant.`,
            `The thermodynamics of CaCl2 solutions was the subject of a large number of research published in the 1980s. Many of these experiments were designed to test and refine the Pitzer equations for calculating the activity coefficients as well as many other parameters present in the electrolyte solutions. Commercial quantities of calcium chloride can be made through a number of different processes, including the refining of natural brines, the reaction of CaCl2 with ammonium chloride, as well as the reaction of calcium carbonate with hydrochloric acid.`,
            `Palvi Chemicals stands in the top 10 most trusted exporters of Calcium Chloride as well as the UAE region. We are popularly known to manufacture as well as export the best quality industrial chemicals.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Properties',
          content: {
            'only-text-0': `Molar Mass = 110.98 g/mol`,
            'only-text-1': `Density	= Anhydrous: 2.15 g/cm3`,
            'only-text-2': 'Boiling Point	=	1,935 °C',
            'only-text-3': 'Melting Point	=	772 °C',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0': `De-icing as well as freezing-point depression`,
            'only-text-1': `Road surfacing`,
            'only-text-2': 'Food',
            'only-text-3': 'Drying operations related to laboratory',
            'only-text-4': `Manufacturing Activated Charcoal`,
          },
        },
      },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Palvi Chemicals is the most popular manufacturer and supplier of industrial chemicals, minerals, as well as other products and, is also the most distinct as well as reputed distributor of Calcium Chloride.`,
          ],
        },
      },
    ],
  },
  'corn-starch': {
    url: 'corn-starch',
    productRange: [{ name: 'corn starch' }],
    content: [
      { type: 'heading', text: 'Corn Starch' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Starch obtained from corn (maize) grain is referred to as corn starch, maize starch, or cornflour (British English). The starch comes from the endosperm of the kernel. Corn starch is a common food additive used for its thickening properties and in the production of corn syrup and other sugars. Corn starch has several uses in the industry thanks to its adaptability and malleability; it is used in everything from adhesives and paper products to anti-sticking agents and textiles. Medical uses include treating glycogen storage disease by administering glucose. Order your desired quantity of Corn Starch in bulk from Palvi Chemicals - an excellent manufacturer as well as supplier of Corn Starch and get it delivered to your premises.`,
            `A little fermentation occurs when the corn is steeped for 30 to 48 hours. After being separated, the endosperm, as well as germ, are ground in two distinct processes. Washing them gets rid of the starch. Hydro cyclones and centrifuges are typically used to separate the starch from the fibres, cereal germ, corn steep liquor, as well as corn gluten before drying. Wet milling describes this process. Last but not least, the starch could be modified for use in specific contexts.`,
            `Corn starch is mostly utilised in the food and household goods industries, but it also has a number of other uses, including as a chemical component in some products and as a medical remedy for some illnesses.`,
          ],
        },
      },
      { type: 'heading-sub-1', text: 'Applications' },
      {
        type: 'list',
        data: {
          title: 'Culinary Applications',
          content: {
            'only-text-0': `Soups, sauces, gravies, and custards can be thickened by mixing corn starch with a colder liquid to create a paste or slurry. In some cases, it is preferred over regular flour since it results in a more see-through mixture. When heated, the molecular chains of starch unwind, allowing individual chains to clash with one another to create a mesh as well as make the liquid thicker. As an anti-clumping agent, it is frequently included in powdered sugar (also known as icing or confectioner's sugar).`,
            'only-text-1': `One common substitute for corn starch is arrowroot starch, which can be used at the same ratio.`,
            'only-text-2':
              'Manufacturers of foods like cheese and yoghurt reduce production costs by adjusting the amount of maize starch added.',
            'only-text-3':
              'Corn starch manufactured by Palvi Chemicals can be used as a coating on chicken nuggets to improve their crispiness and oil absorption during frying.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Non-Culinary Applications',
          content: {
            'only-text-0': `One of the ingredients in baby powder could be corn starch. Corn starch has potential use in the manufacturing of bioplastics and even airbags.`,
            'only-text-1': `Corn starch is a key ingredient in the production of glue, a common adhesive used in the production of paste papers. In contrast to wheat starch, the corn starch dries with a very little sheen. It can also serve the purpose of adhesive in the preservation of books and other paper materials.`,
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Medical Applications',
          content: {
            'only-text-0': `Diaphragms, medical gloves as well as condoms are all examples of natural latex medical products, and they all use corn starch as their major anti-stick component. Any Pharmaceutical company can place their bulk order of Corn Starch for the manufacturing of medical products from Palvi Chemicals.`,
            'only-text-1': `Glycogen storage disorder patients can keep their blood sugar levels steady with the help of glucose given by corn starch. Glucose fluctuations can be avoided by giving corn starch to infants as young as 6-12 months of age`,
          },
        },
      },
    ],
  },
  'cocamidopropyl-betaine': {
    url: 'cocamidopropyl-betaine',
    productRange: [{ name: 'cocamidopropyl betaine' }],
    content: [
      { type: 'heading', text: 'cocamidopropyl betaine (CAPB)' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `<b>Appearance:</b> CAPB is a surfactant that comes in a viscous pale yellow solution and is used in
personal care goods. 
`,
            `Cocamidopropyl betaine (CAPB) is a compound derived from coconut oil and
dimethylaminopropylamine that is a mixture of closely related organic compounds. CAPB is a
surfactant that comes in a viscous pale-yellow solution and is used in personal care goods.
Cocamidopropyl betaine has largely taken the place of cocamide DEA.`,
            `Palvi Chemicals is one of the prominent <b>CAPB suppliers in Ghana.</b>`,
            `Despite its name, cocamidopropyl betaine is not made from the amino acid betaine. Instead, it is
made in two steps, with the first step being the reaction of dimethylaminopropylamine (DMAPA)
with fatty acids from coconut or palm kernel oil (lauric acid, or its methyl ester, is the main
constituent).`,
            `Shampoos use Cocamidopropyl betaine as a foam booster. It is a medium-strength surfactant that&#39;s
also used in hand soaps and other bath products. It is also used in cosmetics as an emulsifier and
thickener, as well as to minimize inflammation caused by solely ionic surfactants. It is also used in
hair conditioners as an antistatic agent that does not irritate skin or mucous membranes. Some
reports, however, suggest that it is an allergen.`,
            `CAPB is obtained as an aqueous solution with a 30 percent concentration.`,
            `Palvi Chemicals is the leading <b>CAPB supplier in Nigeria</b>.`,
            `The impurities AA and DMAPA are the most significant because they have been linked to skin
sensitization reactions. By using a moderate amount of chloroacetate and precisely adjusting the pH
value during the betainization reaction, along with routine analytical monitoring, these by-products
can be avoided.`,
            `CAPB is also used in the formation of gas hydrates as a co-surfactant with sodium dodecyl sulfate. As
an additive, CAPB aids in the scaling-up of the gas hydrate formation process.`,
          ],
        },
      },
    ],
  },
  'zinc-sulphate': {
    url: 'zinc-sulphate',
    productRange: [{ name: 'zinc sulphate' }],
    content: [
      { type: 'heading', text: 'zinc sulphate' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `<b>Appearance:</b> Zinc sulfate is a colorless crystalline solid. All forms of Zinc Sulfate are soluble
in water. All are noncombustible. 
`,
            `Zinc Sulfate has the chemical formula ZnSO<sub>4</sub> and is an inorganic chemical. Zinc Sulfate is the dietary supplement. It is also known as Zincate and Zinc Sulfate. This chemical is on the World Health Organization's List of Essential Medicines. You should avoid taking this medication with foods high in Phosphoros or Calcium because it may make your body absorb it more difficult.`,
            `Palvi Chemicals is one of the most distinguished <b>Zinc Sulfate suppliers in Ghana.</b>`,
            `Zinc sulfate is a zinc supplement that is found in animal feeds, fertilisers, toothpaste, and agricultural sprays. Like many zinc compounds, zinc sulfate can be used to suppress moss growth on roofs.`,
            `Zinc sulfate can be used as a zinc supplement in the brewing process. Zinc is a vital component for optimal yeast health and performance, although it is not required for low-gravity beers because the grains frequently used in brewing already offer ample zinc. It is a more typical approach when pushing yeast to their limits by increasing the alcohol content above their comfort level.`,
            `A modern copper immersion chiller is thought to provide trace elements of zinc; hence, caution must be exercised when adding extra zinc to avoid overabundance. One of the side effects is "When zinc concentrations above 5 ppm, yeast growth increases acetaldehyde and fusel alcohol synthesis. Excess zinc can also result in soapy or goaty tastes.`,
            `Zinc sulfate powder is an irritant to the eyes. Trace amounts are deemed safe for human consumption, and zinc sulfate is added to animal feed as a source of necessary zinc at rates of up to several hundred milligrammes per kilogramme of feed. Excessive intake causes immediate stomach distress, with nausea and vomiting manifesting at 2–8 mg/Kg body weight.`,
            `Palvi Chemicals is one of the most prominent <b>Zinc Sulfate exporters in Nigeria.</b>`,
            `Sulfuric acid is used to create zinc sulfate from almost any zinc-containing substance (metal, minerals, oxides).`,
            `Specific reactions include the metal's interaction with aqueous sulfuric acid:`,
            `Zn + H<sub>2</sub>SO<sub>4</sub> + 7 H<sub>2</sub>O → ZnSO<sub>4</sub>•7H<sub>2</sub>O + H<sub>2</sub>`,
            `High-purity zinc oxide is treated with sulfuric acid to make pharmaceutical-grade zinc sulfate:`,
            `ZnO + H<sub>2</sub>SO<sub>4</sub> + 6 H<sub>2</sub>O → ZnSO<sub>4</sub>•7H<sub>2</sub>O`,
            `Palvi Chemicals is the leading <b>Zinc Sulfate distributor in Nigeria.</b>`,
            `All types of zinc sulfate act identically in aqueous solution. The metal aquo complex [Zn(H2O)6]2+ and SO42 ions are present in these aqueous solutions. When these solutions are treated with barium ion solutions, barium sulfate forms:`,
            `ZnSO4 + BaCl2 → BaSO4 + ZnCl2`,
            `With a reduction potential of -0.76, zinc(II) can only be reduced with a little bit of an extra effort.`,
            `Zinc sulfate decomposes into sulphur dioxide gas and zinc oxide fume when heated above 680 °C, both of which are toxic.`,
          ],
        },
      },
    ],
  },
  'manganese-sulphate': {
    url: 'manganese-sulphate',
    productRange: [{ name: 'manganese sulphate' }],
    content: [
      { type: 'heading', text: 'manganese sulphate' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `<b>Appearance:</b> Manganese sulphate is an inorganic chemical with the formula MnSO<sub>4</sub>H<sub>2</sub>O. This pale pink deliquescent solid is a manganese salt with commercial significance.`,
            `Manganese is found in a variety of foods, including nuts, legumes, seeds, tea, whole grains, and leafy green vegetables. It is classified as an essential nutrient since the body requires it for proper functioning. Manganese is used in medicine.`,
            `Palvi Chemicals is one of the most popular <b>Manganese Sulfate suppliers in Nigeria.</b>`,
            `Manganese sulphate is an inorganic chemical with the formula MnSO<sub>4</sub>H<sub>2</sub>O. This pale pink deliquescent solid is a manganese salt with commercial significance. In 2005, around 260,000 tonnes of manganese sulfate were produced worldwide. It serves as a precursor to manganese metal and a variety of other chemical compounds. This salt is used to repair manganese-deficient soil.`,
            `X-ray crystallography was used to establish the structure of MnSO<sub>4</sub>H<sub>2</sub>O. Manganese sulphate, like many metal sulphates, generates a number of hydrates, including monohydrate, tetrahydrate, pentahydrate, and heptahydrate. When all of these salts are dissolved in water, they produce slightly pink solutions of the aquo complex [Mn(H<sub>2</sub>O)<sub>6</sub>]<sub>2+</sub>.`,
            `Manganese ores are typically refined by being converted to manganese sulphate. When aqueous sulphate solutions are treated with sodium carbonate, manganese carbonate precipitates, which can then be calcined to generate the oxides MnOx. Manganese sulphate can be synthesised in the laboratory by reacting manganese dioxide with sulphur dioxide.`,
            `MnO<sub>2</sub> + SO<sub>2</sub> + H<sub>2</sub>O → MnSO<sub>4</sub>(H<sub>2</sub>O)`,
            `You can also make it by combining potassium permanganate, sodium bisulfate, and hydrogen peroxide.`,
            `Palvi Chemicals is a dominant <b>Manganese Sulfate distributor in Ghana.</b>`,
            `Manganese sulphate is a byproduct of several industrially relevant oxidations that require manganese dioxide, such as the production of hydroquinone and anisaldehyde.`,
            `Manganese dioxide is produced via electrolysis of manganese sulphate, which is known as EMD for electrolytic manganese dioxide. Alternatively, oxidising manganese sulphate with potassium permanganate produces chemical manganese dioxide (CMD). These substances, particularly EMD, are utilised in dry-cell batteries.`,
            `Palvi Chemicals is one of the leading <b>Manganese Sulfate exporters in Ghana.</b>`,
          ],
        },
      },
    ],
  },
  sorbitol: {
    url: 'sorbitol',
    productRange: [{ name: 'sorbitol' }],
    content: [
      { type: 'heading', text: 'sorbitol' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `<b>Appearance:</b> Sorbitol is a clear, colourless liquid that is dry.`,
            `Palvi Chemicals is one of the most distinguished <b>Sorbitol distributors in Ghana.</b> `,
            `Sorbitol can be made by converting the transformed aldehyde group into a hydroxyl group during a glucose reduction process. Aldose reductase catalyses the process, which requires NADH. The first step in the polyol route of glucose metabolism is glucose reduction, which has been linked to a number of diabetes problems.`,
            `A tyrosine residue in the active site of aldehyde reductase is involved in the mechanism. The hydrogen atom from NADH is transferred to the electrophilic aldehyde carbon atom; electrons from the aldehyde carbon-oxygen double bond are transferred to oxygen, which steals the proton from the tyrosine side chain to produce the hydroxyl group. The tyrosine phenol group of aldehyde reductase acts as an universal acid, providing proton to the reduced aldehyde oxygen on glucose.`,
            `Palvi Chemicals is a prominent <b>Sorbitol exporter in Nigeria.</b>`,
            `Glucose reduction is not the primary glucose metabolic pathway in a normal human body with normal glucose levels. However, in diabetes patients with high blood glucose levels, up to one-third of their glucose may pass through the glucose reduction pathway. This consumes NADH and eventually causes cell damage.`,
            `Palvi Chemicals is one of the popular <b>Sorbitol suppliers in Ghana.</b>`,
            `Aldose reductase is the first enzyme in the sorbitol-aldose reductase pathway, and it is responsible for converting glucose to sorbitol as well as galactose to galactitol. Long-term hyperglycemia with poorly controlled diabetes frequently results in too much sorbitol trapped in retinal cells, lens cells, and Schwann cells that myelinate peripheral nerves. This can harm these cells, resulting in retinopathy, cataracts, and peripheral neuropathy. Aldose reductase inhibitors, which are drugs that impede or decrease the action of aldose reductase, are now being studied as a means of preventing or delaying severe consequences.`,
            `Sorbitol ferments in the colon, producing short-chain fatty acids that are favourable to colon health.`,
          ],
        },
      },
    ],
  },
  formaldehyde: {
    url: 'formaldehyde',
    productRange: [{ name: 'formaldehyde' }],
    content: [
      { type: 'heading', text: 'formaldehyde' },
      { type: 'heading-sub-1', text: 'General description' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `<b>Appearance:</b> Formaldehyde is held as an aqueous solution (formalin), producing pungent smelling colourless gas that spontaneously polymerizes into paraformaldehyde.`,
            `The naturally occurring organic compound formaldehyde (methanal) has the formula CH2O (HCHO). Since the pure compound is a pungent-smelling colorless gas that spontaneously polymerizes into paraformaldehyde, it is held as an aqueous solution (formalin). RCHO is the most basic of the aldehydes. This substance's common name derives from its resemblance to and relationship to formic acid.`,
            `Palvi Chemicals is one of the most distinguished <b>Formaldehyde suppliers in Ghana.</b>`,
            `Formaldehyde is a major threat to human health due to its widespread usage, toxicity, and volatility.`,
            `The catalytic oxidation of methanol produces formaldehyde, which is used in industry. Silver metal or a combination of iron and molybdenum or vanadium oxides are the most popular catalysts. Methanol and oxygen react at 250–400 °C in the presence of iron oxide in combination with molybdenum and/or vanadium to create formaldehyde in the widely used formox process, according to the chemical equation:`,
            `2 CH<sub>3</sub>OH + O<sub>2</sub> → 2 CH<sub>2</sub>O + 2 H<sub>2</sub>O`,
            `Typically, the silver-based catalyst works at a higher temperature, about 650 °C. On it, two chemical reactions produce formaldehyde at the same time: the one shown above and the dehydrogenation reaction:`,
            `CH<sub>3</sub>OH → CH<sub>2</sub>O + H<sub>2</sub>`,
            `While formaldehyde may theoretically be generated by oxidizing methane, this route is not commercially viable because methanol is more easily oxidized than methane.`,
            `Palvi Chemicals is one of the most prominent <b>Formaldehyde exporters in Nigeria.</b>`,
            `Formaldehyde is a key component in the production of a wide range of specialized and industrial compounds. It has a lot of the same chemical properties as other aldehydes, but it's a lot more reactive.`,
          ],
        },
      },
    ],
  },
};

export const internationProducts = {
  'soap-detergent': {
    name: 'SOAP, DETERGENT, LAUNDRY',
    url: 'soap-detergent',
    banner: int_banner_soap,
    productRange: products
      .filter((product) => product.industry === 'Soap, Detergent & Laundry')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  'paper-textile': {
    name: 'PAPER & TEXTILE',
    url: 'paper-textile',
    banner: int_banner_textile,
    productRange: products
      .filter((product) => product.industry === 'Paper and Textile')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  'food-ingredient': {
    name: 'food ingredients',
    url: 'food-ingredient',
    banner: int_banner_food,
    productRange: products
      .filter((product) => product.industry === 'Food Ingredients')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  cosmetic: {
    name: 'COSMETICS & HAIR-SKIN CARE',
    url: 'cosmetic',
    banner: int_banner_cosmetics,
    productRange: products
      .filter(
        (product) => product.industry === 'Cosmetics, Perfume & Hair-Skin Care',
      )
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  'flavour-Fragrance': {
    name: 'FLAVOUR & FRAGRANCE',
    url: 'flavour-Fragrance',
    banner: int_banner_flavour,
    productRange: products
      .filter((product) => product.industry === 'Flavour & Fragrance')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  polyurathene: {
    name: 'POLYURATHENE',
    url: 'polyurathene',
    banner: int_banner_poly,
    productRange: products
      .filter((product) => product.industry === 'Polyurathene')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  'paint-conating': {
    name: 'PAINT & COATING',
    url: 'paint-conating',
    banner: int_banner_paint,
    productRange: products
      .filter((product) => product.industry === 'Paint & Coating')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  plastics: {
    name: 'PLASTICS',
    url: 'plastics',
    banner: int_banner_plastic,
    productRange: products
      .filter((product) => product.industry === 'Plastics')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  'drilling-mining': {
    name: 'DRILLING & MINING',
    url: 'drilling-mining',
    banner: int_banner_mine,
    productRange: products
      .filter((product) => product.industry === 'Drilling & Mining')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  'water-treatment': {
    name: 'WATER TREATMENT',
    url: 'water-treatment',
    banner: int_banner_water,
    productRange: products
      .filter((product) => product.industry === 'Water Treatment')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
  'other-products': {
    name: 'OTHER PRODUCTS',
    url: 'other-products',
    banner: int_banner_other,
    productRange: products
      .filter((product) => product.industry === 'Other')
      .map((product) => ({
        name: product.name,
        content: product.content,
        readMore: product.readMore,
        url: product.url,
      })),
  },
};

export const masterbatchesProducts = {
  'black-masterbatches': {
    name: 'black masterbatches',
    url: 'black-masterbatches',
    banner: master_banner_black,
    image: master_card_black_para,
    showProduct: true,
    showTable: true,
    productRange: [
      {
        name: 'PALVBLK-701',
        base: 'PE',
        'Carbon Black %': '40',
        'Carbon Black Grade': 'N330',
        filler: 'N',
      },
      {
        name: 'PALVBLK-702',
        base: 'PE',
        'Carbon Black %': '50',
        'Carbon Black Grade': 'N660',
        filler: 'N',
      },
      {
        name: 'PALVBLK-703',
        base: 'PE',
        'Carbon Black %': '20',
        'Carbon Black Grade': 'N330',
        filler: 'Y',
      },
      {
        name: 'PALVBLK-704',
        base: 'PE',
        'Carbon Black %': '30',
        'Carbon Black Grade': 'N330',
        filler: 'Y',
      },
      {
        name: 'PALVBLK-705',
        base: 'PE',
        'Carbon Black %': '40',
        'Carbon Black Grade': 'N660',
        filler: 'Y',
      },
      {
        name: 'PALVBLK-709',
        base: 'PE',
        'Carbon Black %': '55',
        'Carbon Black Grade': 'N660',
        filler: 'N',
      },
      {
        name: 'PALVBLK-711',
        base: 'PE',
        'Carbon Black %': '35',
        'Carbon Black Grade': 'N330',
        filler: 'Y',
      },
      {
        name: 'PALVBLK-712',
        base: 'PE',
        'Carbon Black %': '50',
        'Carbon Black Grade': 'N330',
        filler: 'N',
      },
      {
        name: 'PALVBLK-714',
        base: 'PE',
        'Carbon Black %': '40',
        'Carbon Black Grade': 'PP1201',
        filler: 'N',
      },
      {
        name: 'PALVBLK-716',
        base: 'PE',
        'Carbon Black %': '40',
        'Carbon Black Grade': 'N330',
        filler: 'Y',
      },
      {
        name: 'PALVBLK-717',
        base: 'PE',
        'Carbon Black %': '17',
        'Carbon Black Grade': 'N330',
        filler: 'Y',
      },
      {
        name: 'PALVBLK-722',
        base: 'PE',
        'Carbon Black %': '50',
        'Carbon Black Grade': 'N660',
        filler: 'Y',
      },
      {
        name: 'PALVBLK-723',
        base: 'PE',
        'Carbon Black %': '50',
        'Carbon Black Grade': 'P353',
        filler: 'N',
      },
      {
        name: 'PALVBLK-728',
        base: 'PE',
        'Carbon Black %': '45',
        'Carbon Black Grade': 'P8242',
        filler: 'N',
      },
      {
        name: 'PALVBLK-730',
        base: 'PE',
        'Carbon Black %': '35',
        'Carbon Black Grade': 'P8242',
        filler: 'Y',
      },
      {
        name: 'PALVBLK-731',
        base: 'PE',
        'Carbon Black %': '40',
        'Carbon Black Grade': 'P824',
        filler: 'N',
      },
      {
        name: 'PALVBLK-732',
        base: 'PE',
        'Carbon Black %': '40',
        'Carbon Black Grade': 'P8242',
        filler: 'N',
      },
      {
        name: 'PALVBLK-735',
        base: 'PE',
        'Carbon Black %': '30',
        'Carbon Black Grade': 'N330',
        filler: 'N',
      },
      {
        name: 'PALVBLK-743',
        base: 'PE',
        'Carbon Black %': '45',
        'Carbon Black Grade': 'P8242',
        filler: 'N',
      },
      {
        name: 'PALVBLK-758',
        base: 'PE',
        'Carbon Black %': '32',
        'Carbon Black Grade': 'PP1201',
        filler: 'N',
      },
      {
        name: 'PALVBLK-801',
        base: 'PP',
        'Carbon Black %': '30',
        'Carbon Black Grade': 'Special Carbon',
        filler: 'N',
      },
      {
        name: 'PALVBLK-803',
        base: 'PP',
        'Carbon Black %': '38',
        'Carbon Black Grade': 'Special Carbon',
        filler: 'N',
      },
      {
        name: 'PALVBLK-960',
        base: 'PET',
        'Carbon Black %': '28',
        'Carbon Black Grade': 'Special Carbon',
        filler: 'N',
      },
      {
        name: 'PALVBLK-901',
        base: 'NYLON',
        'Carbon Black %': '20',
        'Carbon Black Grade': 'Special Carbon',
        filler: 'N',
      },
      {
        name: 'PALVBLK-902',
        base: 'NYLON',
        'Carbon Black %': '25',
        'Carbon Black Grade': 'Special Carbon',
        filler: 'N',
      },
      {
        name: 'PALVBLK-903',
        base: 'NYLON',
        'Carbon Black %': '30',
        'Carbon Black Grade': 'Special Carbon',
        filler: 'N',
      },
    ],
    information: [
      'Palvi’s Black Masterbatches assures maximum jetness with highest dispersion. The Black Masterbatches are designed for high quality filter index giving the product an extended shelf life.',
      'Palvi’s is India’s leading manufacturers of Black Masterbatches. We have always strived to provide customers with quality products keeping in mind their needs and budget. We are well known to both masterbatch producers within and outside India and have job work contracts with them.',
      'The various ranges of Black Masterbatches have different applications like blown films, injection and rota moulding, pipes, cables and wires, etc. We have also differentiated color ranging from 25% to 50% black concentration.',
      'The products have been customized based on the range of polymer materials and for addressing the special requirements of the customers like color shade, heat resistance,light resistance, dispersion, UV protection, jetness, and high loading  carbon content.',
    ],
    advantages: [
      'Excellent weatherabilty',
      'High Jetness',
      'Uniform Particle Size',
      'Excellent dispersion',
      'Smooth surface',
    ],
    application: [
      'Mulch Film',
      'Drip Irrigation / HDPE Pipe / Mini Sprinker System',
      'Woven Sacks',
      'Geomembrane',
      'Moulding-Blow & Injection',
      'Garbage Bags',
      'Mono & Multi filament Yarn',
      'Geo-Textile',
      'Tarpaulin',
      'Various Other Plastic Processing Applications',
    ],
  },
  'white-masterbatches': {
    name: 'white masterbatches',
    url: 'white-masterbatches',
    banner: master_banner_white,
    image: master_card_white_para,
    showProduct: false,
    showTable: false,
    productRange: [{ name: 'White Masterbatch' }],
    information: [
      'Palvi’s specialty lies in manufacturing high quality Tio2-based White Masterbatches. We are trusted by various reputed clients that develop film and packaging products.',
      'We offer both multi-layered and mono-layered films with high weatherability qualities across various grades. Each grade is procured from various loadings of titanium dioxide. Each batch produced at our state of the art infrastructural facility are quality tested.',
      'Our Specialty Masterbatches are available in hues of pure white as well as white with blue-ish undertones. Our products are both customizable and price competitive according to industry standards. We also promise our clients with best competitive pricing across the market.',
    ],
    advantages: [
      'Excellent weatherabilty',
      'High Jetness',
      'Uniform Particle Size',
      'Excellent dispersion',
      'Smooth surface',
    ],
    application: [
      'Mulch Film',
      'Drip Irrigation / HDPE Pipe / Mini Sprinker System',
      'Woven Sacks',
      'Geomembrane',
      'Moulding-Blow & Injection',
      'Garbage Bags',
      'Mono & Multi filament Yarn',
      'Geo-Textile',
      'Tarpaulin',
      'Various Other Plastic Processing Applications',
    ],
  },
  'filler-masterbatches': {
    name: 'filler masterbatches',
    url: 'filler-masterbatches',
    banner: master_banner_filler,
    image: master_card_filler_para,
    showProduct: true,
    showTable: true,
    productRange: [
      { name: 'PALVFIL-101', base: 'PE', 'loading (%)': '20%' },
      { name: 'PALVFIL-102', base: 'PE', 'loading (%)': '20%' },
      { name: 'PALVFIL-201', base: 'PP', 'loading (%)': '20%' },
      { name: 'PALVFIL-202', base: 'PP', 'loading (%)': '20%' },
      { name: 'PALVTRANS-151', base: 'PE', 'loading (%)': '15%' },
      { name: 'PALVTRANS-152', base: 'PE', 'loading (%)': '20%' },
    ],
    information: [
      'Our specialty lies in manufacturing high quality Tio2-based White Masterbatches. We are trusted by various clients that develop film and packaging products.',
      'We offer both multi-layered and mono-layered films. They feature high weatherability qualities across various grades. Each grade is procured from various loadings of titanium dioxide. They are quality tested PE, PP, and PS base carriers.',
      'Our Specialty Masterbatches are available in hues of pure white as well as white with blue-ish undertones.',
      'Our products are both customizable and of the highest quality. We also promise our clients with best competitive pricing across the market.',
    ],
    advantages: [
      'Very low dust formation',
      'Higher Loadability upto 50%',
      'Customized grades with special colors as well',
      'Special grades with ultra fine Calcium carbonate for very thin film extrusion',
      'Additive packages for increasing strength even with high loading of filler masterbatch in film and Raffia',
    ],
    application: [
      'Woven Sacks / Raffia',
      'Lamination',
      'Blown Film / Cast Film',
      'Blow molding',
      'Injection molding',
      'FIBC Bags',
    ],
  },
  // 'additive-masterbatches': {
  //   name: 'additive mastermatches',
  //   url: 'additive-masterbatches',
  //   banner: master_banner_additive,
  //   image: master_card_additive_para,
  //   showProduct: true,
  //   showTable: false,
  //   productRange: [
  //     { name: 'UV Masterbatch (10% Active Content)' },
  //     { name: 'UV Masterbatch (20% Active Content)' },
  //     { name: 'PE Modifier' },
  //     { name: 'PP Modifier' },
  //     { name: 'Anti Oxident' },
  //   ],
  //   information: [
  //     'Our specialty lies in manufacturing high quality Tio2-based White Masterbatches. We are trusted by various clients that develop film and packaging products.',
  //     'We offer both multi-layered and mono-layered films. They feature high weatherability qualities across various grades. Each grade is procured from various loadings of titanium dioxide. They are quality tested PE, PP, and PS base carriers.',
  //     'Our Specialty Masterbatches are available in hues of pure white as well as white with blue-ish undertones. Our products are both customizable and of the highest quality. We also promise our clients with best competitive pricing across the market.',
  //   ],
  //   advantages: [
  //     'Very low dust formation',
  //     'Higher Loadability upto 50%',
  //     'Customized grades with special colors as well',
  //     'Special grades with ultra fine Calcium carbonate for very thin film extrusion',
  //     'Additive packages for increasing strength even with high loading of filler masterbatch in film and Raffia',
  //   ],
  //   application: [
  //     'FIBC Bags',
  //     'Blown Film / Mulch Film',
  //     'Blow / Injection Moulding',
  //     'Geomembrane',
  //     'Woven Sacks / Raffia',
  //     'Agricultural Applications',
  //   ],
  // },
};

export const tollProducts = {
  'sodium-nitrate': {
    url: 'sodium-nitrate',
    banner: master_banner_black,
    productRange: [{ name: 'Sodium Nitrate' }],
    content: [
      { type: 'heading', text: 'sodium nitrate' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            'Sodium Nitrate is a chemical compound which is hygroscopic in nature. The product is alkali metal nitrate salt and widely known as Chile saltpeter. Sodium nitrate is freely soluble in water & liquid ammonia, slightly soluble in ethanol. The product is comes under Hazard class 5.1 (Oxidizing substance/strong oxidizer) and hazardous material category 1 (subjected to the Fire Service Act).',
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Industry Centric',
          content: {
            'only-text': 'Glass',
            'only-text-1': 'Metal',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'specification',
          content: {
            Appearance: 'Fine white crystals',
            Assay: '99% Minimum',
            'Iron as Fe': '0.05% Maximum',
            'Sodium Carbonate': '0.001% Maximum',
            'Sulphate as S': '0.02% Maximum',
            Chloride: '0.02% Maximum',
            Moisture: '1% Maximum',
            pH: '6.5 to 8',
            'Molecular Weight': '85 g/mol',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Packaging details',
          content: {
            'only-text': '25 Kg HDPE Drums',
            'only-text-1': '100 Kg Jumbo Bag',
          },
        },
      },
    ],
  },
  'optical-brightening-agent': {
    url: 'optical-brightening-agent',
    banner: master_banner_black,
    productRange: [{ name: 'Optical Brightening Agent' }],
    content: [
      { type: 'heading', text: 'Optical Brightening Agent' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Optical brightening agents are commercially known as fluorescent brightening agents. These are the chemicals that are added to everything from linen slacks and silk blouses, paper, detergents, etc. The optical brighteners counteract the yellowness of the fabric or material by increasing the reflection of blue light rays.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'Industry Centric',
          content: {
            'only-text': 'Detergent Grade',
            'only-text-1': 'Paper',
            'only-text-2': 'Textile',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'chemical composition',
          content: {
            'only-text': 'Di-Sulpho Strilene',
            'only-text-1': 'Tetra-Sulpho Strilene',
            'only-text-2': 'Hexa-Sulpho Strilene',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Application',
          content: {
            'only-text':
              'The product is widely used as a release agent for the production of many kinds of objects: rubber, polyurethane, polyester processing system, powder metallurgy.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Packaging details',
          content: {
            'only-text': '25 Kg HDPE Bag',
            'only-text-1': `IBC's`,
          },
        },
      },
    ],
  },
  'nitric-acid': {
    url: 'nitric-acid',
    banner: master_banner_black,
    productRange: [{ name: 'Nitric Acid' }],
    content: [
      { type: 'heading', text: 'nitric acid 68%' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Nitric acid belongs to the inorganic acids group & extremely toxic and corrosive in nature. The product is a nitrogen oxoacid with molecular formula HNO3. Nitric acid is produced by the reaction of nitrogen oxide (NO2) with water. Industrially, a strong nitric acid is produced by dissolving additional nitrogen dioxide in around 68% nitric acid in an absorption tower.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'commercial synonyms',
          content: {
            'only-text': 'Aqua fortis',
            'only-text-1': 'Hydrogen nitrate',
            'only-text-2': 'Azotic Acid',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0':
              'The product is used as a key material for the production of fertilizers.',
            'only-text-1':
              'It is highly recommended for the production of ammonium nitrate.',
            'only-text-2':
              'Nitric acid is used to make intermediates in the polymer industry, notably in the manufacture of hexanedioic acid (adipic acid) to make polyamides and TDI (toluene diisocyanate or methylbenzene diisocyanate) and dinitrobenzene',
            'only-text-3':
              'It is also used for making explosives such as nitroglycerin and TNT.',
            'only-text-4':
              'Nitric acid has been used in various forms as the oxidizer in liquid–fueled rockets. ',
            'only-text-5':
              'The product is used as a chemical doping agent for organic semiconductors, and in purification processes for raw carbon nanotubes.',
            'only-text-6':
              'In a low concentration, the product is often used in woodworking to artificially age pine and maple.',
            'only-text-7':
              'Used in manufacturing of dyes and dye intermediates',
            'only-text-8': 'Used in drugs and pharmaceuticals',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Packaging details',
          content: {
            'only-text': '300 Kg HDPE Drums',
            'only-text-1': '35/40 Kg HDPE Jerry Cans',
            'only-text-2': `1400 Kg Composite IBC`,
          },
        },
      },
    ],
  },
  'sulphuric-acid': {
    url: 'sulphuric-acid',
    banner: master_banner_black,
    productRange: [{ name: 'Sulphuric Acid' }],
    content: [
      { type: 'heading', text: 'sulphuric acid' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Sulphuric acid is widely referred as “Chemicals King”, it is a colorless oily liquid and soluble in water with release of heat. The product is prepared industrially by the reaction of water with sulfur trioxide. In various concentration, the acid is used in the manufacture of fertilizers, pigments, dyes, drugs, explosives, detergents, inorganic salts & acids, petroleum refining & metallurgical processes. The most common application, sulfuric acid serves as the electrolyte in lead acid storage batteries.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'commercial synonyms',
          content: {
            'only-text': 'Hydrogen sulfate',
            'only-text-1': 'Oil of vitriol',
            'only-text-2': 'H2SO4',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0':
              'The product is significantly used in the production of nitrogenous fertilizers such as ammonium sulfate.',
            'only-text-1':
              'Also employed in making hydrogen peroxide by reacting barium peroxide with sulfuric acid.',
            'only-text-2':
              'In metal processing sector, sulfuric acid is used for pickling & descaling steel, for leaching copper, uranium, and vanadium ores in hydrometallurgical ore processing. Also in the preparation of electrolytic baths for nonferrous-metal purification and plating.',
            'only-text-3': 'Employed for extraction of metals',
            'only-text-4':
              'Sulfuric acid is used notably in the production of TiO2 pigments, hydrochloric acid, and hydrofluoric acid in inorganic chemical industry.',
            'only-text-5':
              'Sulfuric acid is also used in various detergents & soaps, drain cleaner, paints & pigments.',
            'only-text-6':
              'Certain wood pulping processes in the paper industry require sulfuric acid, as do some textile and chemical fiber processes and leather tanning.',
            'only-text-7':
              'The key use of sulfuric acid in the manufacturing of sulfates.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Specification',
          content: {
            Appearance: 'Clear colorless liquid',
            'Sp Gravity at 30°C': '1.84',
            'Molecular weight': `98.08`,
            'Boiling point': '340 Celsius',
            'Purity (as H2SO4)': '98.0% min',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Packaging details',
          content: {
            'only-text': '325 Kg HDPE Drums',
            'only-text-1': '35/50/57 Kg HDPE Jerry Cans',
            'only-text-2': `1450 Kg Composite IBC`,
          },
        },
      },
    ],
  },
};

// ACID Content

export const acidProducts = {
  'acetic-acid': {
    url: 'acetic-acid',
    banner: master_banner_black,
    productRange: [{ name: 'Acetic Acid' }],
    content: [
      { type: 'heading', text: 'acetic acid' },
      {
        type: 'paraWithPhoto',
        data: {
          // image: master_card_black_para,
          information: [
            `Acetic Acid is a simplest carboxylic acid with antifungal &amp; antibacterial properties. Since it
has a carbon atom in its chemical formula, it is an organic compound and comes with a
chemical formula CH 3 COOH. Acetic acid, as a weak acid, can inhibit carbohydrate
metabolism resulting in subsequent death of the organism. 
`,
            `Interestingly, the word ‘acetic’ is
derived from a Latin word called ‘acetum’ which means ‘vinegar’. Vinegar is a dilute form of
acetic acid and is the most common chemical substance among people. Acetic acid is a main
component of vinegar and also gives vinegar its characteristic smell. When acetic acid is
undiluted, then it is termed as glacial acetic acid.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'commercial synonyms',
          content: {
            'only-text': 'Acetic acid glacial',
            'only-text-1': 'Ethanoic acid',
            'only-text-2': 'Ethylic acid',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Application Centric',
          content: {
            'only-text':
              'It is used as a key chemical reagent & most of the industrial chemical.',
            'only-text-1':
              'In households diluted acetic acid is often used as a cleaning agent.',
            'only-text-2':
              'In the food industry acetic acid is used as an acidity regulator.',
            'only-text-3':
              'The acetyl group, derived from acetic acid, is fundamental to the biochemistry of virtually all forms of life.',
            'only-text-4':
              'Acetic Acid is employed for the production of vinyl acetate monomer (VAM) derivatives of which are further used in textiles, paints & coating.',
            'only-text-5':
              'The product is also used to produce acetic anhydride, esters, and purification of organic compounds.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Industry Centric',
          content: {
            'only-text': 'Industrial use',
            'only-text-1': 'Household use',
            'only-text-2': 'Food industry',
            'only-text-3': 'Medicinal use',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'specification',
          content: {
            Appearance: 'Clear, colorless liquid',
            'Freezing point(Min)': '16.1°C',
            'Acetic acid(Min)': '99.70 %',
            'Formic acid(Max)': '0.15%',
            'Chlorides(Max)': '1.0 PPM',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Packaging details',
          content: {
            'only-text': '235 Kg HDPE Drums',
            'only-text-1': '35 Kg HDPE Jerry Cans',
          },
        },
      },
    ],
  },
  'hydrochloric-acid': {
    url: 'hydrochloric-acid',
    banner: master_banner_black,
    productRange: [{ name: 'Hydrochloric Acid' }],
    content: [
      { type: 'heading', text: 'hydrochloric acid' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Hydrochloric acid is designated as a deleterious and strongly corrosive substance.  The product is a solution of HCL (hydrochloric acid) in water and commercially available in various grades. Hydrochloric acid is synthetically produced chemical used in variety of industrial and commercial applications. The product is also listed as Title III Hazardous Air Pollutant.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'commercial synonyms',
          content: {
            'only-text': 'Muriatic acid',
            'only-text-1': 'Hydrogen chloride',
            'only-text-2': 'Chlorohydric acid',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text':
              'HCL is used by masons to clean finished brick work, is also a common ingredient in many reactions, and is the preferred acid for catalyzing organic processes.',
            'Steel pickling':
              'The product is used as a raw material in pickling operations for alloy, carbon and stainless steel. Steel pickling is the process by which iron oxides and scale are removed from the surface of steel by converting oxides to soluble compounds. Pickling is required for steel products that undergo further processing such as wire production, coating of sheet & strip, and tin mill products. The product is also used in aluminum etching, metal prefixing for galvanizing & soldering and metal cleaning.',
            'Oil well acidizing':
              'Acidizing is generally done in carbonate or lime stone formations by stimulation. An HCL acid solution is injected into the formation, which dissolves a portion of the rock and creates a large pore structure in the formation, increasing its effective permeability and the flow of oil.',
            'Production of calcium chloride':
              'Neutralization of hydrochloric acid with limestone produces calcium chloride. The key usage of calcium chloride is in highway deicing products and the proportion of product varies based weather condition. The other applications of calcium chloride includes dust control, oil recovery (oil recovery products like drilling muds, etc.), tire ballasting, industrial processing and concrete treatment.',
            'Ore mining/processing':
              'The product is significantly consumed by mining industry. It is employed in various mining operations for ore treatment, extraction, separation, purification and water treatment.',
            'only-text-5':
              'Aqueous HCL is used in a variety of applications which include recovery of semi-precious metals, synthesis, use in catalyst generation, neutralization of alkaline products or waste materials, pH control, ion exchange resins regeneration which are further used in wastewater treatment & electric utilities, production of polycarbonates resins, polyvinyl chloride resins, ethanol, use as a bleaching and dyeing assistant in the textile industry and to name a few.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Characteristics/properties of Hydrochloric Acid (HCL)',
          content: {
            'only-text':
              'HCL is a non-flammable, transparent and colorless or light yellow liquid. When it has a concentration of 25% or more, it is a fuming strong acid.',
            'only-text-1':
              'The product reacts with a chromate, permanganate, or persulfate to generate chlorine, and reacts with a metal peroxide to form its chloride and chlorine.',
            'only-text-2':
              'When hydrochloric acid is heated, it generates a large quantity of hydrochloric acid fumes.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Specification',
          content: {
            'Physical appearance': 'White to slight yellow liquid',
            'Specific gravity': '1.16 gm/cc at 25 degree',
            'Hydrochloric Acid (as HCL)': '30.00-33.00%',
            'Iron (as Fe++)': '2.00 PPM Max',
            'Heavy Metals as Pb': '2.00 PPM Max',
            Arsenic: 'Nil',
            Mercury: 'Nil',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Packaging details',
          content: {
            'only-text': '265/ 275/280 Kg HDPE Drums',
            'only-text-1': '35/40 Kg HDPE Jerry Cans',
            'only-text-2': `1150 Kg Composite/Wooden base IBC's`,
          },
        },
      },
    ],
  },
  'nitric-acid': {
    url: 'nitric-acid',
    banner: master_banner_black,
    productRange: [{ name: 'Nitric Acid' }],
    content: [
      { type: 'heading', text: 'nitric acid 68%' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Nitric acid belongs to the inorganic acids group & extremely toxic and corrosive in nature. The product is a nitrogen oxoacid with molecular formula HNO3. Nitric acid is produced by the reaction of nitrogen oxide (NO2) with water. Industrially, a strong nitric acid is produced by dissolving additional nitrogen dioxide in around 68% nitric acid in an absorption tower.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'commercial synonyms',
          content: {
            'only-text': 'Aqua fortis',
            'only-text-1': 'Hydrogen nitrate',
            'only-text-2': 'Azotic Acid',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0':
              'The product is used as a key material for the production of fertilizers.',
            'only-text-1':
              'It is highly recommended for the production of ammonium nitrate.',
            'only-text-2':
              'Nitric acid is used to make intermediates in the polymer industry, notably in the manufacture of hexanedioic acid (adipic acid) to make polyamides and TDI (toluene diisocyanate or methylbenzene diisocyanate) and dinitrobenzene',
            'only-text-3':
              'It is also used for making explosives such as nitroglycerin and TNT.',
            'only-text-4':
              'Nitric acid has been used in various forms as the oxidizer in liquid–fueled rockets. ',
            'only-text-5':
              'The product is used as a chemical doping agent for organic semiconductors, and in purification processes for raw carbon nanotubes.',
            'only-text-6':
              'In a low concentration, the product is often used in woodworking to artificially age pine and maple.',
            'only-text-7':
              'Used in manufacturing of dyes and dye intermediates',
            'only-text-8': 'Used in drugs and pharmaceuticals',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Packaging details',
          content: {
            'only-text': '300 Kg HDPE Drums',
            'only-text-1': '35/40 Kg HDPE Jerry Cans',
            'only-text-2': `1400 Kg Composite IBC`,
          },
        },
      },
    ],
  },
  'sulphuric-acid': {
    url: 'sulphuric-acid',
    banner: master_banner_black,
    productRange: [{ name: 'Sulphuric Acid' }],
    content: [
      { type: 'heading', text: 'sulphuric acid' },
      {
        type: 'paraWithPhoto',
        data: {
          information: [
            `Sulphuric acid is widely referred as “Chemicals King”, it is a colorless oily liquid and soluble in water with release of heat. The product is prepared industrially by the reaction of water with sulfur trioxide. In various concentration, the acid is used in the manufacture of fertilizers, pigments, dyes, drugs, explosives, detergents, inorganic salts & acids, petroleum refining & metallurgical processes. The most common application, sulfuric acid serves as the electrolyte in lead acid storage batteries.`,
          ],
        },
      },
      {
        type: 'list',
        data: {
          title: 'commercial synonyms',
          content: {
            'only-text': 'Hydrogen sulfate',
            'only-text-1': 'Oil of vitriol',
            'only-text-2': 'H2SO4',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Applications',
          content: {
            'only-text-0':
              'The product is significantly used in the production of nitrogenous fertilizers such as ammonium sulfate.',
            'only-text-1':
              'Also employed in making hydrogen peroxide by reacting barium peroxide with sulfuric acid.',
            'only-text-2':
              'In metal processing sector, sulfuric acid is used for pickling & descaling steel, for leaching copper, uranium, and vanadium ores in hydrometallurgical ore processing. Also in the preparation of electrolytic baths for nonferrous-metal purification and plating.',
            'only-text-3': 'Employed for extraction of metals',
            'only-text-4':
              'Sulfuric acid is used notably in the production of TiO2 pigments, hydrochloric acid, and hydrofluoric acid in inorganic chemical industry.',
            'only-text-5':
              'Sulfuric acid is also used in various detergents & soaps, drain cleaner, paints & pigments.',
            'only-text-6':
              'Certain wood pulping processes in the paper industry require sulfuric acid, as do some textile and chemical fiber processes and leather tanning.',
            'only-text-7':
              'The key use of sulfuric acid in the manufacturing of sulfates.',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Specification',
          content: {
            Appearance: 'Clear colorless liquid',
            'Sp Gravity at 30°C': '1.84',
            'Molecular weight': `98.08`,
            'Boiling point': '340 Celsius',
            'Purity (as H2SO4)': '98.0% min',
          },
        },
      },
      {
        type: 'list',
        data: {
          title: 'Packaging details',
          content: {
            'only-text': '325 Kg HDPE Drums',
            'only-text-1': '35/50/57 Kg HDPE Jerry Cans',
            'only-text-2': `1450 Kg Composite IBC`,
          },
        },
      },
    ],
  },
};

export const molybdenumProducts = {
  'sodium-molybdate': {
    name: 'sodium molybdate',
    url: 'sodium-molybdate',
    banner: moly_water,
    image: sample,
    showProduct: false,
    productRange: [{ name: 'Technical' }, { name: 'LR' }, { name: 'AR/ACS' }],
    information: [
      'Sodium Molybdate is a white crystalline solid mainly used for manufacturing alkaloids, ink, fertilizers, molybdenum red pigment and light fastness pigment precipitation agent, catalyst and molybdenum salt, and it can also be used to manufacture fire retardant and pollution-free cold water system metal inhibitor.',
    ],
    details: {
      code: '28417020',
      cas: '10102-40-6',
      assay: '98.5 to 100 %',
      formula: 'Na<sub>2</sub>MoO<sub>4</sub>',
      mo: '39 to 39.7 %',
      packing: '25 kg BOPP bags with liner inside / 1,000 kg bulk bags',
    },
    application: [
      'Manufacturing of Pigments',
      'Micro Nutrients',
      'Corrosion Inhibitors',
      'Fertilizer Micronutrients',
      'Manufacturing of Catalyst',
      'Animal Feed Applications',
      'Water Treatment Chemicals',
      'Coolants & Grease for automobiles',
      'Catalyst in Manufacturing of Inks',
    ],
    tds: '/downloads/Sodium Molybdate.pdf',
    msds: '/downloads/MSDS Sodium Molybdate.pdf',
  },
  'ammonium-di-molybdate': {
    name: 'ammonium di molybdate',
    url: 'ammonium-di-molybdate',
    banner: moly_port,
    image: sample,
    showProduct: false,
    productRange: [{ name: 'Technical' }, { name: 'LR' }, { name: 'AR/ACS' }],
    information: [
      'Ammonium Di Molybdate is a white powder used in production of molybdenum powder, corrosion inhibitors and specialty fertilizers and micronutrients for plant and animal feed. It is also used in manufacturing catalyst for hydrogenation and desulfurization in petroleum refining. Some other uses include paints, pigment and dyes manufacturing, metal surface treatment and ceramic applications.',
    ],
    details: {
      code: '28417090',
      cas: '27546-07-2',
      assay: '99 to 100 %',
      formula: '(NH<sub>4</sub>)<sub>2</sub>Mo<sub>2</sub>O<sub>7</sub>',
      mo: '56 to 56.5 %',
      packing: '25 kg BOPP bags with liner inside / 1,000 kg bulk bags',
    },
    application: [
      'Catalyst Manufacture',
      'Fertilizer Micronutrients',
      'Animal Feed Applications',
      'Corrosion Inhibitors',
      'Metal Surface Treatment',
      'High Purity Metals and Alloys (Sinter)',
      'Pigment Manufacture',
    ],
    tds: '/downloads/Ammonium Di Molybdate.pdf',
    msds: '/downloads/MSDS Ammonium Molybdate.pdf',
  },
  'ammonium-hepta-molybdate': {
    name: 'ammonium hepta molybdate',
    url: 'ammonium-hepta-molybdate',
    banner: moly_fertilizer,
    image: sample,
    showProduct: false,
    productRange: [{ name: 'Technical' }, { name: 'LR' }, { name: 'AR/ACS' }],
    information: [
      'Ammonium Hepta Molybdate is a colorless or light yellow green monoclinic crystal. It is  used in manufacturing pigments and dehydrogenation catalyst. In the oil and coking industry, it can be used for desulfurization and molybdenum powder manufacturing. It is also used in manufacturing specialty fertilizers, micronutrients for plant and animal feed and as a catalyst in producing Omeprazole drug.',
    ],
    details: {
      code: '28417090',
      cas: '27546-07-2',
      assay: '99 to 100 %',
      formula:
        '(NH<sub>4</sub>)<sub>6</sub>Mo<sub>7</sub>O<sub>24</sub>.4H<sub>2</sub>O',
      mo: '56 to 56.5 %',
      packing: '25 kg BOPP bags with liner inside / 1,000 kg bulk bags',
    },
    application: [
      'Catalyst Manufacture',
      'Fertilizer Micronutrients',
      'Animal Feed Applications',
      'Corrosion Inhibitors',
      'Metal Surface Treatment',
      'High Purity Metals and Alloys (Sinter)',
      'Pigment Manufacture',
      'Color lake',
      'Fabric fire retardant agent',
      'Photography, ceramics & paint',
      'Catalyst in petrochemical industry',
    ],
    tds: '/downloads/Ammonium Di Molybdate.pdf',
    msds: '/downloads/MSDS Ammonium Molybdate.pdf',
  },
  'ammonium-tetra-molybdate': {
    name: 'ammonium tetra molybdate',
    url: 'ammonium-tetra-molybdate',
    banner: moly_mine,
    image: sample,
    showProduct: false,
    productRange: [{ name: 'Technical' }, { name: 'LR' }, { name: 'AR/ACS' }],
    information: [
      'Ammonium Tetra Molybdate is a white or slightly yellow crystalline powder, has wide applications and is mainly used in dyes and pigments. It usually as raw material for molybdenum powder, trace element fertilizer, ceramic pigments and other molybdenum compounds production. In addition, it can be used as hydrogenation, desulfurization oil refining catalyst, chemical fertilizer catalyst and artificial wool catalyst. Besides, it is a good catalyst agent and fire retardant fabrics. It is also often used as photography, powder metallurgy molybdenum, ceramic glaze, paint, and medicine’s industrial raw materials.',
    ],
    details: {
      code: '28417090',
      cas: '1313-27-5',
      assay: '98 to 100 %',
      formula:
        '(NH<sub>4</sub>)<sub>2</sub>Mo<sub>4</sub>O<sub>13</sub>.2H<sub>2</sub>O',
      mo: '56.5 to 57.5 %',
      packing: '25 kg BOPP bags with liner inside / 1,000 kg bulk bags',
    },
    application: [
      'Catalyst Manufacture',
      'Fertilizer Micronutrients',
      'Animal Feed Applications',
      'Corrosion Inhibitors',
      'Metal Surface Treatment',
      'High Purity Metals and Alloys (Sinter)',
      'Pigment Manufacture',
      'Laboratory reagent',
    ],
    tds: '/downloads/Ammonium Tetra Molybdate.pdf',
    msds: '/downloads/MSDS Ammonium Molybdate.pdf',
  },
  'pure-molybdenum-trioxide': {
    name: 'pure molybdenum trioxide',
    url: 'pure-molybdenum-trioxide',
    banner: moly_eng,
    image: sample,
    showProduct: false,
    productRange: [{ name: 'Technical' }, { name: 'LR' }, { name: 'AR/ACS' }],
    information: [
      'Pure Molybdenum Trioxide is a yellowish/greenish amorphous powder. Major use of Molybdenum Trioxide is as an additive to steel and other corrosion-resistant alloys. It is also used in the production of molybdenum products, as an industrial catalyst, a pigment, a crop nutrient, a component of glass, ceramics and enamels, a flame retardant for polyester and polyvinyl chloride resins, and as a chemical reagent.',
    ],
    details: {
      code: '28257020',
      cas: '1313-27-5',
      assay: '99.5 % min',
      formula: 'MoO<sub>3</sub>',
      mo: '66.6 % min',
      packing: '25 kg BOPP bags with liner inside / 1,000 kg bulk bags',
    },
    application: [
      'Catalyst Manufacture',
      'Corrosion Inhibitors',
      'Smoke Suppressants in plastics',
      'High Purity Metals and Alloys (Sinter)',
      'Activator in glass industry',
      'Flocculent in Titanium Dioxide production',
      'Paints and adhesives industries',
      'Pigments',
      'Ceramic frits',
      'Fire Retardant for wide range of plastics',
    ],
    tds: '/downloads/Molybdenum Trioxide.pdf',
    msds: '/downloads/MSDS Molybdenum Trioxide.pdf',
  },
  'roasted-molybdenum-concentrate': {
    name: 'roasted molybdenum concentrate',
    url: 'roasted-molybdenum-concentrate',
    banner: moly_bag,
    image: sample,
    showProduct: false,
    productRange: [{ name: 'Technical' }, { name: 'LR' }, { name: 'AR/ACS' }],
    information: [
      'Roasted Molybdenum Concentrate (Technical Molybdenum Oxide) is obtained by roasting molybdenum ore obtained from copper mines. It generally contains 57% - 60% Mo. RMC is a very popular product which is commonly traded in the metal market. The major use of RMC is to produce contained steels such as structural steel, stainless steel and tool steel. Roasted concentrate can also be processed into pure oxide (MoO3 / PMO) which can be used for production of pure Mo metal or Molybdenum-Chrome alloy. Moreover, pure metal or molybdenum-chrome alloy are also used in super-alloy applications, primarily in oil and gas, automotive and aerospace.',
    ],
    details: {
      code: '26131000',
      cas: '1313-27-5',
      assay: '99 to 100 %',
      formula: '',
      mo: '66.6 % min',
      packing: '1000 kg jumbo bags',
    },
    application: [],
    tds: '/downloads/Roasted Molybdenum Concentrate.pdf',
    msds: '',
  },
};

export default allProducts;
