import React from 'react';

import { NavLink } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

import '../style/navBar.css';
import logo from '../assests/logo.jpeg';
// import flag from '../assests/india.svg';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollUpHeader: false,
      showChildMenu: false,
      showMasterMenu: false,
      showMolyMenu: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleSubHover = this.handleSubHover.bind(this);
    this.handleSubLeave = this.handleSubLeave.bind(this);
    this.handleSubHoverMoly = this.handleSubHoverMoly.bind(this);
    this.handleSubLeaveMoly = this.handleSubLeaveMoly.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleHover = (event) => {
    this.setState({ showChildMenu: true });
  };

  handleLeave = (event) => {
    this.setState({ showChildMenu: false });
  };

  handleSubHover = (event) => {
    this.setState({ showMasterMenu: true });
  };

  handleSubLeave = (event) => {
    this.setState({ showMasterMenu: false });
  };

  handleSubHoverMoly = (event) => {
    this.setState({ showMolyMenu: true });
  };

  handleSubLeaveMoly = (event) => {
    this.setState({ showMolyMenu: false });
  };

  handleScroll(event) {
    if (
      document.body.scrollTop > 60 ||
      document.documentElement.scrollTop > 60
    ) {
      document.getElementById('navbar').className = 'navBar-fix';
      this.setState({ scrollUpHeader: false });
    } else {
      document.getElementById('navbar').className = 'navBar-fix'; //navBar-fix
      this.setState({ scrollUpHeader: true });
    }
  }

  render() {
    return (
      <div id="navbar">
        {/* <div className="header-top-block">
          <div className="header-phonemenu">
            <Container>
              <div className="header-contact">
                <div className="header-phone">
                  <p>FOR ANY QUERIES CALL US</p>
                </div>
                <div className="header-address">
                  <img
                    className="flag-icon"
                    alt="img"
                    src={flag}
                    height="30"
                    width="30"
                  />
                  <p>+91-265-2769856</p>
                </div>
              </div>
            </Container>
          </div>
        </div> */}
        <Navbar expand="lg">
          <Container>
            <div className="header-main">
              <div className="float-left">
                <Navbar.Brand>
                  <div className="logo">
                    <NavLink id="logo" to="/">
                      <img src={logo} alt="logo" />
                    </NavLink>
                  </div>
                </Navbar.Brand>
              </div>
              <div className="float-right">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto nav">
                    <div className="navbar-right ">
                      <div className="bottom-header nav__menu">
                        <ul>
                          <li className="nav__menu-item">
                            <span className="nav-span">
                              <NavLink
                                exact
                                activeClassName="active"
                                className="nav-link"
                                to="/"
                              >
                                Home
                              </NavLink>
                            </span>
                          </li>
                          <li
                            className="nav__menu-item"
                            onMouseLeave={this.handleLeave}
                          >
                            <span className="nav-span">
                              <NavLink
                                activeClassName="active"
                                className="nav-link"
                                to="/products"
                                onMouseEnter={this.handleHover}
                              >
                                Export Products
                              </NavLink>

                              <div
                                className={`submenu-container ${
                                  this.state.showChildMenu && 'update-z-index'
                                }`}
                              >
                                <CSSTransitionGroup
                                  transitionName="slide"
                                  transitionEnterTimeout={300}
                                  transitionLeaveTimeout={300}
                                >
                                  {this.state.showChildMenu && (
                                    <ul className="nav__submenu">
                                      <li className="nav__submenu-item ">
                                        <span className="nav-span">
                                          <NavLink
                                            exact
                                            activeClassName="active"
                                            className="nav-link"
                                            to="/products"
                                          >
                                            By Industry
                                          </NavLink>
                                        </span>
                                      </li>
                                      <li className="nav__submenu-item ">
                                        <span className="nav-span">
                                          <NavLink
                                            exact
                                            activeClassName="active"
                                            className="nav-link"
                                            to="/products/list"
                                          >
                                            Product List
                                          </NavLink>
                                        </span>
                                      </li>
                                    </ul>
                                  )}
                                  {/*//this.state.showChildMenu*/}
                                </CSSTransitionGroup>
                              </div>
                            </span>
                          </li>
                          <li
                            className="nav__menu-item"
                            onMouseLeave={this.handleLeave}
                          >
                            <span className="nav-span">
                              <NavLink
                                activeClassName="active"
                                className="nav-link"
                                to="/manufacturedproducts"
                                onMouseEnter={this.handleHover}
                              >
                                Manufactured Products
                              </NavLink>

                              <div
                                className={`submenu-container ${
                                  this.state.showChildMenu && 'update-z-index'
                                }`}
                              >
                                <CSSTransitionGroup
                                  transitionName="slide"
                                  transitionEnterTimeout={300}
                                  transitionLeaveTimeout={300}
                                >
                                  {this.state.showChildMenu && (
                                    <ul className="nav__submenu">
                                      <li
                                        className="nav__submenu-item "
                                        onMouseLeave={this.handleSubLeave}
                                      >
                                        <span className="nav-span">
                                          <NavLink
                                            exact
                                            activeClassName="active"
                                            className="nav-link"
                                            to="/products/masterbatches"
                                            onMouseEnter={this.handleSubHover}
                                          >
                                            Masterbatches
                                          </NavLink>

                                          <div
                                            className={`submenu-container ${
                                              this.state.showMasterMenu &&
                                              'update-z-index'
                                            }`}
                                          >
                                            <CSSTransitionGroup
                                              transitionName="slide"
                                              transitionEnterTimeout={300}
                                              transitionLeaveTimeout={300}
                                            >
                                              {this.state.showMasterMenu && (
                                                <ul className="nav__submenu nav__submenu--right">
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/masterbatches/black-masterbatches"
                                                      >
                                                        Black Masterbatches
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/masterbatches/white-masterbatches"
                                                      >
                                                        White Masterbatches
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/masterbatches/filler-masterbatches"
                                                      >
                                                        Filler Masterbatches
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                </ul>
                                              )}
                                              {/*//this.state.showChildMenu*/}
                                            </CSSTransitionGroup>
                                          </div>
                                        </span>
                                      </li>
                                      <li
                                        className="nav__submenu-item "
                                        onMouseLeave={this.handleSubLeaveMoly}
                                      >
                                        <span className="nav-span">
                                          <NavLink
                                            exact
                                            activeClassName="active"
                                            className="nav-link"
                                            to="/products/molybdenum"
                                            onMouseEnter={
                                              this.handleSubHoverMoly
                                            }
                                          >
                                            Molybdenum
                                          </NavLink>

                                          <div
                                            className={`submenu-container ${
                                              this.state.showMasterMenu &&
                                              'update-z-index'
                                            }`}
                                          >
                                            <CSSTransitionGroup
                                              transitionName="slide"
                                              transitionEnterTimeout={300}
                                              transitionLeaveTimeout={300}
                                            >
                                              {this.state.showMolyMenu && (
                                                <ul className="nav__submenu nav__submenu--right">
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/molybdenum/sodium-molybdate"
                                                      >
                                                        Sodium Molybdate
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/molybdenum/ammonium-di-molybdate"
                                                      >
                                                        Ammonium Di Molybdate
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/molybdenum/ammonium-hepta-molybdate"
                                                      >
                                                        Ammonium Hepta Molybdate
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/molybdenum/ammonium-tetra-molybdate"
                                                      >
                                                        Ammonium Tetra Molybdate
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/molybdenum/pure-molybdenum-trioxide"
                                                      >
                                                        Pure Molybdenum Trioxide
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                  <li className="nav__submenu-item ">
                                                    <span className="nav-span">
                                                      <NavLink
                                                        exact
                                                        activeClassName="active"
                                                        className="nav-link"
                                                        to="/products/molybdenum/roasted-molybdenum-concentrate"
                                                      >
                                                        Roasted Molybdenum
                                                        Concentrate
                                                      </NavLink>
                                                    </span>
                                                  </li>
                                                </ul>
                                              )}
                                              {/*//this.state.showChildMenu*/}
                                            </CSSTransitionGroup>
                                          </div>
                                        </span>
                                      </li>
                                    </ul>
                                  )}
                                  {/*//this.state.showChildMenu*/}
                                </CSSTransitionGroup>
                              </div>
                            </span>
                          </li>
                          <li className="nav__menu-item">
                            <span className="nav-span">
                              <NavLink
                                exact
                                activeClassName="active"
                                className="nav-link"
                                to="/about"
                              >
                                ABOUT US
                              </NavLink>
                            </span>
                          </li>
                          <li className="nav__menu-item">
                            <span className="nav-span">
                              <NavLink
                                exact
                                activeClassName="active"
                                className="nav-link"
                                to="/csr"
                              >
                                CSR
                              </NavLink>
                            </span>
                          </li>
                          {/* <li className="nav__menu-item">
                            <span className="nav-span">
                              <NavLink
                                exact
                                activeClassName="active"
                                className="nav-link"
                                to="/career"
                              >
                                CAREER
                              </NavLink>
                            </span>
                          </li> */}
                          <li className="nav__menu-item">
                            <span className="nav-span">
                              <NavLink
                                exact
                                activeClassName="active"
                                className="nav-link"
                                to="/contact"
                              >
                                Contact
                              </NavLink>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default NavBar;
