import React from 'react';
import { NavLink } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { MdFileDownload } from 'react-icons/md';
import GetQuotaButton from '../../../../components/GetQuotaButton';
import '../../../../style/product-child-molybdenum.css';
import '../../../../style/typography.css';

import Banner from '../../../../components/banner';
import ContactUsForm from '../../../../components/ContactUsForm/index';
import DownloadForm from '../../../../components/DownloadForm/index';
import {
  molybdenumProducts,
  allProducts,
  productNotFoundText,
} from '../../../../data/ProductPage';
import Page404 from '../../../Page404';

class MolybdenumProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const pageId = this.props.match.params.id;
    const product = molybdenumProducts[pageId];
    if (!product) {
      return <Page404 />;
    }
    return (
      <div className="product-main-block">
        <Banner image={product.banner} title={product.name} />
        <div className="product-details-block">
          <Container>
            <Row>
              <Col
                lg={9}
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-details-sub-block">
                  <div className="product-details-block-title">
                    <h2 className="section-heading-primary">
                      {product.name}
                      {product.details.formula ? (
                        <span>
                          <span> (</span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: product.details.formula,
                            }}
                          ></span>
                          <span>)</span>
                        </span>
                      ) : (
                        ''
                      )}
                    </h2>
                  </div>
                  {/* <div className="product-details-block-info">
                    {product.information.map((item, index) => {
                      return (
                        <p
                          className="para para--light-gray"
                          key={`productMainPara-${index}`}
                        >
                          {item}
                        </p>
                      );
                    })}
                  </div> */}
                </div>
                {product.showProduct ? (
                  <div className="product-details-sub-block">
                    <div className="product-details-block-title">
                      <h2 className="heading-secondary">products range</h2>
                    </div>
                    <div className="product-details-block-info">
                      {product.productRange.map((item, index) => {
                        return (
                          <li key={`product-range-${index}`}>{item.name}</li>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="product-details-sub-block">
                  <div className="product-details-block-title">
                    <h2 className="heading-secondary">details</h2>
                  </div>
                  <div className="product-details-block-info">
                    <div className="details-block">
                      <span className="details-block-heading">
                        MO Content:{' '}
                      </span>
                      <span>{product.details.mo}</span>
                    </div>
                    <div className="details-block">
                      <span className="details-block-heading">
                        molecular formula:{' '}
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: product.details.formula,
                        }}
                      ></span>
                    </div>
                    <div className="details-block">
                      <span className="details-block-heading">assay: </span>
                      <span>{product.details.assay}</span>
                    </div>
                    <div className="details-block">
                      <span className="details-block-heading">CAS: </span>
                      <span>{product.details.cas}</span>
                    </div>
                    <div className="details-block">
                      <span className="details-block-heading">HS code: </span>
                      <span>{product.details.code}</span>
                    </div>
                    <div className="details-block">
                      <span className="details-block-heading">packaging: </span>
                      <span>{product.details.packing}</span>
                    </div>
                  </div>

                  <div className="product-details-block-title">
                    <h2 className="heading-secondary">applications</h2>
                  </div>
                  <div className="product-details-block-info">
                    {product.application.map((item, index) => {
                      return <li key={`product-range-${index}`}>{item}</li>;
                    })}
                  </div>

                  <div className="product-btns">
                    <GetQuotaButton
                      btnSize="medium"
                      productList={molybdenumProducts[pageId].productRange.map(
                        (type) => ({
                          name: `${product.name} (${type.name})`,
                        }),
                      )}
                    />
                    {product.tds ? (
                      <div>
                        <Button
                          className="btn-blue btn-medium brochure-button"
                          onClick={this.onOpenModal}
                        >
                          <MdFileDownload className="brochure-icon" />
                          TDS
                        </Button>
                        <Modal
                          open={this.state.open}
                          onClose={this.onCloseModal}
                          classNames={{
                            overlay: 'quote-modal-overlay',
                            modal: 'quote-modal remove-shadow',
                          }}
                        >
                          <DownloadForm downloadLoc={product.tds} />
                        </Modal>
                      </div>
                    ) : (
                      ''
                    )}
                    {product.msds ? (
                      <div>
                        <Button
                          className="btn-blue btn-medium brochure-button"
                          onClick={this.onOpenModal}
                        >
                          <MdFileDownload className="brochure-icon" />
                          MSDS
                        </Button>
                        <Modal
                          open={this.state.open}
                          onClose={this.onCloseModal}
                          classNames={{
                            overlay: 'quote-modal-overlay',
                            modal: 'quote-modal remove-shadow',
                          }}
                        >
                          <DownloadForm downloadLoc={product.msds} />
                        </Modal>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <p className="para para--large para--light-gray">
                    {productNotFoundText}
                  </p>
                  <div
                    className="product-card-block"
                    data-aos="fade-up"
                    data-aos-delay="250"
                    data-aos-once="true"
                    data-aos-anchor=".product-details-block"
                  >
                    <ContactUsForm />
                  </div>
                </div>
              </Col>
              <Col
                lg={3}
                data-aos="fade-left"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-category-main-block">
                  <div className="product-category">
                    <div className="product-category-title">
                      <h2>categories</h2>
                    </div>
                    <ul>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products"
                        >
                          all products
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/international"
                        >
                          export
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/masterbatches"
                        >
                          masterbatches
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/molybdenum"
                        >
                          molybdenum
                        </NavLink>
                        <ul>
                          {allProducts.molybdenum.produts.map((item) => {
                            return (
                              <li>
                                <NavLink
                                  exact
                                  activeClassName="active"
                                  className="nav-link"
                                  to={item.url}
                                >
                                  {item.name}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <Button
                    className="btn-blue btn-medium brochure-button"
                    href="/downloads/brochure/Palvi Industries.pdf"
                    target="_blank"
                    download
                  >
                    <MdFileDownload className="brochure-icon" />
                    BROCHURE
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default MolybdenumProductPage;
