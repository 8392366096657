export const meta = [
  {
    route: '/',
    title:
      'Top Industrial Chemical Company in India- Manufacturer -Distributor | Palvi Chemical',
    description:
      'Palvi Industries Ltd. is one of the top Industrial Chemical Manufacturing Company in India. We are providing the best quality products to customers and distribute a wide range of chemicals. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products',
    title:
      'Masterbatches for Industrial Products |Exporter of Chemical Products| Palvi Chemical',
    description:
      'Palvi Chemical is a leading exporter of the masterbatches used in variety of industries. We cover product range from detergent-soap to cosmetics, from textiles to plastics, flavor & fragrance to drilling and mining, and many more. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/list',
    title:
      'Top Chemicals Product – Manufacturer - Supplier -Exporter | Palvi Chemical',
    description:
      'Palvi is a leading manufacturer of top chemicals product in India. We are among the largest manufacturer of Activated Carbon, Ammonium Bicarbonate, Acetic Acid and many more. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/masterbatches',
    title:
      'Masterbatches Black |Masterbatches White | Masterbatches Filler - Manufacturers',
    description:
      'Are you looking for masterbatch manufacturers in India? With a production capacity of 5000 MT per annum. Palvi Chemical is one of the largest manufacturers of black, white, filler and additive masterbatches. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/masterbatches/black-masterbatches',
    title:
      'Black Masterbatches - Supplier - Manufacturers - Exporter | Palvi chemical',
    description:
      'Palvi is a leading manufacturer, exporter, and supplier of black Masterbatches. We have a very extensive range of black masterbatches suitable for Mulch Film, Drip Irrigation, and many more applications. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/masterbatches/white-masterbatches',
    title:
      'White Masterbatches - Manufacturers– Supplier - Exporter | Palvi chemical',
    description:
      'Palvi is a leading manufacturer, Exporter and supplier of White Masterbatches. Our masterbatches are available in hues of pure white as well as white with blue-ish undertone having specialty in manufacturing high-quality Tio2-based White Masterbatches. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/masterbatches/filler-masterbatches',
    title:
      'Filler Masterbatches - Manufacturers – Supplier - Exporter | Palvi chemical',
    description:
      'Palvi is a leading manufacturer, exporter, and supplier of Filler Masterbatches. Our filler masterbatches are quality tested PE, PP, and PS base carriers. We are producing a wide range of filler masterbatches. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/molybdenum',
    title:
      'Molybdenum Chemicals -Manufacturers -Supplier - Exporter | Palvi chemical',
    description:
      'Palvi is amongst India’s top manufacturers, Exporter and supplier of Molybdenum chemicals. We are specialized in producing Sodium Molybdate, Ammonium Molybdate & Molybdenum Trioxide. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/molybdenum/sodium-molybdate',
    title:
      'Sodium Molybdate Chemical – Manufacturers - Supplier- Exporter -Palvi chemical',
    description:
      'Palvi chemical is a supplier and exporter of Sodium Molybdate in India. Sodium Molybdate is widely used in applications like manufacturing of Pigments, micro Nutrients, Corrosion Inhibitors and many more. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/molybdenum/ammonium-di-molybdate',
    title:
      'Ammonium Di Molybdate Chemical - Manufacturer – Supplier - Distributor| Palvi chemical',
    description: `We're Ammonium Di Molybdate manufacturers, Supplier and Distributor in India. Ammonium Di Molybdate widely used in various industries to prepare high purity molybdenum metal powder, colorimetric analysis of phosphate, to decorate ceramic and as catalysts. For more details contact this no. +91-265-2796401`,
  },
  {
    route: '/products/molybdenum/ammonium-hepta-molybdate',
    title:
      'Ammonium Hepta Molybdate Chemicals:: Manufacturers - Supplier | Palvi chemical',
    description:
      'Palvi Chemical is one of the leading chemical manufacturing companies in India. We manufacture and Supply Ammonium Hepta Molybdate chemicals for various Industry’s needs. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/molybdenum/ammonium-tetra-molybdate',
    title:
      'Ammonium Tetra Molybdate Chemicals - Manufacturers- Supplier –Trader| Palvi chemical',
    description:
      'Palvi is one of the leading trader and Supplier of the wide range of Industrial Chemicals. We manufacture Ammonium Tetra Molybdate chemicals which are mostly used for the Laboratory Reagent Industry in India. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/molybdenum/pure-molybdenum-trioxide',
    title:
      'Pure Molybdenum Trioxide Chemicals -Manufacturers - Supplier - Exporter | Palvi chemical',
    description:
      'Palvi is the leading Pure Molybdenum Trioxide chemicals manufacturers & exporters in India. And supply a wide range of chemicals for different industries like Rubbers, Paints, and Textiles, etc. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/molybdenum/roasted-molybdenum-concentrate',
    title:
      'Roasted Molybdenum Concentrate Chemicals:: Manufacturers – Suppliers  | Palvi chemical',
    description:
      'Palvi is the leading best manufacturers of roasted molybdenum concentrate chemicals in Vadodara, Gujarat. We are a supplier of Chemicals for major industries across the globe. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international',
    title:
      'Industrial Chemicals - Exporters - Distributors - Suppliers – Palvi Chemical',
    description:
      'Palvi’s core strength has always been exports. Our company with its network of distributors and suppliers sources and export a wide range of Industrial Chemicals. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/soap-detergent',
    title:
      'Chemicals Supplier of Soap, Detergent and Laundry Industries – Palvi Chemical',
    description:
      'At Palvi chemical. We produce a host of industrial chemicals that we supply for soap and detergent manufacturing companies. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/paper-textile',
    title:
      'Paper and Textile Industrial Chemicals  ::Manufacturers – Exporter - Distributor– Palvi Chemical',
    description: `Palvi chemical is one of India's leading Paper and Textile Chemicals manufacturing company. We are Exporter and Distributor of chemicals for Paper and Textile industries. For more details contact this no. +91-265-2796401`,
  },
  {
    route: '/products/international/food-ingredient',
    title:
      'Food Ingredient Industrial Chemicals ::  Manufacturers | Supplier | Distributor- Palvi chemical',
    description:
      'We supply Organic Chemicals for Food Ingredients. Palvi chemical is the most trusted and valuable name as Supplier, Exporter and manufacturers of Food Ingredient Chemicals from India. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/cosmetic',
    title:
      'Supplier of Cosmetics & Skincare Chemicals ::  Manufacturers |Distributor - Palvi chemical',
    description:
      'We are suppliers of optimum quality of standardized chemicals for cosmetics and skincare industries. Palvi chemical is the world-class leading manufacturer in India. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/flavour-Fragrance',
    title:
      'Suppliers of Flavours and Fragrances Chemicals :: Manufacturers | Exporter - Palvi chemical',
    description: `Palvi chemical is one of India's leading Flavours and Fragrances Chemicals manufacturing company. We are Manufacturers and exporters of chemicals for Food flavours and fragrances. For more details contact this no. +91-265-2796401`,
  },
  {
    route: '/products/international/polyurathene',
    title: 'Polyurethane Chemicals Manufacturer Company - Palvi Chemical',
    description:
      'We manufacture of fine quality industrial chemicals for polyurethane. Palvi chemical is a progressive name in the field of the Industrial Chemical manufacturer. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/paint-conating',
    title:
      'Supplier of Paint And Coating Industrial Chemicals :: Manufacturers - Palvi chemical',
    description:
      'Palvi chemical has a strong presence in the manufacturing paint and coating chemicals. We are leading company for manufacturing chemicals for the Paints and Coatings Industry in India. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/plastics',
    title: 'Top Plastics Chemicals Manufacturing Companies - Palvi chemical',
    description:
      'We manufacture chemicals for the Plastics Industry in India. Our products include Chlorinated Paraffin and Plasticizers like DOP, DINP, DIBP and many more. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/drilling-mining',
    title:
      'Distributor of Drilling & Mining Chemicals in India :: Manufacturing | Exporter – Palvi Chemical',
    description:
      'Palvi is manufacturing drilling and mining chemicals. We are exporting and distributing Chemicals like Activated Carbon, Nitric Acid, Hydrochloric Acid and many more for Drilling & Mining industries. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/water-treatment',
    title:
      'Distributor of Water Treatment Chemicals :: Manufacturer | Trader - Palvi chemical',
    description:
      'We are manufacturing high-quality chemicals for water treatment industries in India. Palvi is the largest distributor and trader in chemical Industry. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/products/international/other-products',
    title: 'Chemicals Manufacturing Company in India - Palvi chemical',
    description:
      'Palvi is one of the largest Chemicals Manufacturing Company in India. We are experts in manufacturing Chemicals like Acetic Acid, Benzyl Chloride, Zinc Oxide and more. For more details contact this no. +91-265-2796401',
  },
  {
    route: '/contact',
    title:
      'Contact Us:: Manufacturer and Exporter of Industrial Chemicals | Palvi chemical',
    description:
      'We’re leading manufacturing and exporting company for Industrial Chemicals. Contact us to order chemicals for industries like Cosmetics, Paint, plastics and many more. Visit our site or call this no. +91-265-2796401',
  },
  {
    route:
      '/products/international/soap-detergent/sodium-lauryl-ether-sulphate',
    title:
      'SLES Supplier | Distributor| Exporter in Ghana, Nigeria | Palvi Chemicals',
    description:
      'Sodium Lauryl Ether Sulphate(SLES) Supplier | Distributor | Exporter in Ghana, Nigeria, Morocco, UAE, USA, Canada, etc. | Contact Palvi Chemical Now !',
  },
  {
    route: '/products/international/soap-detergent/cocamidopropyl-betaine',
    title:
      'CAPB Supplier |Distributor | Exporter in Nigeria, Ghana | Palvi Chemicals',
    description:
      'Palvi Chemicals is a distinguished Supplier | Distributor |Exporter of CAPB in Ghana, Nigeria, Ecuador, Colombia, Mexico, etc.',
  },
  {
    route: '/products/international/other-products/zinc-sulphate',
    title:
      'Zinc Sulfate Supplier | Distributor | Exporter in Nigeria, Ghana | Palvi Chemical',
    description:
      'Palvi Chemicals is a popular Zinc Sulfate Supplier | Distributor | Exporter in Ghana, Nigeria, Morocco, Qatar, Egypt, etc.',
  },
  {
    route: '/products/international/other-products/manganese-sulphate',
    title:
      'Manganese Sulfate Supplier | Distributor | Exporter in Ghana, Nigeria',
    description:
      'Palvi Chemicals is a Supplier | Distributor | Exporter of Manganese Sulfate in Ghana, Nigeria, Kuwait, Bahrain, Dominican Republic, etc.  ',
  },
  {
    route: '/products/international/food-ingredient/sorbitol',
    title: 'Sorbitol Supplier | Distributor | Exporter in Nigeria | Ghana',
    description:
      'Palvi Chemicals is the best Supplier | Distributor | Exporter of Sorbitol in Ghana, Nigeria, Puerto Rico, Mexico, Ivory Coast, etc.',
  },
  {
    route: '/products/international/other-products/formaldehyde',
    title: 'Formaldehyde Supplier | Distributor | Exporter in Ghana, Nigeria',
    description:
      'Palvi Chemicals is a prominent Supplier | Distributor | Exporter of Formaldehyde in Ghana, Nigeria, Kuwait, Algeria, Qatar, Kenya, etc | Connect with us Now!',
  },
];
