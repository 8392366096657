import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { MdFileDownload } from 'react-icons/md';
import GetQuotaButton from '../../../../components/GetQuotaButton';
import '../../../../style/product-child-masterbatch.css';
import ParaWithPhoto from '../Acid/Components/ParaWithPhoto';
import Heading from '../Acid/Components/Heading';
import List from '../Acid/Components/List';

// import Banner from '../../../../components/banner';
import ContactUsForm from '../../../../components/ContactUsForm/index';
import {
  tollProducts,
  allProducts,
  productNotFoundText,
  exportNoteText,
} from '../../../../data/ProductPage';
import Page404 from '../../../Page404';

class TollProductPage extends React.Component {
  render() {
    const pageId = this.props.match.params.id;
    const product = tollProducts[pageId];
    if (!product) {
      return <Page404 />;
    }
    return (
      <div className="product-main-block">
        {/* <Banner image={product.banner} title={product.name} /> */}
        <div className="product-details-block">
          <Container>
            <Row>
              <Col
                lg={9}
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-details-sub-block">
                  {product.content.map((contentObj) => {
                    let a;
                    switch (contentObj.type) {
                      case 'heading':
                        a = <Heading text={contentObj.text} />;
                        break;

                      case 'paraWithPhoto':
                        a = (
                          <ParaWithPhoto
                            image={contentObj.data.image}
                            text={contentObj.data.information}
                          />
                        );
                        break;

                      case 'list':
                        a = (
                          <List
                            title={contentObj.data.title}
                            items={contentObj.data.content}
                          />
                        );
                        break;

                      default:
                        break;
                    }
                    return a;
                  })}

                  <div className="product-btns">
                    <GetQuotaButton
                      btnSize="medium"
                      productList={tollProducts[pageId].productRange}
                    />
                  </div>
                  <p className="para para--large para--dark-gray">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: exportNoteText,
                      }}
                    ></span>
                  </p>
                  <p className="para para--large para--light-gray">
                    {productNotFoundText}
                  </p>
                  <div
                    className="product-card-block"
                    data-aos="fade-up"
                    data-aos-delay="250"
                    data-aos-once="true"
                    data-aos-anchor=".product-details-block"
                  >
                    <ContactUsForm />
                  </div>
                </div>
              </Col>
              <Col
                lg={3}
                data-aos="fade-left"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-category-main-block">
                  <div className="product-category">
                    <div className="product-category-title">
                      <h2>categories</h2>
                    </div>
                    <ul>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products"
                        >
                          all products
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/international"
                        >
                          export
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/masterbatches"
                        >
                          masterbatches
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/molybdenum"
                        >
                          molybdenum
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/acid"
                        >
                          acid
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/toll"
                        >
                          toll
                        </NavLink>
                        <ul>
                          {allProducts.toll.produts.map((item) => {
                            return (
                              <li>
                                <NavLink
                                  exact
                                  activeClassName="active"
                                  className="nav-link"
                                  to={item.url}
                                >
                                  {item.name}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <Button
                    className="btn-blue btn-medium brochure-button"
                    href="/downloads/brochure/Palvi Industries.pdf"
                    target="_blank"
                    download
                  >
                    <MdFileDownload className="brochure-icon" />
                    BROCHURE
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default TollProductPage;
