import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { MdPhoneInTalk, MdEmail, MdHome } from 'react-icons/md';

import starImg from '../../assests/footer/2star.png';
import iso9001Img from '../../assests/footer/iso9001.png';
import iso14001Img from '../../assests/footer/iso14001.png';

import '../../style/footer.css';
import '../../style/typography.css';

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row className="footer-row">
          <Col sm={3}>
            <div className="footer-group">
              <h3 className="footer-group--title">quick links</h3>
              <div className="footer-links">
                <ul>
                  <li>
                    <Link to="/products/international">Export Products</Link>
                  </li>
                  <li>
                    <Link to="/products/masterbatches">Masterbatches</Link>
                  </li>
                  <li>
                    <Link to="/products/molybdenum">Molybdenum</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/career">Careers</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-socialmedia">
                <a href="#s" className="footer-linkedin">
                  <FaLinkedinIn />
                </a>
                <a href="#s" className="footer-facebook">
                  <FaFacebookF />
                </a>
              </div>
            </div>
          </Col>
          <Col sm={3}>
            <div className="footer-group">
              <h3 className="footer-group--title">get in touch</h3>
              <div className="footer-contactus">
                <div className="footer-contactus-group">
                  <span>
                    <MdPhoneInTalk />
                  </span>
                  <p>+91-265-2796401</p>
                </div>
                <div className="footer-contactus-group">
                  <span>
                    <MdEmail />
                  </span>
                  <p>
                    <a href="mailto: info@palvichemical.com">
                      info@palvichemical.com
                    </a>
                  </p>
                </div>
                <div className="footer-contactus-group">
                  <span>
                    <MdHome />
                  </span>
                  <p>
                    315 Aditviya Complex,
                    <br />
                    Near Deluxe Cross Road,
                    <br />
                    Nizampura,
                    <br />
                    Vadodara 390 002 India.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={3}>
            <div className="footer-group">
              <h3 className="footer-group--title">newsroom</h3>
              <div className="footer-links">
                <ul>
                  <li>
                    <Link to="/press-release">Press Release</Link>
                  </li>
                  <li>
                    <Link to="/media-gallery">Media Gallery</Link>
                  </li>
                  <li>
                    <Link to="/director-message">Directors Message</Link>
                  </li>
                  {/* <li>
                    <Link to="/products/masterbatches">Blogs and Articles</Link>
                  </li>
                  <li>
                    <Link to="/contact">Corporate Social Responsibility</Link>
                  </li>
                  <li>
                    <Link to="/about">Sustainability</Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </Col>
          <Col sm={3}>
            <div className="footer-group">
              <Row>
                <img
                  className="footer-cert-img"
                  alt="2 Star Export House"
                  src={starImg}
                />
              </Row>
              <Row>
                <Col xs={6}>
                  <img
                    className="footer-iso-img"
                    alt="ISO 9001"
                    src={iso9001Img}
                  />
                </Col>
                <Col xs={6}>
                  <img
                    className="footer-iso-img"
                    alt="ISO 14001"
                    src={iso14001Img}
                  />
                </Col>
              </Row>
              <Row>
                <Link
                  className="db-certificate-link"
                  to="/downloads/press-release/Dun&Bradstreet Certificate.pdf"
                  target="_blank"
                  download
                >
                  <p>DUN & BRADSTREET CERTIFICATE</p>
                </Link>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Footer;
