export const accordion = [
  {
    leftLabel: 'Web Analyst',
    rightLabel: 'Vadodara, Gujarat, India',
    leftContent: {
      title: 'Responsbility',
      type: 'ul',
      list: [
        'Using a variety of tools to extract and analyze data generated by online user activity.',
        'Reporting your findings with data visualizations that are easy to understand.',
        'Communicating insights and providing solutions that have demonstrable results.',
        'Working with web development and marketing teams to implement your solutions.',
        'Understanding the behavior and expectations of online visitors.',
        'Staying up to date with the latest trends in online marketing and design.',
      ],
    },
    rightContent: {
      title: 'Qualifications',
      type: 'ul',
      list: [
        `Bachelor's degree in Marketing, Statistics, Computer Science or other related fields.`,
        'Previous data mining and analysis experience.',
        'Familiarity with major web analytics tools, such as Google Analytics and Cloudflare.',
        'Strong verbal and visual communication skills to present and explain insights.',
        'Excellent attention to detail and accuracy.',
        'Ability to work effectively with different people in engineering, marketing, and creative fields.',
      ],
    },
  },
];
