import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { MdFileDownload } from 'react-icons/md';
import GetQuotaButton from '../../../../components/GetQuotaButton';
import MasterbatchTable from '../../../../components/MasterbatchTable';
import '../../../../style/product-child-masterbatch.css';

import Banner from '../../../../components/banner';
import ContactUsForm from '../../../../components/ContactUsForm/index';
import {
  masterbatchesProducts,
  allProducts,
  productNotFoundText,
} from '../../../../data/ProductPage';
import Page404 from '../../../Page404';

class MasterbatchProductPage extends React.Component {
  render() {
    const pageId = this.props.match.params.id;
    const product = masterbatchesProducts[pageId];
    if (!product) {
      return <Page404 />;
    }
    return (
      <div className="product-main-block">
        <Banner image={product.banner} title={product.name} />
        <div className="product-details-block">
          <Container>
            <Row>
              <Col
                lg={9}
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-details-sub-block">
                  <div className="product-details-block-title">
                    <h2 className="section-heading-primary">{product.name}</h2>
                  </div>
                  <div className="product-details-block-info">
                    <img src={product.image} alt="" />
                    {product.information.map((item, index) => {
                      return <p key={`productMainPara-${index}`}>{item}</p>;
                    })}
                  </div>
                </div>
                {product.showProduct ? (
                  <div className="product-details-sub-block">
                    <div className="product-details-block-title">
                      <h2>products range</h2>
                    </div>
                    <div className="product-details-block-info">
                      {product.showProduct ? (
                        product.showTable ? (
                          <MasterbatchTable data={product.productRange} />
                        ) : (
                          product.productRange.map((item, index) => {
                            return (
                              <li key={`product-range-${index}`}>
                                {item.name}
                              </li>
                            );
                          })
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="product-details-sub-block">
                  <div className="product-details-block-title">
                    <h2>advantages</h2>
                  </div>
                  <div className="product-details-block-info">
                    {product.advantages.map((item, index) => {
                      return <li key={`product-range-${index}`}>{item}</li>;
                    })}
                  </div>

                  <div className="product-details-block-title">
                    <h2>applications</h2>
                  </div>
                  <div className="product-details-block-info">
                    {product.application.map((item, index) => {
                      return <li key={`product-range-${index}`}>{item}</li>;
                    })}
                  </div>

                  <div className="product-btns">
                    <GetQuotaButton
                      btnSize="medium"
                      productList={masterbatchesProducts[pageId].productRange}
                    />
                  </div>
                  <p className="para para--large para--light-gray">
                    {productNotFoundText}
                  </p>
                  <div
                    className="product-card-block"
                    data-aos="fade-up"
                    data-aos-delay="250"
                    data-aos-once="true"
                    data-aos-anchor=".product-details-block"
                  >
                    <ContactUsForm />
                  </div>
                </div>
              </Col>
              <Col
                lg={3}
                data-aos="fade-left"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-category-main-block">
                  <div className="product-category">
                    <div className="product-category-title">
                      <h2>categories</h2>
                    </div>
                    <ul>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products"
                        >
                          all products
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/international"
                        >
                          export
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/masterbatches"
                        >
                          masterbatches
                        </NavLink>
                        <ul>
                          {allProducts.masterbatches.produts.map((item) => {
                            return (
                              <li>
                                <NavLink
                                  exact
                                  activeClassName="active"
                                  className="nav-link"
                                  to={item.url}
                                >
                                  {item.name}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/molybdenum"
                        >
                          molybdenum
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <Button
                    className="btn-blue btn-medium brochure-button"
                    href="/downloads/brochure/Palvi Industries.pdf"
                    target="_blank"
                    download
                  >
                    <MdFileDownload className="brochure-icon" />
                    BROCHURE
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default MasterbatchProductPage;
