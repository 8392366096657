import React from 'react';
import { Table } from 'react-bootstrap';

import '../../style/masterbatchTable.css';

const MasterbatchTable = props => {
  const data = props.data;
  return (
    <div>
      <Table
        className="table-masterbatch"
        size="sm"
        responsive
        striped
        bordered
        hover
      >
        <thead>
          <tr>
            {Object.keys(data[0]).map(key => {
              return <th>{key.charAt(0).toUpperCase() + key.slice(1)}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map(obj => {
            return (
              <tr>
                {Object.keys(obj).map(key => {
                  return <td>{obj[key]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default MasterbatchTable;
