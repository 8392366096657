import alay_mehta from '../../assests/aboutus/team/alay_mehta.jpg';
import abhishek_chauhan from '../../assests/aboutus/team/abhishek_chauhan.jpg';
import amitabh_singh from '../../assests/aboutus/team/amitabh_singh.jpg';
import anshul_parashar from '../../assests/aboutus/team/anshul_parashar.jpg';
import himanshu_mehta from '../../assests/aboutus/team/himanshu_mehta.jpg';
import janaki_patel from '../../assests/aboutus/team/janaki_patel.jpg';
import jasmin_lala from '../../assests/aboutus/team/jasmin_lala.jpg';
import karan_brahmbhatt from '../../assests/aboutus/team/karan_brahmbhatt.jpg';
import kumod_mistry from '../../assests/aboutus/team/kumod_mistry.jpg';
import manoj_pillai from '../../assests/aboutus/team/manoj_pillai.jpg';
import rakesh_agrawal from '../../assests/aboutus/team/rakesh_agrawal.jpg';
import sanjay_sahu from '../../assests/aboutus/team/sanjay_sahu.jpg';
import shreeraj_jethwa from '../../assests/aboutus/team/shreeraj_jethwa.jpg';
import tausif_shaikhji from '../../assests/aboutus/team/tausif_shaikhji.jpg';
import trupti_panchal from '../../assests/aboutus/team/trupti_panchal.jpg';
import vibha_mehta from '../../assests/aboutus/team/vibha_mehta.jpg';
import yagnesh_mistry from '../../assests/aboutus/team/yagnesh_mistry.jpg';
import aditya_bhavsar from '../../assests/aboutus/team/aditya_bhavsar.jpg';
import kajal_joshi from '../../assests/aboutus/team/kajal_joshi.jpg';
import nirav_mistry from '../../assests/aboutus/team/nirav_mistry.jpeg';
import priyanka_muktibodh from '../../assests/aboutus/team/priyanka_muktibodh.jpg';
import sheron_souter from '../../assests/aboutus/team/sheron_souter.jpg';
import shivani_patel from '../../assests/aboutus/team/shivani_patel.jpg';
import sudipto_bhowmik from '../../assests/aboutus/team/sudipto_bhowmik.jpg';

export default {
  company: [
    {
      image: himanshu_mehta,
      name: 'Mr. Himanshu Mehta',
      position: 'CEO & Managing Director',
      email: 'himanshu@palvichemical.com',
      phone: '9825031036',
    },
  ],
  molybdenum: [
    {
      image: himanshu_mehta,
      name: 'Mr. Himanshu Mehta',
      position: 'Managing Director - Molybdenum Unit',
      email: 'himanshu@palvichemical.com',
      phone: '9825031036',
    },
    {
      image: alay_mehta,
      name: 'Mr. Alay Mehta',
      position: 'Business Head - Molybdenum Unit',
      email: 'alay@palvichemical.com',
      phone: '8866270849',
    },
    {
      image: anshul_parashar,
      name: 'Mr. Anshul Parashar',
      position: 'Marketing Manager',
      email: 'anshul@palvichemical.com',
      phone: '9712943201',
    },
    {
      image: jasmin_lala,
      name: 'Ms. Jasmin Lala',
      position: 'Business Head - Molybdenum Unit & UAE Division',
      email: 'jasmin@palvichemical.com',
      phone: '9998050676',
    },
  ],
  masterbatch: [
    {
      image: aditya_bhavsar,
      name: 'Mr. Aditya Bhavsar',
      position: 'Business Head - Masterbatch Unit',
      email: 'aditya@palvimasterbatches.com',
      phone: '8980027689',
    },
  ],
  marketing: [
    {
      image: manoj_pillai,
      name: 'Mr. Manoj Pillai',
      position: 'General Manager-Exports',
      email: 'manoj@palvichemical.com  ',
      phone: '9825032403',
    },
    {
      image: sanjay_sahu,
      name: 'Mr. Sanjay Sahu',
      position: 'General Manager-Exports',
      email: 'sanjay@palvichemical.com',
      phone: '7227096465',
    },
    {
      image: abhishek_chauhan,
      name: 'Mr. Abhishek Chauhan',
      position: 'Export Manager - Trading Division',
      email: 'abhishek@palvichemical.com',
      phone: '8980041467',
    },
    {
      image: amitabh_singh,
      name: 'Mr. Amitabh Singh',
      position: 'Business Development Manager',
      email: 'amitabh@palvichemical.com',
      phone: '8980804406',
    },
    {
      image: karan_brahmbhatt,
      name: 'Mr. Karan Brahmbhatt',
      position: 'Marketing Executive',
      email: 'karan.b@palvichemical.com',
      phone: '8980041492',
    },
    {
      image: janaki_patel,
      name: 'Ms. Janaki Patel',
      position: 'Marketing Executive',
      email: 'janaki@palvichemical.com',
      phone: '8980804471',
    },
    // {
    //   image: sample,
    //   name: 'Ms. Priyanka Muktibodh',
    //   position: 'Export Manager -Trading Division',
    //   email: 'priyanka@palvichemical.com',
    //   phone: '8980804554',
    // },
    {
      image: shreeraj_jethwa,
      name: 'Mr. Shreeraj Jethwa ',
      position: 'Marketing Executive',
      email: 'shreeraj@palvichemical.com',
      phone: '8980804412',
    },
    {
      image: kajal_joshi,
      name: 'Mrs. Kajal Joshi',
      position: 'Assistant Manager',
      email: 'kajal@palvichemical.com',
      phone: '8487879704',
    },
    {
      image: nirav_mistry,
      name: 'Mr. Nirav Mistry',
      position: 'Assistant Manager',
      email: 'nirav@palvichemical.com',
      phone: '8487878027',
    },
    {
      image: priyanka_muktibodh,
      name: 'Mrs. Priyanka Muktibodh',
      position: 'Export Manager',
      email: 'priyanka@palvichemical.com',
      phone: '8980804554',
    },
    {
      image: sheron_souter,
      name: 'Mrs. Sheron Souter',
      position: 'Management Trainee',
      email: 'sheron@palvichemical.com',
      phone: '8487879703',
    },
    {
      image: shivani_patel,
      name: 'Mrs. Shivani Patel',
      position: 'Marketing Executive',
      email: 'shivani@palvichemical.com',
      phone: '8487878144',
    },
    {
      image: sudipto_bhowmik,
      name: 'Mr. Sudipto Bhowmik',
      position: 'General Manager',
      email: 'bhowmik@palvichemical.com',
      phone: '9099192628',
    },
  ],
  finance: [
    {
      image: vibha_mehta,
      name: 'Mrs. Vibha Mehta',
      position: 'Finance Director',
      email: 'vibha@palvichemical.com',
      phone: '9825032486',
    },
    {
      image: rakesh_agrawal,
      name: 'Mr. Rakesh Agarwal',
      position: 'Finance Manager',
      email: 'rakesh.agrawal@palvichemical.com',
      phone: '9998073307',
    },
    {
      image: tausif_shaikhji,
      name: 'Mr. Tausif Shaikhji',
      position: 'Manager - Accounts',
      email: 'accounts@palvichemical.com',
      phone: '9099931164',
    },
  ],
  logistics: [
    {
      image: kumod_mistry,
      name: 'Mr. Kumud Mistry',
      position: 'Assistant Manager - Logistics',
      email: 'kumud@palvichemical.com',
      phone: '9825032449',
    },
    {
      image: yagnesh_mistry,
      name: 'Mr. Yagnesh Mistry ',
      position: 'Executive - Logistics',
      email: 'yagnesh@palvichemical.com',
      phone: '8980804208',
    },
  ],
  hr: [
    {
      image: trupti_panchal,
      name: 'Mrs. Trupti Panchal',
      position: 'Assistant Manager - HR & Admin',
      email: 'hr@palvichemical.com',
      phone: '-',
    },
  ],
};
