import React from 'react';
import { Link } from 'react-router-dom';
import { MdFileDownload } from 'react-icons/md';
import { Container, Row, Col } from 'react-bootstrap';

import '../../style/header.css';
import '../../style/product-page.css';
import '../../style/typography.css';
import '../../style/press-release.css';

const legalNoticeData = [
  'All rights reserved particularly with regard to duplication and translation.',
  'We reserve the right to make modifications at any time.',
  'Technical data on the website are only guidelines and must be confirmed before making any further assumption.',
];

const googleAnalyticsData = [
  `This website uses Google Analytics, a web service of Google Inc. ("Google") Google Analytics uses "cookies," which are text files placed on your computer, to analyze the use of the website by you. The information generated by the cookie about your use of this website (including your IP address) will be sent to a Google server in the U.S. and stored there. Google will use this information to evaluate your use of the website, compiling reports on website activity for website operators and providing other use of the website and Internet services. Google will transmit this information to third parties unless required by law or if third parties process this data on Google's behalf.Google will not associate your IP address with any other data held by Google.You may refuse the use of cookies by selecting the appropriate settings on your browser, we will point out however that you can use in this case not all the features of this site in their entirety.By using this site you agree to the processing of data about you by Google in the manner described and for the aforementioned purpose.`,
];

const PressRelease = () => {
  return (
    <div className="product-main-block">
      {/* <Banner image={productData.banner} title={productData.bannerTitle} /> */}
      <div className="product-details-block">
        <Container>
          <Row>
            <Col
              lg={12}
              data-aos="fade-right"
              data-aos-delay="250"
              data-aos-once="true"
            >
              <div className="product-details-titel-info">
                <div className="product-details-block-title">
                  <h2 className="section-heading-primary">Press Release</h2>
                </div>
                <div className="product-details-block-info">
                  <ul className="press-release-list">
                    <li className="press-release-link para para--dark-grey">
                      <Link
                        to="/downloads/press-release/Form MGT-9 2018-19.pdf "
                        target="_blank"
                        download
                      >
                        <MdFileDownload className="brochure-icon" />
                        Download Form MGT-9 2018-19
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="product-details-titel-info">
                <div className="product-details-block-title">
                  <h2 className="section-heading-primary">Legal Notice</h2>
                </div>
                <div className="product-details-block-info">
                  {legalNoticeData.map((item, index) => {
                    return (
                      <p
                        className="para para--dark-gray"
                        key={`productPara-${index}`}
                      >
                        {item}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="product-details-titel-info">
                <div className="product-details-block-title">
                  <h2 className="section-heading-primary">Google Analytics</h2>
                </div>
                <div className="product-details-block-info">
                  {googleAnalyticsData.map((item, index) => {
                    return (
                      <p
                        className="para para--dark-gray"
                        key={`productPara-${index}`}
                      >
                        {item}
                      </p>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default PressRelease;
