import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Product = (Props) => {
  return (
    <Col xs={6} md={3}>
      <div className="product-data">
        <Card
          className={
            Props.from === 'moly'
              ? 'molybdenum-product'
              : 'international-product'
          }
        >
          <Link to={Props.url}>
            <Card.Img
              variant="top"
              src={Props.img}
              height={Props.from === 'moly' ? '380' : '150'}
              className="zoom"
            />
          </Link>
        </Card>

        <div className="product-card-title">{Props.name}</div>
      </div>
    </Col>
  );
};
export default Product;
