import React from 'react';
import HomeSlider from '../components/Slider.js';
import HomeBusinessUnit from '../components/HomeBusinessUnit.js';
import SinglePara from '../components/SingleParabar';
import Services from '../components/Services';
// import WhyChooseUs from '../components/WhyChooseUs.js';
import HomeReach from '../components/HomeReach';
import Client from '../components/HomeClient.js';
// import BannerPopUp from '../components/BannerPopUp';
import '../style/header.css';

class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <HomeSlider />
        <SinglePara />
        <HomeBusinessUnit />
        <Services />
        <HomeReach />
        {/* <WhyChooseUs /> */}
        <Client />
        {/* <BannerPopUp /> */}
      </div>
    );
  }
}
export default Home;
