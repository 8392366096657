import React from 'react';
import './index.css';

const Heading = (props) => {
  const { text, level } = props;
  return (
    <div className="product-heading-title-block">
      {level ? (
        level === '1' ? (
          <h3 className="section-heading-primary">{text}</h3>
        ) : level === '2' ? (
          <h4 className="section-heading-primary">{text}</h4>
        ) : (
          <h5 className="section-heading-primary">{text}</h5>
        )
      ) : (
        <h2 className="section-heading-primary">{text}</h2>
      )}
    </div>
  );
};

export default Heading;
