import React from 'react';
import { Card } from 'react-bootstrap';
import '../style/banner.css';

class Banner extends React.Component {
  render() {
    return (
      <div className="banner-top">
        <Card className=" text-white" data-aos="zoom-in" data-aos-once="true">
          <img
            className="card-img"
            src={this.props.image}
            alt="card-img"
            height="300"
          />
          {/* <Card.ImgOverlay>
            <Container>
              <Card.Title
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-once="true"
              >
                {this.props.title.split('<BR/>').map((i, index) => {
                  return <p key={`title-${index}`}>{i}</p>;
                })}
              </Card.Title>
            </Container>
          </Card.ImgOverlay> */}
        </Card>
      </div>
    );
  }
}

Banner.defaultProps = {
  title: 'Default Props',
};
export default Banner;
