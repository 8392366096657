import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Gallery from 'react-photo-gallery';

import '../../style/mediagallery.css';

import factory1_4x3 from '../../assests/aboutus/factory/1.jpeg';
import factory2_4x3 from '../../assests/aboutus/factory/2.jpeg';
import factory3_4x3 from '../../assests/aboutus/factory/3.jpeg';
import factory4_4x3 from '../../assests/aboutus/factory/4.jpeg';
import factory5_3x4 from '../../assests/aboutus/factory/5.jpeg';
import factory6_4x3 from '../../assests/aboutus/factory/6.jpeg';
import factory7_3x4 from '../../assests/aboutus/factory/7.jpeg';
import factory8_4x3 from '../../assests/aboutus/factory/8.jpeg';
import factory9_4x3 from '../../assests/aboutus/factory/9.jpeg';
import factory10_3x4 from '../../assests/aboutus/factory/10.jpeg';
import factory11_4x3 from '../../assests/aboutus/factory/11.jpeg';

const PressRelease = () => {
  return (
    <div className="product-main-block">
      <div className="product-details-block">
        <Row>
          <Col
            lg={12}
            data-aos="fade-right"
            data-aos-delay="250"
            data-aos-once="true"
          >
            <div className="product-details-titel-info">
              <Container>
                <div className="product-details-block-title">
                  <h2 className="section-heading-primary">Our Premises</h2>
                </div>
              </Container>
              <div className="media-gallery-photos">
                <Gallery photos={photos} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const photos = [
  {
    src: factory1_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory2_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory3_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory4_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory5_3x4,
    width: 3,
    height: 4,
  },
  {
    src: factory6_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory7_3x4,
    width: 3,
    height: 4,
  },
  {
    src: factory8_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory9_4x3,
    width: 4,
    height: 3,
  },
  {
    src: factory10_3x4,
    width: 3,
    height: 4,
  },
  {
    src: factory11_4x3,
    width: 4,
    height: 3,
  },
];

export default PressRelease;
