import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ContactUsForm from '../components/ContactUsForm/index';
import '../style/contact.css';
class Contact extends React.Component {
  render() {
    return (
      <div className="contact">
        <Container>
          <Row>
            <Col
              lg={8}
              data-aos="fade-right"
              data-aos-delay="250"
              data-aos-once="true"
            >
              <div className="contact-main-block">
                <ContactUsForm />
              </div>
            </Col>
            <Col
              lg={4}
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-once="true"
            >
              <div className="contact-get-in-touch">
                <h2>Contact Us</h2>
                <div className="number-blcok">
                  <h5>CONTACT NUMBER</h5>
                  <p>+91-265-2796401</p>
                </div>
                <div className="office-blcok">
                  <h5>CORPORATE OFFICE</h5>
                  <p>
                    315 Aditviya Complex <br />
                    Near Deluxe Cross Road <br />
                    Nizampura, Vadodara 390 002 <br />
                    India
                  </p>
                </div>
                <div className="email-blcok">
                  <h5>EMAIL</h5>
                  <p>
                    <a href="mailto: info@palvichemical.com">
                      info@palvichemical.com
                    </a>
                  </p>
                </div>
                <div className="email-blcok">
                  <h5>OTHER WEBSITES</h5>
                  <p>
                    <a
                      href="https://www.palvifze.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Palvi Power Tech Sales FZE
                    </a>
                    <br />
                    <a
                      href="https://www.palvimasterbatches.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Palvi Masterbatches
                    </a>
                  </p>
                </div>
                {/* <Link to="/about">
                  <Button className="btn-medium btn-blue">ABOUT US</Button>
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Contact;
