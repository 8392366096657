import testTube from '../assests/slider/slider1.jpg';
import blackMasterbatch from '../assests/slider/slider2.jpg';
import presidentImage from '../assests/slider/slider3.jpg';

const content = [
  {
    title: 'Leaders In Chemical Distribution And Manufacturing',
    description:
      'Palvi Industries Ltd is a 2 star export house recognized chemical material distribution and marketing company with over 30 years of experience.',
    image: testTube,
  },
  {
    title: 'Recognized By FIEO (Federation of Indian Export Organizations)',
    description:
      'We have been awarded Niryat Shree Bronze Trophy by FIEO, SME1* rating from CRISIL and several other accolades from reputed organizations allowing us to extend our footprint across Asia, America, Europe and Middle East.',
    image: presidentImage,
  },
  {
    title: 'Molybdenum Derivatives & Specialty Masterbatches',
    description:
      'Renowned manufacturers of Black Masterbatches with PP, PE, PC, PS, PVC, SAN, ABS and Nylon polymers.',
    image: blackMasterbatch,
  },
];

export default content;
