import React from 'react';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { meta } from '../data/meta';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING, {
  debug: true,
});
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      ReactGA.pageview(
        this.props.location.pathname + this.props.location.search,
      );
      window.scrollTo(0, 0);
    }
  }

  render() {
    const metaData = meta.find(
      (obj) => obj.route === this.props.location.pathname,
    );
    return (
      <>
        <Helmet>
          <title>{metaData ? metaData.title : ''}</title>
          <meta
            name="description"
            content={`${metaData ? metaData.description : ''}`}
          />
        </Helmet>
        {this.props.children}
      </>
    );
  }
}

export default withRouter(ScrollToTop);
