import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Row, Col, Container } from 'react-bootstrap';
import Banner from '../../components/banner';
import { allProducts } from '../../data/ProductPage';
import Product from '../../components/Product';

class ProductPage extends React.Component {
  render() {
    return (
      <div className="all-product-page">
        <Banner
          image={allProducts.products.banner}
          title={allProducts.products.bannerTitle}
        />

        <div className="product-details-block">
          <Container>
            <Row>
              <Col
                lg={8}
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-details-titel-info">
                  <div className="product-details-block-title">
                    <h2 className="section-heading-primary">
                      {allProducts.products.title}
                    </h2>
                  </div>
                  <div className="product-details-block-info">
                    {allProducts.products.information.map((item, index) => {
                      return <p key={`productMainPara-${index}`}>{item}</p>;
                    })}
                  </div>
                </div>
              </Col>
              <Col
                lg={4}
                data-aos="fade-left"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-category-main-block">
                  <div className="product-category">
                    <div className="product-category-title">
                      <h2>by industry</h2>
                    </div>
                    <ul>
                      {allProducts.products.industryCategories.map(
                        (item, index) => {
                          return (
                            <li key={`industryCategories-${index}`}>
                              <NavLink
                                exact
                                activeClassName="active"
                                className="nav-link"
                                to={item.url}
                              >
                                {item.name}
                              </NavLink>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>
                  <div className="product-category manufacture category ">
                    <div className="product-category-title">
                      <h2>manufactured by us</h2>
                    </div>
                    <ul>
                      {allProducts.products.manufacturedCategories.map(
                        (item, index) => {
                          return (
                            <li key={`manufacture-cat-${index}`}>
                              <Link
                                className="nav-link"
                                to={`/products/${item.url}`}
                              >
                                {item.name}
                              </Link>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <div
              className="product-card-block"
              data-aos="fade-up"
              data-aos-delay="250"
              data-aos-once="true"
              data-aos-anchor=".product-details-block"
            >
              <Row>
                {allProducts.products.mainCategories.map((prop, index) => {
                  return <Product key={`mainCategories-${index}`} {...prop} />;
                })}
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default ProductPage;
