import React from 'react';
import { Col, Card } from 'react-bootstrap';
import '../style/productInfo2.css';
import { Link } from 'react-router-dom';

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
  }

  render() {
    return (
      <Col sm={6}>
        <div className="buss-unit-product-flex-container">
          <img
            style={{ height: '250px', width: '40%', objectFit: 'cover' }}
            alt=""
            src={this.props.img}
          />
          <Card
            className={`home-product-card home-product-card-${this.props.productIndex}`}
            style={{ height: '250px' }}
          >
            <Card.Body>
              <Card.Title className="overlay-title">
                <Link to={this.props.url}>{this.props.headLine}</Link>
              </Card.Title>
              <Card.Text className="overlay-des-2">
                {this.props.description}
              </Card.Text>
              <div className="product-read-more">
                <p>
                  <Link to={this.props.url}>+ Read More</Link>
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Col>
    );
  }
}

// <Col lg={3} className="product-info-block">
//   <div className="product-info-image">
//     <img src={Props.img} alt="img" height="200" width="300" />
//   </div>
//   <div className="product-info-des">
//     <h3>{Props.headLine}</h3>
//     <p>{Props.description}</p>
//     <div className="product-read-more">
//       <p>+ Read More</p>
//     </div>
//   </div>
// </Col>
export default ProductInfo;
