import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GetQuotaButton from '../components/GetQuotaButton';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { products } from '../data/searchProduct';
import Banner from '../components/banner';
import pro_banner from '../assests/products/banner/products.jpg';
const { SearchBar } = Search;

class ProductSearch extends React.Component {
  state = {
    columns: [
      {
        dataField: 'id',
        text: 'Product ID',
      },
      {
        dataField: 'name',
        text: 'Product Name',
      },
      {
        dataField: 'industry',
        text: 'Industry',
      },
      {
        dataField: 'application',
        text: 'Applications',
      },
      {
        dataField: 'grade',
        text: 'Grade',
      },
      {
        dataField: 'packingDetails',
        text: 'Packing Details',
      },
      {
        dataField: 'actions',
        text: 'Actions',
        isDummyField: true,
        formatter: (cell, row) => {
          return (
            <GetQuotaButton
              btnSize="small"
              productList={[{ name: row.name }]}
            />
          );
        },
      },
    ],
    products: products
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map((item, index) => ({ id: index + 1, ...item })),
  };

  render() {
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      totalSize: this.state.products.length,
      hideSizePerPage: false,
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <Container>
        <ToolkitProvider
          keyField="id"
          columns={this.state.columns}
          data={this.state.products}
          search
        >
          {(toolkitprops) => (
            <div>
              <SearchBar {...toolkitprops.searchProps} />

              <BootstrapTable
                striped
                hover
                {...toolkitprops.baseProps}
                {...paginationTableProps}
              />
            </div>
          )}
        </ToolkitProvider>
        <PaginationListStandalone {...paginationProps} />
      </Container>
    );

    return (
      <div className="all-product-page">
        <Banner image={pro_banner} title="Search Product" />
        <div
          className="product-details-block"
          data-aos="fade-right"
          data-aos-delay="250"
          data-aos-once="true"
        >
          <Container>
            <Row>
              <Col lg={8}>
                <div className="product-details-titel-info">
                  <div className="product-details-block-title">
                    <h2 className="section-heading-primary">Search Products</h2>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        </div>
      </div>
    );
  }
}
export default ProductSearch;
