import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { withFirebase } from '../../Firebase';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import '../../style/contactUsForm.css';
import { countryList } from '../../data/countryList';

const DownloadForm = props => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    downloadLoc,
  } = props;
  const country_list = countryList.map(country => ({
    value: country,
    text: country,
  }));

  return (
    <div className="container-contactus">
      <h2>Personal Details</h2>
      <form className="contactUsForm">
        <div className="form-group-contactus">
          <input
            className={
              errors.firstName && touched.firstName
                ? 'input-error-contactus'
                : 'input-contactus'
            }
            name="firstName"
            value={values.firstName || ''}
            onChange={handleChange}
            type="text"
            placeholder="First Name*"
            size="large"
            onBlur={handleBlur}
          />
          {errors.firstName && touched.firstName && (
            <div className="invalid-feedback-contactus">{errors.firstName}</div>
          )}
        </div>
        <div className="form-group-contactus">
          <input
            className={
              errors.lastName && touched.lastName
                ? 'input-error-contactus'
                : 'input-contactus'
            }
            name="lastName"
            value={values.lastName || ''}
            onChange={handleChange}
            type="text"
            placeholder="Last Name"
            size="large"
            onBlur={handleBlur}
          />
          {errors.lastName && touched.lastName && (
            <div className="invalid-feedback-contactus">{errors.lastName}</div>
          )}
        </div>
        <div className="form-group-contactus">
          <input
            className={
              errors.email && touched.email
                ? 'input-error-contactus'
                : 'input-contactus'
            }
            name="email"
            value={values.email || ''}
            onChange={handleChange}
            type="text"
            placeholder="Email Address*"
            size="large"
            onBlur={handleBlur}
          />
          {errors.email && touched.email && (
            <div className="invalid-feedback-contactus">{errors.email}</div>
          )}
        </div>
        <div className="form-group-contactus">
          <input
            className={
              errors.companyName && touched.companyName
                ? 'input-error-contactus'
                : 'input-contactus'
            }
            name="companyName"
            value={values.companyName || ''}
            onChange={handleChange}
            type="text"
            placeholder="Company Name*"
            size="large"
            onBlur={handleBlur}
          />
          {errors.companyName && touched.companyName && (
            <div className="invalid-feedback-contactus">
              {errors.companyName}
            </div>
          )}
        </div>
        <div className="form-group-contactus">
          <input
            className={
              errors.phoneNumber && touched.phoneNumber
                ? 'input-error-contactus'
                : 'input-contactus'
            }
            name="phoneNumber"
            value={values.phoneNumber || ''}
            onChange={handleChange}
            type="text"
            placeholder="Phone Number*"
            size="large"
            onBlur={handleBlur}
          />
          {errors.phoneNumber && touched.phoneNumber && (
            <div className="invalid-feedback-contactus">
              {errors.phoneNumber}
            </div>
          )}
        </div>
        <div className="form-group-contactus">
          <select
            className={
              errors.country && touched.country
                ? 'input-error-contactus'
                : 'input-contactus'
            }
            name="country"
            value={values.country || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select Country*</option>
            {country_list.map(({ value, text }) => (
              <option value={value}>{text}</option>
            ))}
          </select>
          {errors.country && touched.country && (
            <div className="invalid-feedback-contactus">{errors.country}</div>
          )}
        </div>
        <div className="form-group-contactus">
          <button
            disabled={isSubmitting ? true : false}
            className="btn-blue btn-medium"
            type="primary"
            onClick={handleSubmit}
            size="large"
            loading={isSubmitting}
          >
            {!isSubmitting ? 'Get Download Link' : 'Please Wait...'}
          </button>

          {errors.apiError && (
            <div className="invalid-feedback-contactus">{errors.apiError}</div>
          )}
        </div>
        <div className="form-group-contactus">
          {errors.downloadFile ? (
            <Button
              className="btn-blue btn-medium"
              href={downloadLoc}
              target="_blank"
              download
            >
              Download File
            </Button>
          ) : (
            ''
          )}
        </div>
      </form>
    </div>
  );
};

const contactusWithFormik = withFormik({
  mapPropsToValues: props => ({
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    companyName: props.companyName,
    phoneNumber: props.phoneNumber,
    country: props.country,
  }),

  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required.')
      .max(50, 'Name must be less than 50 characters.'),
    lastName: Yup.string().max(50, 'Name must be less than 50 characters.'),
    email: Yup.string()
      .email('Invalid email address.')
      .required('Email is required.'),
    companyName: Yup.string()
      .required('Company name is required.')
      .max(50, 'Company name must be less than 50 characters.'),
    phoneNumber: Yup.string().required('Phone numeber is required.'),
    country: Yup.string().required('Country is required.'),
  }),

  handleSubmit: async (
    values,
    { props, setSubmitting, setErrors, resetForm },
  ) => {
    const text = `
    <html>
    <head>
    <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 50%;
    }

    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }

    </style>
    </head>
    <body>
    <h2>Client Details</h2>
    <table>
      <tr>
        <td>First Name</td>
        <td>${values.firstName}</td>
      </tr>
      <tr>
        <td>Last Name</td>
        <td>${values.lastName}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>${values.email}</td>
      </tr>
      <tr>
        <td>Company Name</td>
        <td>${values.companyName}</td>
      </tr>
      <tr>
        <td>Phone Number</td>
        <td>${values.phoneNumber}</td>
      </tr>
      <tr>
        <td>Country</td>
        <td>${values.country ? values.country : ''}</td>
      </tr>
    </table>
    </body>
</html>
    `;
    try {
      const response = await axios.post(
        'https://backend-api24.herokuapp.com/sendemail',
        {
          fromName: 'Palvi Chemicals',
          fromEmail: 'info@palvichemical.com',
          toName: 'Alay Mehta',
          toEmail: 'alay@palvichemical.com',
          subject: 'Website - Download - Molybdenum',
          text,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.status !== 200) {
        setErrors({ apiError: response.message });
        setSubmitting(false);
      } else {
        setErrors({ downloadFile: true });
        setSubmitting(false);
      }
    } catch (error) {
      setErrors({ apiError: error.message });
      setSubmitting(false);
    }
  },
})(DownloadForm);

export default withFirebase(contactusWithFormik);
