import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import '../style/homeReach.css';

const countries = {
  africa: [
    'Egypt',
    'Kenya',
    'Uganda',
    'Ethiopia',
    'Zimbabwe',
    'Tanzania',
    'South Africa',
  ],
  asia: [
    'Bangladesh',
    'Bahrain',
    'UAE',
    'Qatar',
    'Yemen',
    'Saudi Arabia',
    'Russia',
    'Philippines',
    'Sri Lanka',
    'Vietnam',
  ],
  europe: ['UK', 'Greece'],
  northAmerica: [
    'USA',
    'Canada',
    'Costa Rica',
    'Mexico',
    'Panama',
    'Honduras',
    'Guatemala',
  ],
  SouthAmerica: [
    'Brazil',
    'Colombia',
    'Argentina',
    'Bolivia',
    'Chile',
    'Ecuador',
    'Paraguay',
    'Peru',
    'Uruguay',
    'Venezuela',
  ],
  Australia: ['Australia'],
};

const list = [];

Object.keys(countries).forEach((cont) => {
  countries[cont]
    .sort((a, b) => a > b)
    .forEach((country) => list.push(country));
});

const HomeReach = () => (
  <div>
    <div className="reach-container" data-aos="fade-right" data-aos-once="true">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="reach-ul-container">
              <h2>Our Reach</h2>
              <div className="reach-categories">
                <ul>
                  {list.map((country) => (
                    <li>{country}</li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="service-data" />
  </div>
);

export default HomeReach;
