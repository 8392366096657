import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import Banner from '../../../components/banner';
import allProducts from '../../../data/ProductPage';
import Product from '../../../components/Product';

import '../../../style/header.css';
import '../../../style/product-page.css';
// import ResponsiveTabs from '../components/responsiveTabs';
import '../../../style/typography.css';

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = allProducts;
  }
  render() {
    const productData = this.state[this.props.productType];
    return (
      <div className="product-main-block">
        <Banner image={productData.banner} title={productData.bannerTitle} />
        <div className="product-details-block">
          <Container>
            <Row>
              <Col
                lg={8}
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-details-titel-info">
                  <div className="product-details-block-title">
                    <h2 className="section-heading-primary">
                      {productData.title}
                    </h2>
                  </div>
                  <div className="product-details-block-info">
                    {productData.information.map((item, index) => {
                      return (
                        <p
                          className="para para--dark-gray"
                          key={`productPara-${index}`}
                        >
                          {item}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col
                lg={4}
                data-aos="fade-left"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-category-main-block">
                  <div className="product-category">
                    <div className="product-category-title">
                      <h2>categories</h2>
                    </div>
                    <ul>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products"
                        >
                          all products
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/international"
                        >
                          export
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/masterbatches"
                        >
                          masterbatches
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/molybdenum"
                        >
                          molybdenum
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/acid"
                        >
                          acid
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/toll"
                        >
                          toll
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="product-card-block"
          data-aos="fade-up"
          data-aos-delay="250"
          data-aos-once="true"
          data-aos-anchor=".product-details-block"
        >
          <Container>
            <Row>
              {productData.produts.map((prop, index) => {
                return (
                  <Product
                    key={`products-${index}`}
                    {...prop}
                    from={this.props.productType === 'molybdenum' ? 'moly' : ''}
                  />
                );
              })}
            </Row>
          </Container>
        </div>
        {/* {this.props.productType === 'molybdenum' ? (
          <div
            className="product-card-block"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-once="true"
            data-aos-anchor=".product-details-block"
          >
            <Container>
              <Row>
                <Col lg={12}>
                  <ResponsiveTabs />
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          ''
        )} */}
      </div>
    );
  }
}
export default Products;
