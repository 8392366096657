import React from 'react';

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import 'normalize.css/normalize.css';
import '../style/slider-animation.css';
import '../style/slider.css';
import '../style/typography.css';

import { MdExpandMore } from 'react-icons/md';
import { scroller } from 'react-scroll';

import content from '../data/slider';

class HomeSlider extends React.Component {
  render() {
    return (
      <div>
        <Slider className="slider-wrapper" nextButton="22222" autoplay="3000">
          {content.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${item.image}') no-repeat center center`,
              }}
            >
              <div className="inner inner-center">
                <div className="inner-heading-block">
                  <h1 className="slider-section-heading">{item.title}</h1>
                  <p className="para para--white">{item.description}</p>
                  {item.button && <button>{item.button}</button>}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="scroll-down-block">
          <a
            href={false}
            onClick={() => {
              scroller.scrollTo('home-business-section', {
                duration: 1500,
                delay: 100,
                smooth: true,
                // offset: -145, // Scrolls to element + 50 pixels down the page
              });
            }}
          >
            <span className="scroll-down">
              <div className="scroll-down-box bounce-1">
                <MdExpandMore size={50} />
              </div>
            </span>
          </a>
        </div>
      </div>
    );
  }
}

export default HomeSlider;
