import React from 'react';
import { NavLink } from 'react-router-dom';
import { MdFileDownload } from 'react-icons/md';
import Gallery from 'react-photo-gallery';

import '../style/product-child-masterbatch.css';
import { Row, Col, Container, Button } from 'react-bootstrap';
import factory1_4x3 from '../assests/csr/activity/1.jpg';
import factory2_4x3 from '../assests/csr/activity/2.jpg';
import factory3_4x3 from '../assests/csr/activity/3.jpg';
import factory4_4x3 from '../assests/csr/activity/4.jpg';
import factory5_3x4 from '../assests/csr/activity/5.jpg';
import factory6_4x3 from '../assests/csr/activity/6.jpg';
import factory7_3x4 from '../assests/csr/activity/7.jpg';
import factory8_4x3 from '../assests/csr/activity/8.jpg';
import factory9_4x3 from '../assests/csr/activity/9.jpg';
import factory10_3x4 from '../assests/csr/activity/10.jpg';
import factory11_4x3 from '../assests/csr/activity/11.jpg';
import factory12_4x3 from '../assests/csr/activity/12.jpg';

import ParaWithPhoto from './Product/ProductCategory/Acid/Components/ParaWithPhoto';
import Heading from './Product/ProductCategory/Acid/Components/Heading';
import List from './Product/ProductCategory/Acid/Components/List';
import { csrSectionDetails } from '../data/ProductPage';
class Csr extends React.Component {
  render() {
    const pageId = this.props.match.params.id || 'composition';
    const product = csrSectionDetails[pageId];
    return (
      <div className="product-main-block">
        <div className="product-details-block">
          <Container>
            <Row>
              <Col
                lg={9}
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-details-sub-block">
                  {product.content.map((contentObj) => {
                    let a;
                    switch (contentObj.type) {
                      case 'heading':
                        a = <Heading text={contentObj.text} />;
                        break;

                      case 'heading-sub-1':
                        a = <Heading text={contentObj.text} level="1" />;
                        break;

                      case 'heading-sub-2':
                        a = <Heading text={contentObj.text} level="2" />;
                        break;

                      case 'heading-sub-3':
                        a = <Heading text={contentObj.text} level="3" />;
                        break;

                      case 'paraWithPhoto':
                        a = (
                          <ParaWithPhoto
                            image={contentObj.data.image}
                            text={contentObj.data.information}
                          />
                        );
                        break;

                      case 'list':
                        a = (
                          <List
                            title={contentObj.data.title}
                            items={contentObj.data.content}
                          />
                        );
                        break;

                      case 'gallery':
                        a = <Gallery photos={contentObj.data.photos} />;
                        break;

                      case 'download-button':
                        a = (
                          <Button
                            className="btn-blue btn-medium brochure-button"
                            href={contentObj.data.ref}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MdFileDownload className="brochure-icon" />
                            {contentObj.data.name}
                          </Button>
                        );
                        break;

                      default:
                        break;
                    }
                    return a;
                  })}
                </div>
              </Col>
              <Col
                lg={3}
                data-aos="fade-left"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-category-main-block">
                  <div className="product-category">
                    <div className="product-category-title">
                      <h2>Sections</h2>
                    </div>
                    <ul>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/csr/composition"
                        >
                          Composition
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/csr/board-approval"
                        >
                          Boards Approval
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/csr/scope-of-activities"
                        >
                          Scope of activites
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/csr/implementation-of-activities"
                        >
                          Implementation of activities
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/csr/statutory-compliance"
                        >
                          Statutory compliance
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const photos = [
  {
    src: factory1_4x3,
    width: 4,
    height: 2,
  },
  {
    src: factory2_4x3,
    width: 4,
    height: 2,
  },
  {
    src: factory3_4x3,
    width: 4,
    height: 2,
  },
  {
    src: factory4_4x3,
    width: 4,
    height: 2,
  },
  {
    src: factory5_3x4,
    width: 4,
    height: 2,
  },
  {
    src: factory6_4x3,
    width: 4,
    height: 2,
  },
  {
    src: factory7_3x4,
    width: 4,
    height: 2,
  },
  {
    src: factory8_4x3,
    width: 4,
    height: 2,
  },
  {
    src: factory9_4x3,
    width: 4,
    height: 2,
  },
  {
    src: factory10_3x4,
    width: 4,
    height: 2,
  },
  {
    src: factory11_4x3,
    width: 4,
    height: 2,
  },
  {
    src: factory12_4x3,
    width: 4,
    height: 2,
  },
];
export default Csr;
