import chuttersnap from '../assests/chuttersnap-kyCNGGKCvyw-unsplash-421x228.jpg';
import carbon_nanotube_masterbatch from '../assests/carbon_nanotube_masterbatch-421x228.jpg';
import cement from '../assests/cement-banner.jpg';
import pro_card_acid from '../assests/products/card/4acid.jpg';
import pro_card_toll from '../assests/products/card/5toll.jpg';

const Products = [
  {
    img: chuttersnap,
    type: 'internation',
    headLine: 'export',
    description:
      'We source and export a wide range of chemicals to more than five continents, covering more than 50 countries.',
    url: '/products/international',
  },
  {
    img: carbon_nanotube_masterbatch,
    type: 'masterbatches',
    headLine: 'masterbatches',
    description:
      'Palvi Chemicals Pvt Ltd has an dedicated plant for Manufacturing of Master Batches & Additives.',
    url: '/products/masterbatches',
  },
  {
    img: cement,
    type: 'molybdenum',
    headLine: 'molybdenum',
    description:
      'The company has commissioned a plant to manufacture of Molybdenum Chemicals using the latest technology.',
    url: '/products/molybdenum',
  },
  {
    img: pro_card_acid,
    type: 'acid',
    headLine: 'acid',
    description:
      'Palvi Chemicals Pvt Ltd has started manufacturing, purification and repacking of Industrial acids.',
    url: '/products/acid',
  },
  {
    img: pro_card_toll,
    type: 'toll',
    headLine: 'toll manufactured products',
    description:
      'Palvi Industries Limited has started production of Sodium Nitrate and Optical Brightening Agents.',
    url: '/products/toll',
  },
];

export default Products;
