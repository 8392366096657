import React from 'react';
import axios from 'axios';
import { withFormik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { withFirebase } from '../../Firebase';
import '../../style/getQuoteForm.css';
import { countryList } from '../../data/countryList';

const templateData = {
  product: '',
  quantity: 0,
};

const { product, quantity } = templateData;

const GetQuoteForm = (props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    productList,
  } = props;
  const product_list = productList.map((obj) => ({
    value: obj.name,
    text: obj.name,
  }));
  const country_list = countryList.map((country) => ({
    value: country,
    text: country,
  }));

  return (
    <div>
      <h1>GET QUOTE</h1>
      <form>
        <div className="getquote-input-flex-container">
          <div className="getquote-input-error-group">
            <input
              className={
                errors.name && touched.name
                  ? 'getquote-input-error'
                  : 'getquote-input'
              }
              name="name"
              value={values.name || ''}
              onChange={handleChange}
              type="text"
              placeholder="Name*"
              size="large"
              onBlur={handleBlur}
            />
            {errors.name && touched.name && (
              <div className="invalid-feedback-getquote">{errors.name}</div>
            )}
          </div>
          <div className="getquote-input-error-group">
            <input
              className={
                errors.email && touched.email
                  ? 'getquote-input-error'
                  : 'getquote-input'
              }
              name="email"
              value={values.email || ''}
              onChange={handleChange}
              type="text"
              placeholder="Email Address*"
              size="large"
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <div className="invalid-feedback-getquote">{errors.email}</div>
            )}
          </div>
          <div className="getquote-input-error-group">
            <input
              className={
                errors.companyName && touched.companyName
                  ? 'getquote-input-error'
                  : 'getquote-input'
              }
              name="companyName"
              value={values.companyName || ''}
              onChange={handleChange}
              type="text"
              placeholder="Company Name*"
              size="large"
              onBlur={handleBlur}
            />
            {errors.companyName && touched.companyName && (
              <div className="invalid-feedback-getquote">
                {errors.companyName}
              </div>
            )}
          </div>
          <div className="getquote-input-error-group">
            <select
              className={
                errors.country && touched.country
                  ? 'getquote-input-error'
                  : 'getquote-input'
              }
              name="country"
              value={values.country || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select Country*</option>
              {country_list.map(({ value, text }) => (
                <option value={value}>{text}</option>
              ))}
            </select>
            {errors.country && touched.country && (
              <div className="invalid-feedback-getquote">{errors.country}</div>
            )}
          </div>
        </div>
        <FieldArray
          name="entries"
          render={({ insert, remove, push, replace }) => (
            <table>
              <tr className="row">
                <td className="col">Product</td>
                <td className="col">Quantity</td>
                <td className="col"></td>
                <td className="col"></td>
                <td className="col"></td>
              </tr>
              {values.entries &&
                values.entries.length > 0 &&
                values.entries.map((other, index) => (
                  <tr className="row" key={index}>
                    <td className="col">
                      <div className="getquote-input-error-group">
                        <Field
                          className={
                            touched.entries &&
                            touched.entries[index] &&
                            touched.entries[index].product &&
                            errors.entries &&
                            errors.entries[index] &&
                            errors.entries[index].product
                              ? 'getquote-input-error'
                              : 'getquote-input'
                          }
                          name={`entries.${index}.product`}
                          id={`entries.${index}.product`}
                          component="select"
                          validate={validateProduct}
                        >
                          <option value="Select Product">Select Product</option>
                          {product_list.map(({ value, text }) => (
                            <option value={value}>{text}</option>
                          ))}
                        </Field>
                        {touched.entries &&
                          touched.entries[index] &&
                          touched.entries[index].product &&
                          errors.entries &&
                          errors.entries[index] &&
                          errors.entries[index].product && (
                            <div className="invalid-feedback-getquote">
                              {errors.entries[index].product}
                            </div>
                          )}
                      </div>
                    </td>

                    <td className="col">
                      <div className="getquote-input-error-group">
                        <Field
                          className={
                            touched.entries &&
                            touched.entries[index] &&
                            touched.entries[index].quantity &&
                            errors.entries &&
                            errors.entries[index] &&
                            errors.entries[index].quantity
                              ? 'getquote-input-quantity-error'
                              : 'getquote-input-quantity'
                          }
                          name={`entries.${index}.quantity`}
                          id={`entries.${index}.quantity`}
                          type="number"
                        />
                        {touched.entries &&
                          touched.entries[index] &&
                          touched.entries[index].quantity &&
                          errors.entries &&
                          errors.entries[index] &&
                          errors.entries[index].quantity && (
                            <div className="invalid-feedback-getquote">
                              {errors.entries[index].quantity}
                            </div>
                          )}
                      </div>
                    </td>

                    <td className="col">
                      <div>Metric Tons</div>
                    </td>

                    <td className="col">
                      {values.entries.length === index + 1 ? (
                        <button
                          type="button"
                          className="getquote-add-product-button"
                          onClick={async () => {
                            await push({});
                          }}
                        >
                          Add Product
                        </button>
                      ) : (
                        ''
                      )}
                    </td>

                    <td className="col">
                      <button
                        type="button"
                        className="secondary"
                        onClick={() =>
                          values.entries.length > 1
                            ? remove(index)
                            : replace(index, templateData)
                        }
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          )}
        />
        <div className="getquote-container getquote-input-error-group">
          <textarea
            className={`message-textarea-getquote ${
              errors.message && touched.message
                ? 'getquote-input-error'
                : 'getquote-input'
            }`}
            name="message"
            value={values.message || ''}
            onChange={handleChange}
            type="text"
            placeholder="Message"
            size="large"
            onBlur={handleBlur}
          />
          {errors.message && touched.message && (
            <div className="invalid-feedback-getquote">{errors.message}</div>
          )}
        </div>
        <div className="form-group-getquote">
          <button
            disabled={isSubmitting ? true : false}
            className="btn-blue btn-medium"
            type="primary"
            onClick={handleSubmit}
            size="large"
            loading={isSubmitting}
          >
            {!isSubmitting ? 'Send Request' : 'Please Wait...'}
          </button>

          {errors.apiError && (
            <div className="invalid-feedback-getquote">{errors.apiError}</div>
          )}
        </div>
      </form>
    </div>
  );
};

const contactusWithFormik = withFormik({
  mapPropsToValues: (props) => ({
    name: props.name,
    email: props.email,
    companyName: props.companyName,
    entries: [{ product, quantity }],
    country: props.country,
    message: props.message,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('Name is required.')
      .max(50, 'Name must be less than 50 characters.'),
    email: Yup.string()
      .email('Invalid email address.')
      .required('Email is required.'),
    companyName: Yup.string()
      .required('Company name is required.')
      .max(50, 'Company name must be less than 50 characters.'),
    country: Yup.string().required('Country is required.'),
    message: Yup.string().max(
      1000,
      'Message must be less than 1000 characters.',
    ),
    entries: Yup.array()
      .of(
        Yup.object().shape({
          product: Yup.string().required('Product field must be selected.'),
          quantity: Yup.number().required('Quantity must be entered.'),
        }),
      )
      .required('At least one product is required.'),
  }),

  handleSubmit: async (
    values,
    { props, setSubmitting, setErrors, resetForm },
  ) => {
    try {
      const text = `
    <html>
    <head>
    <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 50%;
    }

    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }

    </style>
    </head>
    <body>
    <h2>Client Details</h2>
    <table>
      <tr>
        <td>Name</td>
        <td>${values.name}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>${values.email}</td>
      </tr>
      <tr>
        <td>Company Name</td>
        <td>${values.companyName}</td>
      </tr>
      <tr>
        <td>Country</td>
        <td>${values.country}</td>
      </tr>
      <tr>
        <td>Message</td>
        <td>${values.message ? values.message : ''}</td>
      </tr>
    </table>
    <br/>
    <h2>Products</h2>
    <table>
      ${values.entries
        .map(
          (entry) =>
            `<tr><td>${entry.product}</td><td>${entry.quantity} Metric Tons</td></tr>`,
        )
        .join('')}
    </table>
    </body>
</html>
    `;

      const response = await axios.post(
        'https://backend-api24.herokuapp.com/sendemail',
        {
          fromName: 'Palvi Chemicals',
          fromEmail: 'info@palvichemical.com',
          toName: 'Alay Mehta',
          toEmail: 'alay@palvichemical.com',
          subject: 'Website - Product - Query',
          text,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.status !== 200) {
        setErrors({ apiError: response.message });
        setSubmitting(false);
      } else {
        props.onSuccess();
        resetForm({});
        setSubmitting(false);
      }
    } catch (error) {
      setErrors({ apiError: error.message });
      setSubmitting(false);
    }
  },
})(GetQuoteForm);

const validateProduct = (value) => {
  let error;
  if (value === 'Select Product') {
    error = 'Please select a product.';
  }
  return error;
};

export default withFirebase(contactusWithFormik);
