import { createStore, applyMiddleware, compose } from 'redux';
import Reducer from './reducer/index';
import thunk from 'redux-thunk';
const initializeState = {};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(Reducer, initializeState, enhancer);

export default store;
