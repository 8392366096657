import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../style/homeBusinessUnit.css';
// import ProductInfo from '../components/ProductInfo';
import ProductInfo2 from '../components/ProductInfo2';
import Products from '../data/homeProduct';
import GetQuotaButton from '../components/GetQuotaButton';
import { Element } from 'react-scroll';

const HomeBusinessUnit = (Props) => {
  return (
    <Element name="home-business-section" className="home-business-section">
      <div className="home-business-unit">
        <Container>
          <div className="indsutris-block">
            <div className="home-business-headers">
              <Row>
                <Col sm={8}>
                  <Row>
                    <div
                      className="unit-left-header"
                      data-aos="fade-right"
                      data-aos-once="true"
                    >
                      <h2>Industries we serve globally</h2>
                      <span>Explore our business units</span>
                    </div>
                  </Row>
                </Col>
                <Col sm={4} data-aos="fade-left" data-aos-once="true">
                  <GetQuotaButton position="right" withoutProduct={true} />
                </Col>
              </Row>
            </div>

            <div
              className="unit-blocks"
              data-aos="fade-up"
              data-aos-once="true"
            >
              {/* <Row>
                {Products.map((item, index) => (
                  <ProductInfo
                    productIndex={index}
                    key={`product-${index}`}
                    {...item}
                  />
                ))}
              </Row> */}
              <Row>
                {Products.map((item, index) => (
                  <ProductInfo2
                    productIndex={index}
                    key={`product-${index}`}
                    {...item}
                  />
                ))}
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </Element>
  );
};

export default HomeBusinessUnit;
