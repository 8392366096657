import React from 'react';

import { NavLink } from 'react-router-dom';

const Page404 = ({ location }) => (
  <div>
    <div
      className="page-wrap d-flex flex-row align-items-center"
      style={{ height: '500px', padding: '300px' }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">404</span>
            <div className="mb-4 lead">
              The page you are looking for was not found.
            </div>

            <NavLink to="/">Back to Home</NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Page404;
