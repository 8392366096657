import React from 'react';
import { Container } from 'react-bootstrap';
import '../style/homeClient.css';
import '../style/typography.css';

import brenntag from '../assests/client/brenntag.png';
import generalElectric from '../assests/client/generalElectricLogo.png';
import asahi from '../assests/client/asahi.jpg';
import birla from '../assests/client/birla.jpg';
import sudarshan from '../assests/client/sudarshan-logo.svg';
import dksh from '../assests/client/dksh.svg';
import bidco from '../assests/client/bidco.png';
import metl from '../assests/client/metl.png';
import suez from '../assests/client/suez.png';
import norkem from '../assests/client/norkem.jpg';

const Client = (Props) => {
  return (
    <div>
      <Container>
        <div className="client-main-block">
          <center>
            <div
              className="client-header"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <h2>our clients</h2>
            </div>
            <div
              className="middel-text"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <p className="para para--dark-gray para--medium">
                <span>
                  With quality delivered, we have built strong partnerships with
                  our clients across various industries.
                </span>
                <span>
                  Our core values emulate customer first approach with trust and
                  reliability for all segments.
                </span>
              </p>
            </div>
            <div className="client-logo">
              <div
                className="first-logo"
                data-aos="flip-up"
                data-aos-once="true"
              >
                <img src={brenntag} alt="img" />
                <img src={generalElectric} alt="img" />
                <img src={asahi} alt="img" />
                <img src={birla} alt="img" />
                <img src={sudarshan} alt="img" />
              </div>
              <div
                className="last-logo"
                data-aos="flip-up"
                data-aos-once="true"
              >
                <img src={dksh} alt="img" />
                <img src={bidco} alt="img" />
                <img src={metl} alt="img" />
                <img src={suez} alt="img" />
                <img src={norkem} alt="img" />
              </div>
            </div>
          </center>
        </div>
      </Container>
    </div>
  );
};

export default Client;
