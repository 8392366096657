import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { MdFileDownload } from 'react-icons/md';
import GetQuotaButton from '../../../../components/GetQuotaButton';
import TextAcordion from '../../../../components/textAcordion';
import '../../../../style/product-child.css';

import Banner from '../../../../components/banner';
import ContactUsForm from '../../../../components/ContactUsForm/index';
import {
  internationProducts,
  allProducts,
  productNotFoundText,
} from '../../../../data/ProductPage';
import Page404 from '../../../Page404';

class ExportProductPage extends React.Component {
  render() {
    const pageId = this.props.match.params.id;
    const product = internationProducts[pageId];
    if (!product) {
      return <Page404 />;
    }
    return (
      <div className="product-main-block">
        <Banner image={product.banner} title={product.name} />
        <div className="product-details-block">
          <Container>
            <Row>
              <Col
                lg={9}
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-details-titel-info">
                  <h2 className="section-heading-primary">{product.name}</h2>
                  <div className="product-details-block-title">
                    <h2 className="heading-secondary">products range</h2>
                  </div>
                  <div className="product-details-block-info">
                    {/* {product.productRange.map((item, index) => {
                      return (
                        <li key={`product-range-${index}`}>{item.name}</li>
                      );
                    })} */}
                    <TextAcordion panels={product.productRange} />
                  </div>
                  <div className="product-btns">
                    <GetQuotaButton
                      btnSize="medium"
                      productList={internationProducts[pageId].productRange}
                    />
                  </div>
                  <p className="para para--large para--light-gray">
                    {productNotFoundText}
                  </p>
                  <div
                    className="product-card-block"
                    data-aos="fade-up"
                    data-aos-delay="250"
                    data-aos-once="true"
                    data-aos-anchor=".product-details-block"
                  >
                    <ContactUsForm />
                  </div>
                </div>
              </Col>
              <Col
                lg={3}
                data-aos="fade-left"
                data-aos-delay="250"
                data-aos-once="true"
              >
                <div className="product-category-main-block">
                  <div className="product-category">
                    <div className="product-category-title">
                      <h2>categories</h2>
                    </div>
                    <ul>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products"
                        >
                          all products
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/international"
                        >
                          export
                        </NavLink>
                        <ul>
                          {allProducts.products.industryCategories.map(
                            (item) => {
                              return (
                                <li>
                                  <NavLink
                                    exact
                                    activeClassName="active"
                                    className="nav-link"
                                    to={item.url}
                                  >
                                    {item.name}
                                  </NavLink>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </li>

                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/masterbatches"
                        >
                          masterbatches
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          activeClassName="active"
                          className="nav-link"
                          to="/products/molybdenum"
                        >
                          molybdenum
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <Button
                    className="btn-blue btn-medium brochure-button"
                    href="/downloads/brochure/Palvi Industries.pdf"
                    target="_blank"
                    download
                  >
                    <MdFileDownload className="brochure-icon" />
                    BROCHURE
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default ExportProductPage;
