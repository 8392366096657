import { combineReducers } from 'redux';
import { FETCH_PRODUCTS } from '../actions/index';

const initializeState = {};
const products = (state = initializeState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return {
        items: [],
      };
  }
};

export default combineReducers({ products: products });
