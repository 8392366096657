import React from 'react';
import './index.css';

const paraWithPhoto = (props) => {
  const { text, image } = props;
  return (
    <div className="product-para-block">
      {image ? <img src={image} alt="" /> : ''}
      {text.map((item, index) => {
        return (
          <p key={`product-para-${index}`}>
            <span
              dangerouslySetInnerHTML={{
                __html: item,
              }}
            ></span>
          </p>
        );
      })}
    </div>
  );
};

export default paraWithPhoto;
