export const products = [
  {
    name: 'Caustic soda Flakes/Pearls/Solid',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Caustic soda is used in saponification- the chemical process that converts fat, tallow and vegetable oils into soap.',
    grade: 'Flakes/Prills/Pearls',
    packingDetails: '25 Kg Bags',
    content:
      'Caustic soda is widely known as sodium hydroxide (NaOH). The product is chemically reactive with a wide variety of organic and inorganic chemicals. Caustic soda is majorly available in flakes, pearls & solid form and also commercially traded as CSL/CSP/CSS. The product is used as a key raw material in the manufacturing of variety of valuable products. Caustic soda is highly corrosive in nature.',
    readMore: true,
    url: '/products/international/soap-detergent/caustic-soda',
  },
  {
    name: 'Linear Alkyl Benzene Sulfonix Acid (LABSA)',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Household detergents including laundry powders, laundry liquids, dishwashing liquids and other household cleaners. Industrial applications of wetting agent, emulsifier for agricultural herbicides and in polymerization.',
    grade: 'Industrial Grade',
    packingDetails: '210Kgs HPDE Drums',
    content:
      'Linear Alkyl Benzene Sulfonic Acid (LABSA) is a synthetic chemical surfactant, more the 70% of its volume is used industrial detergent & surfactant. It is highly used in production of washing powder, detergent powder, oil soap, cleaning powder, detergent cake, etc.',
  },
  {
    name: 'Perchloroethylene/Trichloroethylene',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Used as dry-cleaning solvent,vapor degreasing solvent, drying agent for metal and some other solids, As heat transfer medium and in the manufacture of fluorocarbon',
    grade: 'Laundry Grade',
    packingDetails: '330 Kgs MS Drums',
    content:
      'Perchloroethylene/Trichloroethylene are the two special organochloro compounds. These products are often used for the dry cleaning solvents. Perchloroethylene is also known as Tetrachloroethylene (PCE or Perc), is a kind of sweet nonflammable colorless chemical with a stench that its odor threshold is 1 ppm. Trichloroethylene (TCE) is a volatile, nonflammable and colorless liquid. The product is widely used in industry as a solvent in dry-cleaning, as a degreasing agent for manufactured metal parts and as a precursor in the production of chlorofluorocarbons.',
  },
  {
    name: 'Soda Ash (Light and Dense)',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Sodium carbonate is a very efficient source of alkalinity reserve and plays an important role in water softening',
    grade: 'Light/Dense',
    packingDetails: '25Kgs Bags',
    content:
      'Soda ash is commercial known as sodium carbonate and is a commodity product used mainly in the glass, detergent and food processing, chemical, textile and host of other specialty industries. Soda ash light is an important basic industrial alkali chemical used in soap and detergents, pulp and paper, iron and steel, aluminum cleaning compounds, water softening and dyeing, in fiber–reactive dyes, effluent treatment and production of chemicals. Soda ash dense is used in glass manufacturing (flat glass, container glass, plate glass, deep processing to other high grade glass for example automotive glass, curtain wall glass), silicate, ultramarine, and other chemical industries.',
    readMore: true,
    url: '/products/international/soap-detergent/soda-ash',
  },
  {
    name: 'Sodium Carboxyl Methyl Cellulose',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Used as a bulk laxative and as an emulsifier and thickener in cosmetics and pharmaceuticals and as a stabilizer for reagents.',
    grade: 'Detergent Grade',
    packingDetails: '25 Kgs Bags',
    content:
      'Sodium carboxyl methyl cellulose (SCMC or CMC) is also known as cellulose gum. The product is cellulose derived and an anionic water soluble polymer. Sodium CMC is used in industries such as soaps & detergent, oil well drilling, paints, adhesives, textiles, insecticides, leather, foundry, etc.',
  },
  {
    name: 'Sodium Lauryl Ether Sulphate (SLES)',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Widely used surfactant in cleaning products, cosmetics, and personal care products. The sodium lauryl sulfate formula is a highly effective anionic surfactant used to remove oily stains and residues.',
    grade: 'Industrial Grade',
    packingDetails: '170Kgs & 220 Kgs Plastic Drums',
    content:
      'Sodium Lauryl Ether Sulfate (SLES) is an anionic surfactant popularly used in detergents and other cleaning agents due to its excellent emulsification and formability characteristics. The product is also known as Sodium Laureth Sulfate. In term of revenue, the global sodium lauryl ether sulphate demand was around USD 1.16 to 1.2 billion in past year and will grow with a CAGR close to 6.2%.',
    readMore: true,
    url: '/products/international/soap-detergent/sodium-lauryl-ether-sulphate',
  },
  {
    name: 'Sodium Monochloro Acetate',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Manufacturing of carboxymethylcellulose and -starch as thickening agent for detergents, oil drilling, paper, food and pharma applications. Production of agrochemicals like herbicides (e.g. 2,4-D and MCPA) or insecticides e.g. (dimethoates).',
    grade: 'Industrial Grade',
    packingDetails: '25 Kg Bags',
    content:
      'Sodium monochloro acetate (SMCA) is the sodium salt of monochloroacetic acid. It is commercially available with synonyms like Sodium Chloroacetate, Monochloroacetic acid sodium salt, Monochloroethanoic acid sodium salt, Chloroacetic acid sodium salt, Chloroethanoic acid sodium salt, SMCA, SMA.  The product is used in the manufacturing of carboxymethylcellulose and starch as thickening agent for detergents, oil drilling, paper, food & pharma and other applications. Also, production of agrochemicals like herbicides, insecticides and raw material for betaine production, manufacturing of thioglycolic acid for UV-light stabilizers in PVC and thioglycolic acid ester as additives in cosmetics, etc. ',
  },
  {
    name: 'Sodium Tri Polyphosphate',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Phosphates are excellent emulsifiers of oil and fat, turning them into minute droplets dispersed in the detergent. They supplement the emulsifying effect of surfactants and/or sodium disilicate',
    grade: 'Industrial Grade',
    packingDetails: '25Kgs Bags',
    content:
      'Sodium tripolyphosphate is also known as sodium tripolyphosphate, or tripolyphosphate. The product is an inorganic compound with formula Na5P3O10 and the sodium salt of polyphosphate penta-anion. It is used as a component of a wide range domestic and industrial products, particularly detergents.',
  },
  {
    name: 'Talc (Soap Stone) 300-600 Mesh',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Talc is used as a thickening agent and lubricant, is an Use in ceramics, paint, Paper,plastic and roofing material, and is also one of the main ingredients in many cosmetic & Soap-Detergent products.',
    grade: 'Mesh Size 325/400/500/600',
    packingDetails: '25 Kg & 50 Kg Bag',
    content:
      'Talc or soap stone 300 – 600 mesh has variety of industrial application. The product is highly consumed by various manufacturing industries like cosmetics, paints & paper, ceramic, plastic, etc.  Additionally, the product is one of the ke ingredients in many cosmetic & soap-detergent products.',
  },
  {
    name: 'Hydrogen Peroxide 35% & 50%',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Used in bleaching for pulp & paper And another major industiral application is as a active ingredient in laundary product & manufacture of sodium percarbonate.',
    grade: '35% & 50 % Concentration',
    packingDetails: '30 Kg Cans/',
    content:
      'The product is used in bleaching of pulp & paper and another major industrial application is as an active ingredient in laundry product & manufacture of sodium percarbonate. Hydrogen peroxide 50% is highly recommended in water treatment industry. ',
    readMore: true,
    url: '/products/international/soap-detergent/hydrogen-peroxide',
  },
  {
    name: 'Sodium Silicate',
    industry: 'Soap, Detergent & Laundry',
    application:
      'Sodium silicate is a building agent used in many commercial detergents.The purpose of the sodium silicate to is prevent mineral deposits on surfaces after washing by removing water hardness.',
    grade: 'Liquid/Powder/lumps',
    packingDetails: '50 Kg,900 Kg & 280 Kg Drums/IBC',
    content:
      'Sodium silicate (water glass) is a clear, colorless liquid. Majorly used in soaps, detergents, adhesives and in waterproofing. Sometimes used to seal small cracks or leaks in the cooling system and other application as well.',
  },
  {
    name: 'Soap Noodles',
    industry: 'Soap, Detergent & Laundry',
    application: '',
    grade: '',
    packingDetails: '',
    content:
      'Soap noodles fatty acids derived either from vegetable oil or animal fats used as the main ingredient in the production of soap bars. The textile industry is one of the key end-user industries of soap noodles, where industrial soaps are used for a wide array of applications.',
  },
  {
    name: 'Cocamidopropyl Betaine (CAPB)',
    industry: 'Soap, Detergent & Laundry',
    application: '',
    grade: 'Liquid/Powder/lumps',
    packingDetails: '50 Kg,900 Kg & 280 Kg Drums/IBC',
    content:
      'Cocamidopropyl betaine (CAPB) is a mixture of closely related organic compounds derived from coconut oil and dimethylaminopropylamine. The product is available as a viscous pale yellow solution and widely used as a surfactant in personal care products.',
    readMore: true,
    url: '/products/international/soap-detergent/cocamidopropyl-betaine',
  },
  {
    name: 'Formic Acid',
    industry: 'Paper and Textile',
    application:
      'Used as a coagulant for obtaining rubber from latex. It is used as an auxiliary pickling of fur, fixing of dyes-in leather industry and in processing in textile industry. Used as an intermediate in manufacturing of basic drugs, plant protection agents, pesticides, vulcanization accelerators,   antioxidants and cleaning agents.',
    grade: 'Industrial Grade',
    packingDetails: '',
    content:
      'Used as a coagulant for obtaining rubber from latex. It is used as an auxiliary pickling of fur, fixing of dyes-in leather industry and in processing in textile industry. Used as an intermediate in manufacturing of basic drugs, plant protection agents, pesticides, vulcanization accelerators,   antioxidants and cleaning agents.',
  },
  {
    name: 'Fortified Rosin',
    industry: 'Paper and Textile',
    application:
      'Rosin is a product of pine trees and is a commonly used sizing agent. Any good sizing agent improves the quality and life of pa',
    grade: 'Industrial Grade',
    packingDetails: '260Kgs HDPE Drums',
    content:
      'Fortified rosin is a product of rosin. The key raw materials required for the production of fortified rosin are maleic anhydride, caustic, gum rosin, methyl ethanol, and glycol.  It commonly used as sizing agents on wide range of paper grades. The product improves the quality and life of paper.',
  },
  {
    name: 'Optical Brightener',
    industry: 'Paper and Textile',
    application:
      'The product is widely used as a release agent for the production of many kinds of objects: rubber, polyurethane, polyester processing system, powder metallurgy.',
    grade: 'Industrial Grade',
    packingDetails: '',
    content:
      'The product is widely used as a release agent for the production of many kinds of objects: rubber, polyurethane, polyester processing system, powder metallurgy.',
  },
  {
    name: 'Calcium Stearate',
    industry: 'Paper and Textile',
    application:
      'In paper production, calcium stearate is used as a lubricant to provide good gloss, preventing dusting and fold cracking in paper and paperboard making. In plastics, it can act as an acid scavenger or neutralizer at concentrations up to 1000ppm, a lubricant and a release agent.',
    grade: 'Industrial Grade',
    packingDetails: '',
    content:
      'In paper production, calcium stearate is used as a lubricant to provide good gloss, preventing dusting and fold cracking in paper and paperboard making. In plastics, it can act as an acid scavenger or neutralizer at concentrations up to 1000ppm, a lubricant and a release agent.',
  },
  {
    name: 'Ascorbic Acid',
    industry: 'Food Ingredients',
    application:
      'In the manufacturing of glucose powder, energy drinks and fruit juices',
    grade: 'Food Grade',
    packingDetails: '25Kgs Carton Box',
    content:
      'Ascorbic Acid is also known as ascorbic and ascorbate, L-ascorbic acid. It is natural water-soluble vitamin (Vitamin C). The product is a potent reducing and antioxidant agent that functions in fighting bacterial infections, in detoxifying reactions, and in the formation of collagen in fibrous tissue, teeth, bones, connective tissue, skin, and capillaries. Apart from the food industry, it has other applications like in photo development, some specialized scientific applications, personal care products & cosmetics, plastic manufacturing and water purification.',
  },
  {
    name: 'Citric Acid',
    industry: 'Food Ingredients',
    application:
      'As flavor adjunct to add an acidic or sour taste to carbonated beverages and fruit juices',
    grade: 'Monohydrate and Anhydrous',
    packingDetails: '25Kgs Kraft Paper Bags',
    content:
      'Citric acid is widely used as a flavoring, as an acidifier and chelating agent. The most common uses of citric acid are as a preservative, flavoring agent emulsifying agent in food and beverages such as soft drinks, ice creams, etc. The product is also used in pharmaceutical & cosmetic, pharmaceutical & polymer industries, water treatment and other industrial applications. ',
  },
  {
    name: 'Corn (Maize) Starch',
    industry: 'Food Ingredients',
    application:
      'Corn(Maize) starch is used in thickening sauces,soups,Pasta,in making of corn syrup, adhesives, in paper products',
    grade: 'Food/BP',
    packingDetails: '25 Kg Bags',
    content:
      'Corn starch is also known as maize starch and mostly used as key food ingredient in food industry. Additionally, the product is also employed in the industrial adhesive production, key component in various cosmetics, oral pharma products, etc. ',
    readMore: true,
    url: '/products/international/food-ingredient/corn-starch',
  },
  {
    name: 'Dextrose Monohydrate',
    industry: 'Food Ingredients',
    application:
      'It provides sweetness, softness and crystallization control in candies',
    grade: 'Food/BP/USP',
    packingDetails: '25 Kg Bags',
    content:
      'Dextrose Monohydrate is a pure crystallized dextrose. The product is used as a sweetener, a fermentation substrate, a humectant or a carrier in a large scope of applications such as confectionery, bakery, snacks, beverages and dairy products.',
    readMore: true,
    url: '/products/international/food-ingredient/dextrose-monohydrate',
  },
  {
    name: 'Liquid Glucose (Corn Syrup)',
    industry: 'Food Ingredients',
    application:
      'Liquid glucose is used in  manufacturing of  Jams, jellies, chewing gums, biscuits,Candies and canned fruits.',
    grade: 'Food',
    packingDetails: '300 Kg Drum',
    content:
      'Corn syrup is an edible syrup and commercially known as glucose liquid. The product is mostly used in confectionary, soft drinks, soften the texture of food, and enhance the flavor of edible/food products. ',
  },
  {
    name: 'Malt Extract Liquid/Powder',
    industry: 'Food Ingredients',
    application:
      'Used in preparation of Malt Beverages, confectionaries & malted beer',
    grade: 'Liquid/Powder',
    packingDetails: '300 Kg Dums/25 Kg Drums',
    content:
      'Malt extract powder/liquid is extensively used for the production of health drinks, nutrious food, beverages, confectionaries, bakery products, pharmaceuticals and food products. ',
  },
  {
    name: 'Phosphoric acid 85% Food Grade',
    industry: 'Food Ingredients',
    application: 'As a degumming agent in edible oil and sugar refinery',
    grade: 'Food Grade',
    packingDetails: '35Kgs, 330Kgs and IBC',
    content:
      'Phosphoric acid 85% food grade is widely used to regulate pH value, as a food additive in packaged food & beverage products. The product is also employed in pharma sector, as it is a key ingredient in OTC medicines and others. ',
    readMore: true,
    url: '/products/international/food-ingredient/phosphoric-acid',
  },
  {
    name: 'Starch and Modified Starches',
    industry: 'Food Ingredients',
    application:
      'Starch & Modified Starch used in food products as a thickening agent, stabilizer or emulsifier; in pharmaceuticals as a disintegrant; or as binder in coated paper and corrugation Industries',
    grade: 'Modifed Sarch',
    packingDetails: '25Kgs Bags',
    content:
      'Starch is a soft, white, tasteless powder that is insoluble in cold water, alcohol, or other solvents. Starch is a polysaccharide comprising glucose monomers joined in α 1,4 linkages.',
  },
  {
    name: 'Sodium Bicarbonate',
    industry: 'Food Ingredients',
    application: 'As a leavening agent in food',
    grade: 'Food Grade',
    packingDetails: '25Kgs Bags',
    content:
      'Sodium bicarbonate is commercially known as sodium hydrogen carbonate and baking soda. In food & beverage industry, the product is used as an additive to regulative acidity, increase the product shelf life. The product is used throughout industry, it buffers, neutralizes, provides a source of CO2, and acts as a catalyst in multiple processes. Fire extinguishers use sodium bicarbonate in order to smother the flames. ',
    readMore: true,
    url: '/products/international/food-ingredient/sodium-bicarbonate',
  },
  {
    name: 'Ammonium Bicarbonate',
    industry: 'Food Ingredients',
    application:
      'Ammonium bicarbonate is used as a baking powder, in some food processing applications, in cough syrups and as antacid. It also has uses as a fertilizer, pH buffer, and reagent in chemical laboratories.',
    grade: 'Food Grade',
    packingDetails: '25Kgs Bags',
    content:
      'Ammonium bicarbonate is used as a baking powder, in some food processing applications, in cough syrups and as antacid. It also has uses as a fertilizer, pH buffer, and reagent in chemical laboratories. In the industry, it is used in the manufacture of dyes, pharmaceuticals, catalysts, ceramics, fire-retardants, plastics and other products.',
  },
  {
    name: 'Sorbitol 70% NC BP/USP',
    industry: 'Food Ingredients',
    application:
      'Used in diet foods including diet drinks and ice cream, mints and sugar-free chewing gum.',
    grade: 'Food/BP/USP',
    packingDetails: '300 Kg Drums,Flexi,Ibc',
    content:
      'Sorbitol 70% NC is a hydrogenated dextrose non-crystalline aqueous solution. The product is used in the production of tobacco, cosmetics, pharma, as a bulking agent for sugar-free solutions & syrups, etc.',
    readMore: true,
    url: '/products/international/food-ingredient/sorbitol',
  },
  {
    name: 'Malic Acid',
    industry: 'Food Ingredients',
    application: 'Used as a flavour enhancer',
    grade: 'Food Grade',
    packingDetails: '25Kgs Bags',
    content:
      'Malic Acid is an organic compound, a dicarboxylic acid that is the active ingredient in many sour and tart foods. The product is generated during fruit metabolism and occurs naturally in all fruits and many vegetables. Malic acid is employed in carbonated beverages, non-carbonated beverages, powdered mixes, low calorie beverages, ciders & wines, dairy products, medical & personal care products, acid based facial products and other oil field applications.',
  },
  {
    name: 'Tertiary Butylhydroquinone (TBHQ)',
    industry: 'Food Ingredients',
    application:
      'It is used to stabilize foods, fats and vegetable oils against oxidative deterioration and thus extending their storage life.',
    grade: 'Food Grade',
    packingDetails: '10/25 Kgs Fiber Drums',
    content:
      'In food industry, the product is used as a preservative for unsaturated vegetable oils and many edible animal fats. The other key applications include baked & confectionary products, cosmetics, edible fats, emulsifiers, etc. ',
  },
  {
    name: 'Butylated Hydroxytoluene (BHT)',
    industry: 'Food Ingredients',
    application:
      'It is a particularly good antioxidant for baked food products and also for stabilizing meat, fish and bone meals. Also used as n universal and highly effective stabilizer for natural rubber, various synthetic rubber and lattices',
    grade: 'Food Grade',
    packingDetails: '25Kgs PP Bags',
    content:
      'Butylated Hydroxytoluene (BHT) is a lab-made chemical which is added to foods as a preservative. The product is also used as a medicine. Butylated Hydroxytoluen is used to treat genital herpes and acquired immunodeficiency syndrome (AIDS). ',
  },
  {
    name: 'Butylated Hydroxyanisole (BHA)',
    industry: 'Food Ingredients',
    application:
      'It is used as antioxidant for baked food products and also for stabilizing meat, fish and bone meals',
    grade: 'Food Grade',
    packingDetails: '5Kgs Carton Box',
    content:
      'Butylated Hydroxyanisole (BHA) is used as an antioxidant and preservative in food, food packaging, animal feed, rubber, cosmetics, and petroleum products. The product is also commonly used in medicines include isotretinoin, lovastatin, and simvastatin, among others.',
  },
  {
    name: 'Ascorbyl Palmitate (ASP)',
    industry: 'Food Ingredients',
    application:
      'It is a fat-soluble antioxidant which is used to increase the shelf life of vegetable oils and potato chips',
    grade: 'Food Grade',
    packingDetails: '10/25 Kgs Fiber Drums',
    content:
      'Ascorbyl palmitate (ASP) is highly employed as a chemical preservative food additive and also as an antioxidant in pharmaceuticals and whitening agent in cosmetics. The product has the ability to penetrate skin tissue and stimulate collagen production. Additionally, it is also used as a source of Vitamin C. ',
  },
  {
    name: 'Castor Oil and derivatives',
    industry: 'Cosmetics, Perfume & Hair-Skin Care',
    application:
      'Castor oil and its derivatives are used in the manufacturing of soaps, lubricants, hydraulic and brake fluids, paints, dyes, coatings and Many more',
    grade: 'FSG/PP/Low Moisture/Sulphonated',
    packingDetails: '225 Kg Drum',
    content:
      'The product is extensively used for the production of perfumery products, lipsticks, hair tonics, shampoos, polishes, deodorant’s, sunscreen lotions, and emulsifiers. The other industrial applications include auto components, apparel, electronics, neuro-protective ingredients, textile accessories, flame retardants, optics, etc. ',
  },
  {
    name: 'Menthol Crystals/Oil',
    industry: 'Cosmetics, Perfume & Hair-Skin Care',
    application:
      'Mint flavoring agent in candies, mouth fresheners and dentifrices',
    grade: 'Large & Fine Crystals',
    packingDetails: '25Kgs Fiber Drums',
    content:
      'We export menthol crystals and oils across the globe. Menthol crystals are the cool, refreshing, icy component of most flavors and fragrances derived from mint. The product is used in pharma as well as in cosmetic industry and also employed as an element in production of medicated creams, mouth wash, cooling gel, throat lozenges, balms, foot sprays, body cooling products, shaving creams, and others.',
  },
  {
    name: 'Sorbitol 70% BP/NC',
    industry: 'Cosmetics, Perfume & Hair-Skin Care',
    application:
      'Used in chewable tablets, capsule formulations and cough syrups.',
    grade: 'Food/BP/USP',
    packingDetails: '300 Kg Drums,Flexi,Ibc',
    content:
      'Sorbitol 70% NC is a hydrogenated dextrose non-crystalline aqueous solution. The product is used in the production of tobacco, cosmetics, pharma, as a bulking agent for sugar-free solutions & syrups, etc.',
  },
  {
    name: 'White Oil Tech/Pharma',
    industry: 'Cosmetics, Perfume & Hair-Skin Care',
    application:
      'Used in manufacture of Bulk Drugs, Cosmetics, Textile Auxiliaries & Personal care Application',
    grade: 'USP/BP',
    packingDetails: '200&165 Kg Drum',
    content:
      'The product is used as a blending base in cosmetic and pharmaceutical products like ointments, creams, hair oils, petro jelly, etc. Additionally, the product is employed in protective coatings, PS production and other industrial applications. ',
  },
  {
    name: 'Zinc Pyrithione',
    industry: 'Cosmetics, Perfume & Hair-Skin Care',
    application:
      'Anti-dandruff agent ,body wash products, An effective, broad-spectrum anti-dandruff compound,Used in various rinse-off and leave-on hair & skin care products',
    grade: 'Comestic Grade/Industrial Grade',
    packingDetails: '25 Kgs HDPE Jerry Cans',
    content:
      'Zinc Pyrithione is also known as pyrithione zinc. The product is used as an antibacterial and antifungal agent in OTC antidandruff topical treatments such as shampoos and other dermatitis products. ',
  },
  {
    name: 'Stearic Acid',
    industry: 'Cosmetics, Perfume & Hair-Skin Care',
    application:
      'Skin protective, used as  ingredient in cleansers, body washes and shampoos as well as bar soaps',
    grade: 'Triple Press Grade',
    packingDetails: '25 Kg Paper Bags',
    content:
      'Stearic acid is used for the production of ointments, emulsifiers, lubricants, lotions, emollients, and others. The product effectively softens, stabilizes, thickens and helps to create a cooling sensation when applied on the skin. ',
  },
  {
    name: 'Glycerine',
    industry: 'Cosmetics, Perfume & Hair-Skin Care',
    application: 'Improves skin smoothness and attracts moisture to the skin',
    grade: 'Comestic Grade/Food Grade',
    packingDetails: '250 Kg HDPE Drums',
    content:
      'The product is a simple polyol compound and owing to its antiviral & antimicrobial properties, it is extensively used in FDA approved burn & wound treatments. Moreover, the product is employed in various cosmetic products which include moisturizers, conditioners & shampoos, creams, and others.',
  },
  {
    name: 'Benzaldehyde',
    industry: 'Flavour & Fragrance',
    application:
      'Benzaldehyde is used chiefly as a precursor to other organic compounds, ranging from pharmaceuticals to plastic additives',
    grade: 'Industrial Grade',
    packingDetails: '210Kgs HPDE Drums',
    content:
      'Benzaldehyde is widely used for the production of personal care products, pharma, plastic additives, food, etc. The product has its almond scent, which makes it perfect for most of cosmetic & personal care products applications such as perfumes & deodorant’s, creams & lotions, soaps, etc. ',
  },
  {
    name: 'Benzyl Acetate',
    industry: 'Flavour & Fragrance',
    application:
      'It is used as a solvent in plastics and resins, nitrates, oils and lacquers. Also an intermediate in the production other organic compounds',
    grade: 'Industrial Grade',
    packingDetails: '210Kgs HPDE Drums',
    content:
      'Benzyl acetate possess pleasant and sweet aroma and find usage in health care and personal hygiene products. Additionally, the product is also employed in plastic & resin, polishes & inks, and other industrial products. ',
  },
  {
    name: 'Cinammic Aldehyde',
    industry: 'Flavour & Fragrance',
    application:
      'The most obvious application for cinnamaldehyde is as flavoring in chewing gum, ice cream, candy, eliquid and beverages',
    grade: 'Fragrance',
    packingDetails: '210Kgs HPDE Drums',
    content:
      'Cinammic aldehyde is extensively used for the production cosmetic and personal care products such as hair creams, lotions, shampoos, etc. as it imparts pleasing aroma in the products.  In addition, the product is used as a corrosion inhibitor for steel and other ferrous alloys in corrosive fluids, also used in dispersing agents, surfactants, etc. ',
  },
  {
    name: 'Dihydromyrcenol',
    industry: 'Flavour & Fragrance',
    application:
      'Dihydromyrcenol imparts a powerful, fresh lime-like, citrusy-floral and sweet odour with little or no terpenic undertones. It is used predominantly in soap fragrances and as a powerful supporting note in citrus and lime-type perfumes',
    grade: '-',
    packingDetails: '170Kgs Epoxy Coated Drums',
    content:
      'Dihydromyrcenol is used as fragrance & flavoring agent in most of the cosmetics, personal hygiene and other personal care products. ',
  },
  {
    name: 'Methylene Chloride',
    industry: 'Polyurathene',
    application:
      'It is used as an aerosol spray propellant and as a blowing agent for polyurethane foams',
    grade: 'Industrial Grade',
    packingDetails: '270Kgs MS Drums',
    content:
      'Methylene chloride is commercially known as methylene dichloride and dichloromethane. The product is used a foam bowing agent in polymer industry. It is also employed as a softening, aerosol spray agent in polyurethane foams & coatings. ',
  },
  {
    name: 'Polyols',
    industry: 'Polyurathene',
    application:
      'polyols are chemicals used by polyurethane industry primarly to produce products such as flexible and moulded foam for beddings, mattresses & upholstered furniture.',
    grade: 'Industrial Grade',
    packingDetails: '210Kgs HPDE Drums',
    content:
      'Polyols are extensively used as key raw material for the production of polyurethane. These polyurethane further employed to make foam insulations for freezers & refrigerators, seats of automotive, spandex, adhesives, and other elastomeric products.   ',
  },
  {
    name: 'PU Grade Castor Oil',
    industry: 'Polyurathene',
    application: 'Used in Manufacturing of polyurethane foam and Resins',
    grade: 'PU grade',
    packingDetails: '225 Kg Drum',
    content:
      'Polyurethane grade castor oil is widely used in the manufacturing of polyurethane foam and resins. The key applications of product includes urethane coatings, adhesives, inks, urethane molding, etc. ',
  },
  {
    name: 'Toluene Di Isocyanate (TDI 80/20)',
    industry: 'Polyurathene',
    application:
      'for flexible polyurethane foam, for use in cushions, mattresses, garments, pads and gloves and packaging materials.',
    grade: 'Industrial',
    packingDetails: '250 Kg Drums',
    content:
      'Toluene Diisocyanate TDI 80-20 is an aromatic isocyanate that is produced for reaction with polyols to form polyurethanes. It is widely used in the production of polyester-based soft foam, semi-rigid ester foam, high-bearing sponges, paints, etc.',
    readMore: true,
    url: '/products/international/polyurathene/toluene-di-isocyanate',
  },
  {
    name: 'PU Colors',
    industry: 'Polyurathene',
    application: 'Used a colourant in PU industry',
    grade: 'Industrial Grade',
    packingDetails: '25Kgs Fiber Drums',
    content:
      'The polyurethane colors are mostly employed on flexible slab stock foam, semi flexible foams, automotive parts, shoes, toys, industrial and other consumer goods.',
  },
  {
    name: 'Benzyl Alcohol',
    industry: 'Paint & Coating',
    application:
      'As solvent in epoxy hardeners & in marine paints, ball pen inks and lacquer. As paint stripper in aviation industry',
    grade: 'Industrial Grade',
    packingDetails: '210 & 220 Kgs HDPE Drums',
    content:
      'Benzyl alcohol is used as a solvent for epoxy resin coatings, inks, and paints. A solution of benzyl alcohol with a concentration of 10% can be used as a local anesthetic and also as an antimicrobial agent. The product is used in the production of shampoos, soaps, skin lotions, and others. ',
  },
  {
    name: 'Butyl Acetate',
    industry: 'Paint & Coating',
    application:
      'Butyl acetate, also known as butyl ethanoate, is an organic compound commonly used as a solvent in the production of lacquers and Paints. It is also used as a synthetic fruit flavoring in foods such as candy, ice cream, cheeses, and baked goods. Butyl acetate is found in many types of fruit, where along with other chemicals it imparts characteristic flavors.',
    grade: 'Industrial Grade',
    packingDetails: '180 Kg Steel Drums',
    content:
      'Butyl acetate is extensively used as a solvent for the production of paints & lacquers. Additionally, the product is also employed in the manufacturing of hardening coatings & adhesives. ',
  },
  {
    name: 'Calcium Carbonate',
    industry: 'Paint & Coating',
    application:
      'It is used as an  extender in paints & manufacturing of Filler Masterbatch',
    grade: 'Food Grade/Plastic Grade',
    packingDetails: '25/50 Kg bags',
    content:
      'Calcium carbonate is used as and extender for titanium dioxide in the processing of paints & coatings. This in turn, will significantly reduce the overall cost of the product. ',
  },
  {
    name: 'Ethyl Acetate',
    industry: 'Paint & Coating',
    application:
      'Ethyl acetate is used primarily as a solvent and diluent, being favored because of its low cost, low toxicity, and agreeable odor.it is commonly used to clean circuit boards and in some nail varnish removers.  It is also used in paints as an activator or hardener.',
    grade: 'Industrial Grade',
    packingDetails: '210 Kgs HDPE Drums & 210 Kg MS Drums',
    content:
      'Ethyl acetate is used as an additive & solvent in pants, coatings, thinners, adhesives, cleaning and other paint removing agents. ',
  },
  {
    name: 'Isophorone',
    industry: 'Paint & Coating',
    application:
      'As a solvent for resins, lacquers, inks and PVC,In manufacture of perfumery chemicals and intermediates,In manufacture of polyurethanes for light stable weather resistant urethane coating, Intermediate for Isophorone diamine, Isophorone diisocyanate, Trimethylcyclohexanone,In pesticide and herbicide formulations,In vinyl chloride/acetate-based coating systems for metal cans, other metal paints,\nnitrocellulose finishes and printing inks for plastics.',
    grade: 'Industrial Grade',
    packingDetails: '195 Kg GI Drum',
    content:
      'Owing to high water solubility, miscibility with lacquer solvents, significant dilution ratio and other properties makes it perfect for paint & coatings applications.',
  },
  {
    name: 'Methyl Ethyl Ketoxime (MEKO)',
    industry: 'Paint & Coating',
    application:
      'MEKO, is used to suppress “skinning” of paints: the formation of a skin on paint before it is used, hence is a popular anti-skinning agent. Skinning is the biggest nuisance in protective coatings.',
    grade: 'Industrial Grade',
    packingDetails: '190 Kg UN approved  MS drums',
    content:
      'Methyl ethyl ketoxime is a type of oil based coating antioxidant. The product is widely used an anti skinning agent, viscidity stabilizer for alkyd resin coatings, isocyanate sealants, etc. ',
  },
  {
    name: 'Paint Driers (Octoates)',
    industry: 'Paint & Coating',
    application:
      'Driers , are chemically added to the paint as they act as catalysts. The main function of a drier is to accelerate the drying of  paint and in consequence paint film becomes hard.',
    grade: 'Co,Mn,Pb,Ca,Zn,Zr based',
    packingDetails: '200,225,250,190,180  Kg UN approved MS Drums',
    content:
      'Octoates are chemically added to the paint as they act as catalysts. The main function of a drier is to accelerate the drying of paint and in consequence paint film becomes hard.',
  },
  {
    name: 'Talc',
    industry: 'Paint & Coating',
    application:
      'used in many industries, including paper making, plastic, paint, coatings & rubber',
    grade: 'Mesh Size 325/400/500/600',
    packingDetails: '25 Kg Bags',
    content:
      'Talc is mostly used in industrial paints, printing inks, fillers and several coatings as it increases the coverage & weather resistance properties. Moreover, the product creates a barrier effect, which basically limits the water & corrosive agent’s penetration into coatings and paints. ',
  },
  {
    name: 'Potassium Silicate',
    industry: 'Paint & Coating',
    application:
      'Used in manufacturing of welding rods .In horticulture, potassium silicate is used as a soluble source of potassium and silicon & strengthens the stem of plant.',
    grade: 'Lumps/Liquid/Powder',
    packingDetails: '50 Kg,900 Kg & 280 Kg Drums/IBC',
    content:
      'Potassium silicate is used in paints & coatings processing due to its superior water vapor permeability, adhesion, low absorbability, stable & persistent towards acids, and other properties. ',
  },
  {
    name: 'Chlorinated Paraffin',
    industry: 'Plastics',
    application:
      'It is mainly used  as a plasticizer in formulation of PVC compounds & granules used in wires & cables, PVC windows and door profiles, PVC flooring, films & sheets, PVC foot wears, etc',
    grade: '42% and 52%',
    packingDetails: '210Kgs HPDE Drums, IBC and Flexi',
    content:
      'The product is mainly used as a plasticizer in the formulation of PVC compounds & granules used in wires & cables, PVC windows and door profiles, PVC flooring, films & sheets, PVC foot wears, and other plastic products. This is all due to its superior fire retarding, chemical & stain resistance, other characteristics. ',
    readMore: true,
    url: '/products/international/plastics/chlorinated-paraffin',
  },
  {
    name: 'Plasticizers-DOP/DINP/DIBP/DEP/DMP/DBP',
    industry: 'Plastics',
    application:
      'These are additives used mainly in PVC that also in films and cables.',
    grade: 'Industrial Grade',
    packingDetails: '235 KG MS Drum',
    content:
      'The plasticizers are additives used mainly in PVC, which further used in films and cables. ',
  },
  {
    name: 'Activated Carbon',
    industry: 'Drilling & Mining',
    application:
      'Used in methane and hydrogen storage, air purification, decaffeination, gold purification, metal extraction, water purification, medicine, sewage treatment, air filters in gas masks and respirators.',
    grade: 'Grannules/Powder',
    packingDetails: '50 Kg, 900 Kg',
    content:
      'Activated carbon is used in the purification of edible oils, recovery of metals, corrosion prevention, cyanotoxin removal from drinking water, air filtration, treatment of condensate water, etc. ',
  },
  {
    name: 'Calcium Chloride 94%',
    industry: 'Drilling & Mining',
    application:
      'Used  in Oil drilling - helps add density to the mud to overcome formation pressures and keep oil, gas, and water in place. ,de-icing,Road surfacing & food ingedients',
    grade: 'Tech/Food grade',
    packingDetails: '25 Kg HDPE bags',
    content:
      'Calcium chloride 94% are majorly used to formulate solid free, high density drilling fluids for the mining, oil & gas industry. ',
    readMore: true,
    url: '/products/international/drill-mining/calcium-chloride',
  },
  {
    name: 'Sodium Cyanide',
    industry: 'Drilling & Mining',
    application:
      'The main application  is in the mining industry, where it is used to extract gold and other precious metals from their ores. It is also used for electroplating. It is used as an important precursor to many useful organic and inorganic chemicals, including pharmaceuticals.',
    grade: 'Industrial Grade',
    packingDetails: '',
    content:
      'Sodium cyanide is used for the recovery gold & other metals. The product is employed in mining operations to leach the gold & other metals from ore. ',
  },
  {
    name: 'Xanthates (SIPX, PIPX, PAX, PIBX, PEX, SAX)',
    industry: 'Drilling & Mining',
    application:
      'xanthate are  used in the mining industry as flotation agent for recovery of metals, such as copper, nickel, silver or gold, as well as solid metal sulfides or oxides from ore slurries.',
    grade: 'Industrial Grade',
    packingDetails: '25 Kg /900 Kg UN approved bags',
    content:
      'Xanthates are water-soluble chemicals which are majorly used in the mining industry. The product is chemical reagent and commonly used during the processing of metalliferous ores in mining & drilling industry.',
  },
  {
    name: 'Aluminium Chloro Hydrate (ACH)',
    industry: 'Water Treatment',
    application:
      'Used to reduce underarm wetness in underarm antiperspirant products. Also widely used in water purification to remove dissolved organic material in drinking & waste water .',
    grade: 'Liquid/Powder',
    packingDetails: '275 Kg drums ,IBC,816 Kg,50 Kg',
    content:
      'Aluminum chlorohydrate (ACH) is used as a coagulant in municipal & industrial water treatment plants. Mostly, the product is employed for TSS, metals and phosphate removal in waste water treatment sector. ',
  },
  {
    name: 'Activated Carbon',
    industry: 'Water Treatment',
    application:
      'Used in methane and hydrogen storage, air purification, decaffeination, gold purification,edible oil purification,tooth paste , metal extraction, water purification, medicine,used vehicle canisters to reduce emission of toxic gases,  sewage treatment, air filters in gas masks and respirators.',
    grade: 'Graules/Powder',
    packingDetails: '50 Kg/900 Kg',
    content:
      'Activated carbon is used in the purification of edible oils, recovery of metals, corrosion prevention, cyanotoxin removal from drinking water, air filtration, treatment of condensate water, etc. ',
  },
  {
    name: 'Aluminium Sulphate (Ferric/Non-Ferric)',
    industry: 'Water Treatment',
    application:
      'Used in water purification as coagulant & flocculant and as a mordant in dyeing and printing textiles.',
    grade: 'Lumps /Powder',
    packingDetails: '25 /50 Kg Bags',
    content:
      'Aluminium sulphate is used in the water treatment for coagulation of organic and mineral colloids prior to sedimentation and/or filtration. The product is also used in dyeing fabrics, paper making, medical, food and other applications.  ',
  },
  {
    name: 'Calcium Hydrochlorite (Sodium Basel) 65% and 70%',
    industry: 'Water Treatment',
    application:
      'Commonly used to sanitize public swimming pools and disinfect drinking water.',
    grade: '65/70 %',
    packingDetails: '45 Kg UN approved drums',
    content:
      'Calcium hydrochlorite is also commercially known as sodium basel. The product is used to sanitize & disinfect the decks, pool bottom& sides, ladders, industrial sanitization and also employed as a bleaching agent. Calcium hydrochlorite is extensively used to disinfect sewage and waste water treatment. ',
  },
  {
    name: 'Ferric Chloride (Liquid/Powder)',
    industry: 'Water Treatment',
    application:
      'Iron(III) chloride is used in sewage treatment and drinking water production as a coagulant and flocculant. Iron(III) chloride is used as catalyst for the reaction of ethylene with chlorine, forming ethylene dichloride (1,2-dichloroethane), an important commodity chemical, which is mainly used for the industrial production of vinyl chloride, the monomer for making PVC.',
    grade: 'Liquid/Anhydrous Powder',
    packingDetails:
      '1) 300 KG HDPE Drums 2) 1400/1450 Kgs Wooden Base/Composite Base IBC',
    content:
      'Ferric chloride is an industrial commodity chemical and wisely used for sewage treatment and purification of water, production of drinking water, etc. Additionally, it is also employed in production of plating agents, surface treating agents, pigments and other industrial applications. ',
  },
  {
    name: 'Liquid Chlorine',
    industry: 'Water Treatment',
    application:
      'It is used as a highly efficient disinfectant and is added to public water supplies to kill disease-causing pathogens',
    grade: 'Industrial Grade',
    packingDetails: '900 Kg used & new cylinders',
    content:
      'Liquid chlorine is significantly employed in the water purification activities as it restrict the biological growth in water. Moreover, the product is used in pulp & paper, textile, plastics, chemical processing, agriculture, pigments, pharma and other industries. ',
  },
  {
    name: 'Poly Aluminium Chloride (Liquid/Powder)',
    industry: 'Water Treatment',
    application:
      'The product is used  as a flocculant in water purification, in treatment of drinking / potable water, wastewater treatment and paper sizing.',
    grade: 'Liquid/Powder',
    packingDetails: '25 Kg HDPE bags/250 Kg HDPE drums',
    content:
      'Poly aluminium chloride (PAC) is mostly used for purification application in waste-water industry. The product is also used in pulp & paper for the treatment of water & effluents during production of paper.  ',
  },
  {
    name: 'Sodium Chlorate - Chlorite',
    industry: 'Water Treatment',
    application:
      'use is to make chlorine dioxide gas.and is used in the bleaching of pulp.',
    grade: 'Powder/liquid',
    packingDetails: '50/1250 Kg bags,270 Kg HDPE drums',
    content:
      'Sodium chlorate / chlorite are both majorly used in the production of chlorine di oxide, which is further employed in water treatment application. ',
  },
  {
    name: 'Sodium Hypochlorite',
    industry: 'Water Treatment',
    application:
      'Sodium hypochlorite (NaOCl) is a compound that can be effectively used for water purification. It is used on a large scale for surface purification, bleaching, odor removal and water disinfection.',
    grade: 'Industrial Grade',
    packingDetails: '60 Kgs HDPE Jerry Cans With Vent Cap',
    content:
      'Sodium Hypochlorite is widely used for water purification applications. The product is also used for bleaching, odor removal, surface purification, and other industrial applications. ',
  },
  {
    name: 'Sodium Nitrite',
    industry: 'Other',
    application:
      'Used as an acid neutralizer agent after chromium tanning in leather manufacturing. Used for processing and purifying chemicals & polymers, serving as a catalyst, as a neutralizer and buffering agent and a reactant.',
    grade: 'Industrial Grade',
    packingDetails: '',
    content:
      'Sodium Nitrite is a chemical compound with a formula NaNO2. The product is a white to slight yellowish crystalline powder that is very soluble in water and hygroscopic in nature.',
  },
  {
    name: 'Zinc Stearate',
    industry: 'Other',
    application:
      'The product is widely used as a release agent for the production of many kinds of objects: rubber, polyurethane, polyester processing system, powder metallurgy.',
    grade: 'Industrial Grade',
    packingDetails: '',
    content:
      'The product is widely used as a release agent for the production of many kinds of objects: rubber, polyurethane, polyester processing system, powder metallurgy.',
  },
  {
    name: 'Benzyl Chloride',
    industry: 'Other',
    application:
      'It is the precursor to benzyl esters & manufacturing of other organic compounds',
    grade: 'Industrial Grade',
    packingDetails: '210Kgs and 220Kgs HDPE Drums',
    content:
      'Benzyl chloride is mostly employed as an intermediate in various chemicals. The product is used as a solvent for inks, paints, biocide, employed as a precursor for penicillin, pharma products, detergent & cleaning agent industry, etc.',
  },
  {
    name: 'Extra Neutral Alcohol',
    industry: 'Other',
    application:
      'Mainly used in the production of potable alcohol, in the pharmaceutical industry. Also it is used to produce distilled vinegar, flavour extracts and concentrates for soft drinks and food products.',
    grade: 'Industrial Grade',
    packingDetails: '250 Liter Drums',
    content:
      'Extra neutral alcohol (ENA) is employed in the production of potable alcohol, pharma, flavor & fragrance, personal care and food industries. ',
  },
  {
    name: 'Copper Sulphate',
    industry: 'Other',
    application:
      'It is used as Mirconutient for Fertilizer & also used in electroplating',
    grade: 'Industrial/Agricultural',
    packingDetails: '25 KgBags/50 Kg Bags',
    content:
      'Copper sulphate/copper sulfate is used as an intermediary chemical, reagent, catalyst, additive in agricultural sector, synthetic fiber, metal & mining, paints & coating and other industries. ',
  },
  {
    name: 'Ferrous Sulphate',
    industry: 'other',
    application:
      'It is used as colorant as well as in Water treatment. Also widely used in Industiral Catalyst & in gold refining',
    grade: 'Industrial',
    packingDetails: '25 KgBags/50 Kg Bags',
    content: '',
  },
  {
    name: 'Magnesium Sulphate',
    industry: 'Other',
    application: 'Used as Micronutrient in fertilizer as source of Magnesium',
    grade: 'Industiral/Agricultural',
    packingDetails: '25 KgBags/50 Kg Bags',
    content:
      'Magnesium sulphate/Magnesium Sulfate is highly recommended in pharma industry as it is used to control the low blood vessel level of magnesium, constipation, etc. Moreover, the product find its extensive use in the production of textile, cement, fertilizer, and other industries.',
  },
  {
    name: 'Manganese Sulphate',
    industry: 'Other',
    application:
      'Used as Micronutrient in fertilizer & in feed additives as source of Managnese',
    grade: 'Industiral/Agricultural/Feed',
    packingDetails: '25 KgBags/50 Kg Bags/1000 Kg Jumbo Bags',
    content:
      'Manganese sulphate/manganese sulfate is used as a fertilizer additive, textile dyes, nutrient & dietary supplements, poultry, laboratory reagent, paints & varnishes, etc. ',
    readMore: true,
    url: '/products/international/other-products/manganese-sulphate',
  },
  {
    name: 'Zinc Sulphate',
    industry: 'Other',
    application:
      'Used as Micronutrient in fertilizer & in feed additives as source of Zinc',
    grade: 'Heptahydrate/Monohydrate',
    packingDetails: '25 KgBags/50 Kg Bags/1000 Kg Jumbo Bags',
    content:
      'Zinc Sulphate (ZnSO4) is widely used as a dietary supplement. Apart from this, the product is used as a preservative for leather, electroplating, dyeing, personal care & cosmetics, etc. ',
    readMore: true,
    url: '/products/international/other-products/zinc-sulphate',
  },
  {
    name: '2-Ethyl Hexyl Acetate',
    industry: 'Other',
    application:
      'Used as an additive in cleaners & Paint Removers, it improves the flow & film formation',
    grade: 'Industrial',
    packingDetails: '185 Kg MS Drums',
    content:
      '2-Ethyl Hexyl Acetate is used in various industrial applications. Some of the application industries include leather, paint & coatings, cleaners, automotive OEM, etc. ',
  },
  {
    name: 'Oxalic Acid Dihydrate',
    industry: 'Other',
    application:
      'It is used commercially as a laundry rinse, wood-bleaching agent, and calcium remover. Oxalic acid can remove some paints and varnishes. It is also used as a mordant in dyeing textiles and in the manufacture of ink.',
    grade: 'Dihydrate',
    packingDetails: '25 Kg/900 Kg Bags',
    content:
      'Oxalic Acid Dihydrate is used as an analytical reagent in metal treatment, pain & dye, polymer, surface treatment, water treatment, wood and other industrial applications. ',
  },
  {
    name: 'Para Di Chloro Benzene (PDCB)',
    industry: 'Other',
    application:
      'Paradichlorobenzene is used as a fumigant insecticide to control clothes moths. It is also found in deodorant blocks made for trash cans and toilets.',
    grade: 'Industrial Grade',
    packingDetails: '25 Kg Paper Bags',
    content:
      'Para di chloro benzene (PDCB) is commercially used as a fumigant insecticide to control cloth moths. Additionally, it is also employed in insecticidal fumigant, production of PPS resin, pesticides, etc. ',
  },
  {
    name: 'Zinc Oxide',
    industry: 'Other',
    application:
      'chemical process for converting natural rubber into a more durable material.',
    grade: 'Industrial Grade',
    packingDetails: '25 Kg Bags',
    content:
      'Zinc oxide (ZnO) is used in various branches of industries such as textile, electronic, rubber, pharma & medical, rubber, etc. ',
  },
  {
    name: 'Formaldehyde',
    industry: 'Other',
    application:
      'This compound is used mainly in the manufacture of phenolic, ureic, thioureic and melaminic resins. Such resins are used in particleboard and plywood manufacture, in ceiling and wall insulation; are also used in the manufacture of wrinkle-proof fabrics.',
    grade: 'Industrial Grade',
    packingDetails: '210Kgs and 220Kgs HDPE Drums',
    content:
      'Formaldehyde is a simple chemical compound made of hydrogen, oxygen and carbon. All life forms – bacteria, plants, fish, animals and humans – naturally produce formaldehyde as part of cell metabolism.',
    readMore: true,
    url: '/products/international/other-products/formaldehyde',
  },
  {
    name: 'Black MasterBatches',
    industry: 'Masterbatches',
    application: '',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'White Masterbatches',
    industry: 'Masterbatches',
    application: '',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'Additive Masterbatches',
    industry: 'Masterbatches',
    application:
      'Additive masterbatches modify various properties of the base plastic: ultraviolet light resistance, flame retardant, anti-fouling.',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'Filler Masterbatches',
    industry: 'Masterbatches',
    application: '',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'Ammonium Di Molybdate',
    industry: 'Molybdenum',
    application:
      'Used as pigments, agricultural fertilizer, color lakes and fabric fire retardant agent. It is also used as a raw material for the production of molybdenum wire, ceramic glazes and other molybdenum compounds.',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'Sodium Molybdate',
    industry: 'Molybdenum',
    application: '',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'Molybdenum Trioxide',
    industry: 'Molybdenum',
    application: '',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'Ammonium Para Molybdate',
    industry: 'Molybdenum',
    application:
      'Widely used for the smelting molybdenum ferro and as raw material for molybdenum trioxide and metal molybdenum powder production. In addition, it is also an excellent chemical catalyst, often used in the oil industry.',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'Ammonium Tetra Molybdate',
    industry: 'Molybdenum',
    application:
      'Ammonium molybdate is used in various sectors: to decorate ceramics, in chemical analysis to find the presence of phosphates, arsenics, lead. In the chemical industry as a source of molybdenum ions.',
    grade: '-',
    packingDetails: '',
  },
  {
    name: 'Sodium Nitrate',
    industry: 'Toll Manufactured Products',
    application:
      'Used in etching of metals, metal hot treating (metal treatment), purification & extraction of gold, metal finishing, quench hardening & tempering steel, etc. Sodium nitrate is majorly used for the production of high strength glass, frits, etc.',
    grade: 'Industrial',
    packingDetails: '25 Kg HDPE Bag',
  },
];
