import React from 'react';
import './index.css';

const List = (props) => {
  const { title, items } = props;
  return (
    <div>
      <div className="product-list-title-block">
        <h2 className="heading-secondary">{title}</h2>
      </div>
      <div className="product-list-block">
        {Object.keys(items).map((key, index) => {
          return (
            <li key={`product-list-item-${index}`}>
              {key.includes('only-text') ? (
                ''
              ) : (
                <span className="product-list-item-heading">{key}</span>
              )}
              <span>{items[key]}</span>
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default List;
