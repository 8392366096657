import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-responsive-modal';
import GetQuoteFormWithoutProduct from '../components/GetQuoteForm/withoutProduct';
import GetQuoteForm from '../components/GetQuoteForm';

class GetQuotaButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div
        className={`getQuotaButton ${
          this.props.position === 'right' ? `unit-right-header` : ''
        } ${this.props.className}`}
      >
        <Button
          className={`btn-${this.props.btnColor} btn-${this.props.btnSize}`}
          onClick={this.onOpenModal}
        >
          get a quote
        </Button>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          classNames={{
            overlay: 'quote-modal-overlay',
            modal: 'quote-modal remove-shadow',
          }}
        >
          {this.props.withoutProduct ? (
            <GetQuoteFormWithoutProduct onSuccess={this.onCloseModal} />
          ) : (
            <GetQuoteForm
              productList={this.props.productList}
              onSuccess={this.onCloseModal}
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default GetQuotaButton;

GetQuotaButton.defaultProps = {
  className: '',
  btnColor: 'blue',
  btnSize: 'large',
  productList: [],
};
