import React from 'react';
import { Image, Container } from 'react-bootstrap';
import '../../style/peoplegallery.css';

const PeopleGallery = (props) => {
  return (
    <Container className="peoplegallery__container">
      {props.data.map((item, index) => {
        return (
          <div className="peoplegallery__child">
            <Image className="peoplegallery__image" src={item.image} />
            <div className="peoplegallery__child__block">
              <div className="peoplegallery__child__block__details">
                <h3>{item.name}</h3>
                <div className="peoplegallery__child__block__line" />
                <p>{item.position}</p>
              </div>
            </div>
            <div className="people-overlay">
              <div className="people-overlay-text">
                <div>
                  <u>Email</u>
                </div>
                <div>
                  <a href={`mailto: ${item.email}`}>{item.email}</a>
                </div>
                <div>
                  <u>Phone</u>
                </div>
                <div>{item.phone}</div>
              </div>
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default PeopleGallery;
