import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assests/icons/icon-font.css';
import '../style/services.css';
import '../style/typography.css';

import factory from '../assests/icons/services/factory.svg';
import calendar from '../assests/icons/services/calendar.svg';
import chart from '../assests/icons/services/chart.svg';
import user from '../assests/icons/services/user.svg';

class Services extends React.Component {
  render() {
    return (
      <div className="home-services">
        <Container>
          <section
            className="section-primary-services"
            data-aos="fade-left"
            data-aos-once="true"
          >
            <Row>
              <Col xs={6} md={3}>
                <div className="primary-service__container">
                  <img src={factory} alt="img" />
                  <h2 className="section-heading-secondary">03</h2>
                  <span>facilities</span>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="primary-service__container">
                  <img src={calendar} alt="img" />
                  <h2 className="section-heading-secondary">30</h2>
                  <span>years</span>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="primary-service__container">
                  <img src={user} alt="img" />
                  <h2 className="section-heading-secondary">500+</h2>
                  <span>customers</span>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="primary-service__container">
                  <img src={chart} alt="img" />
                  <h2 className="section-heading-secondary">30,000+</h2>
                  <span>tpa volumes</span>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </div>
    );
  }
}
export default Services;
